import Dexie, { Table } from 'dexie';
import { IndexedDBSetting } from '../constants';
export interface Items {
  id?: number;
  image: File;
}

export class MySubClassedDexie extends Dexie {
  items!: Table<Items>; 

  constructor() {
    super(IndexedDBSetting.DB_NAME);
    this.version(1).stores({
      items: '++id, image'
    });
  }
}

export const db = new MySubClassedDexie();