/**
 * URLクラス。
 */
export default class Url {
    _url:string = "";
    _params:string[] = [];

    /**
     * コンストラクタ。
     *
     * @param {string} url URL。
     */
    constructor(url:string) {
        this._url = url;
    }

    /**
     * URLパラメータを追加する。
     *
     * 指定したKeyとValueはencodeURIComponentでURLエンコードして設定する。
     *
     * @param {string} key   URLパラメータのKey名。
     * @param {string} value keyに紐付ける値。
     */
    addString(key:string, value:string|null):void {
        if (value !== null) {
            this._params.push(`${key}=${encodeURIComponent(value)}`);
        }
    }

    /**
     * URLパラメータを追加する。
     *
     * 指定したKeyとValueはencodeURIComponentでURLエンコードして設定する。
     *
     * @param {string} key   URLパラメータのKey名。
     * @param {number} value keyに紐付ける値。
     */
    addNumber(key:string, value:number|null):void {
        if (value !== null) {
            this._params.push(`${key}=${value}`);
        }
    }

    /**
     * URLを生成する。
     *
     * @return {string} 生成したURL。
     */
    create():string {
        if (this._params.length > 0) {
            return `${this._url}?${this._params.join("&")}`;
        }
        return this._url;
    }
}
