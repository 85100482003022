import { NavigateFunction } from "react-router-dom";
import * as PagingUtil from "./PagingUtil"

/**
 * 画面遷移を行うクラス。
 */
export default class PagingNavigator {
    _navigate:NavigateFunction;

    /**
     * コンストラクタ。
     *
     * @param {NavigateFunction} navigate Navigate関数。
     */
    constructor(navigate:NavigateFunction) {
        this._navigate = navigate;
    }

    /**
     * 指定の画面に画面遷移する。
     *
     * @param {string}      pageName 画面名。
     * @param {object|null} state    画面遷移先に渡す状態情報。
     * @see PagingUtil#next
     */
    next(pageName:string, state?:object|null):void {
        PagingUtil.next(pageName, this._navigate, state);
    }

    /**
     * 前画面に画面遷移する。
     * @see PagingUtil#back
     */
    back():void {
        PagingUtil.back(this._navigate);
    }

    /**
     * 前画面に戻した上で指定の画面に画面遷移する。
     *
     * @param {string}      pageName 画面名。
     * @param {object|null} state    画面遷移先に渡す状態情報。
     * @see PagingUtil#backAndNext
     */
    backAndNext(pageName:string, state?:object|null) {
        PagingUtil.backAndNext(pageName, this._navigate, state);
    }

    /**
     * 画面履歴を消去した上で指定の画面に画面遷移する。
     *
     * @param {string}      pageName 画面名。
     * @param {object|null} state    画面遷移先に渡す状態情報。
     * @see PagingUtil#resetAndNext
     */
    resetAndNext(pageName:string, state?:object|null):void {
        PagingUtil.resetAndNext(pageName, this._navigate, state);
    }

    /**
     * 先頭画面に画面遷移する。
     * @see PagingUtil#backToTop
     */
    backToTop():void {
        PagingUtil.backToTop(this._navigate);
    }

    /**
     * 現在の画面に対して補正を行う
     * @see PagingUtil#restore
     */
    restore():object|null {
        return PagingUtil.restore(this._navigate);
    }

    /**
     * Navigateを更新する。
     *
     * 利用者側でNavigateの更新を監視し、変更された場合に置き換えることを想定している。
     *
     * @param {NavigateFunction} navigate Navigate関数。
     */
    update(navigate:NavigateFunction) {
        this._navigate = navigate;
    }
}
