import { chat_api_url } from '../../../constants';
import { ChatMessageList } from '../../../types/chatInfo';
import { ApiGet_ChatAccessToken } from '../../ApiGet';
import { ApiPost_ChatAccessToken } from '../../ApiPost';
import { ApiPut_ChatAccessToken } from '../../ApiPut';
import { ApiDef, validateResponse } from '../common';
import Url from '../../../common/Url';
import LoginUserInfo from '../../LoginUserInfo';

type MessageCallback = (response:ChatMessageList) => void;
type Callback = () => void;
type Error = (error:any) => void;

/**
 * チャットルームに投稿されたメッセージを取得する。
 *
 * @param {string}          chatroomId  ルームID。
 * @param {string|null}     messageId   指定されたメッセージIDより小さいIDを持つメッセージ(過去メッセージ)を取得する。
*                                       最新メッセージから取得する場合はnullを指定すること。
 * @param {MessageCallback} success     成功時に呼び出されるコールバック関数。
 * @param {Error}           failure     失敗時に呼び出されるコールバック関数。
 */
export const getChatMessages = (chatroomId:string, messageId:string|null, success:MessageCallback, failure:Error):void => {
    const url:Url = new Url(chat_api_url.API_URL + "messageInfo");
    url.addString("chatroomId", chatroomId);
    if (messageId !== null) {
        url.addNumber("messageId", Number(messageId));
    }

    const config = {
        headers:{
            "X-RsRescue-Token": LoginUserInfo.getUserId(),
        }
    };

    ApiGet_ChatAccessToken(url.create(), config, (response:any) => {
        console.log('チャットメッセージ取得成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("messages",        "object", 0,  true, [
                new ApiDef("messageUniqId",     "string", 35,  true),
                new ApiDef("chatroomId",        "string", 25,  true),
                new ApiDef("userId",            "string", 11,  true),
                new ApiDef("controlCenterCode", "string", 1,   true),
                new ApiDef("chatMessageCode",   "string", 1,   true),
                new ApiDef("chatMessage",       "string", 512, true),
                new ApiDef("chatImgMessageId",  "string", 10,  false),
                new ApiDef("chatImg",           "string", 0,   false),
                new ApiDef("sent",              "string", 10,  true),
            ]),
            new ApiDef("latestMessageId", "string", 10, false),
            new ApiDef("earliestMessageId", "string", 10, false),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};

/**
 * チャットルームにメッセージを登録する。
 *
 * @param {string}   chatroomId ルームID。
 * @param {string}   message    送信メッセージ。
 * @param {Callback} success    成功時に呼び出されるコールバック関数。
 * @param {Error}    failure    失敗時に呼び出されるコールバック関数。
 */
export const sendChatMessage = (chatroomId:string, message:string, success:Callback, failure:Error):void => {
    const URL = chat_api_url.API_URL + "messageInfo";

    const config = {
        headers:{
            "Content-Type": "application/json; charset=utf-8",
            "X-RsRescue-Token": LoginUserInfo.getUserId(),
        }
    };

    const data = { chatroomId: chatroomId, message: message };
    ApiPost_ChatAccessToken(URL, data, config, (response:any) => {
        console.log('チャットメッセージ送信成功', response);

        success();
    }, failure);
};

/**
 * オペレータから受信したメッセージの既読日時を更新する。
 *
 * @param {string}   chatroomId         ルームID。
 * @param {string}   latestMessageId    指定されたメッセージIDより小さいIDを持つメッセージを更新対象とする。
 * @param {string}   earliestMessageId  指定されたメッセージIDより大きいIDを持つメッセージを更新対象とする。
 * @param {Callback} success            成功時に呼び出されるコールバック関数。
 * @param {Error}    failure            失敗時に呼び出されるコールバック関数。
 */
export const updateChageMessageReadDate = (chatroomId:string, latestMessageId:string, earliestMessageId:string, success:Callback, failure:Error):void => {
    const URL = chat_api_url.API_URL + "messageInfo";

    const config = {
        headers:{
            "Content-Type": "application/json; charset=utf-8",
            "X-RsRescue-Token": LoginUserInfo.getUserId(),
        }
    };

    const data = { chatroomId: chatroomId, latestMessageId: Number(latestMessageId), earliestMessageId: Number(earliestMessageId) };
    ApiPut_ChatAccessToken(URL, data, config, (response:any) => {
        console.log('既読情報更新成功', response);

        success();
    }, failure);
};
