import axios from 'axios';
import { Licence } from '../constants';

/**
 * ���C�Z���X�\���pHTML�t�@�C�����擾����
 * @return {Promise} 
 */
export const htmlFileRead = () => {  
    return new Promise((resolve, reject) => {
        axios.get(Licence.FILE).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};