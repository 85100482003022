import axios from 'axios';
import { requestApi } from './ApiCommon';
import { ApiGet_getAccessToken, ApiGet_loginChat } from './ApiGet';
import LoginUserInfo from './LoginUserInfo';


/**
 * PUT要求を行う。
 *
 * @param {string} url     APIのURL。
 * @param {any}    body    APIのBody。
 * @param {any}    config  axiosの設定。
 * @param {any}    success 成功時に呼び出されるコールバック関数。
 * @param {any}    failure 失敗時に呼び出されるコールバック関数。
 */
const ApiPut = (url:string, body: any, config:any, success:any, failure:any):void => {
    requestApi(url, config, success, failure, (url:string, config:any) => {
        console.log("request put:", url);
        return axios.put(url, body, config);
    });
};


//アクセストークンを使用したAPI実行処理
export const ApiPut_AccessToken = (url:string, body:any, header:any, success:any, failure:any = null):void => {
    const loginUserInfo:LoginUserInfo = new LoginUserInfo();

    //アクセストークン発行APIの実行
    ApiGet_getAccessToken(loginUserInfo, (accessToken:any) => {
        const config = {
            headers:{
                ...header.headers,...{
                    "X-AccessToken": accessToken,
                    "X-RsRescue-Token": loginUserInfo.userId,
                }
            }
        };

        ApiPut(url, body, config, success, failure);
    }, failure);
};

//アクセストークンを使用したチャットAPI実行処理
export const ApiPut_ChatAccessToken = (url:string, body:any, header:any, success:any, failure:any = null):void => {
    const loginUserInfo:LoginUserInfo = new LoginUserInfo();

    //ログインAPIの実行
    ApiGet_loginChat(loginUserInfo, (accessToken:any) => {
        const config = {
            headers:{
                ...header.headers,...{
                    "X-AccessToken": accessToken,
                    "X-RsRescue-Token": loginUserInfo.userId,
                }
            }
        };

        ApiPut(url, body, config, success, failure);
    }, failure);
};
