// 初期設定
import { useEffect, useState, useRef } from 'react';

import { mail_sender, TransitionCode, AuthCode, OrderFlag, BlacklistFlag, QueryParameter } from '../constants';
import { useNavigate , useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HomeMessages,AlertMessages } from '../messages';

// モーダル呼び出し関数
import { ModalDialog, SecurityVerificationModalDialog } from '../common/Modal';
import { validateMailAddress } from "../common/api/mailValidate"
import { registerCertificationInfo } from "../common/api/certificationInfo"
import { MailValidateInfo } from '../types/mailValidateInfo';
import { ErrorTransition } from '../common/ErrorUtil';
import *  as CommonUtil from '../common/CommonUtil';
import MailInfo from '../common/MailInfo';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';
import { ErrorReason } from '../constants';
import LoginUserInfo from '../common/LoginUserInfo';

const trasition_code = TransitionCode.RELIEF_ORDER;
const auth_code = AuthCode.USER_REGISTRATION_CONFIRM;

function Home(props:PageProps) {

    const navigate = useNavigate();
    const location = useLocation();
    const processing = useRef(false);
    const search = useLocation().search;

    const [email, setEmail] = useState("");
    const [email2, setEmail2] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    const [errorComment1, setError1] = useState("");
    const [errorComment2, setError2] = useState("");

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);
    
    //スクロール対象定義
    const scrollTop = useRef<HTMLDivElement>(null);

    // 初期処理
    useEffect(() => {
        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }

        // URLパラメータ取得
        const query = new URLSearchParams(search);
        const inflowCode = query.get(QueryParameter.PARAMETER_INFLOW_CODE);

        //URLパラメータに流入元コードがセットされている場合、セッションストレージにセットする。
        if(inflowCode !== null){
            const loginUserInfo:LoginUserInfo = new LoginUserInfo();
            loginUserInfo.inflowCode = inflowCode;
            loginUserInfo.save();
        }

        // すでに送信済みなら、送信済み画面へ移動
        const mailInfo:MailInfo = new MailInfo();
        if(mailInfo.sendMailFlg) {
            PagingUtil.resetAndNext("/send", navigate);
            return initResult['cleanup'];
        }

        const isFirst:boolean = mailInfo.sendMailFlg === null;

        // メール送信完了フラグの設定
        mailInfo.sendMailFlg = false;
        mailInfo.save();

        //メンテナンスメッセージの取得
        CommonUtil.getMainteMessage().then(function(response: any){
            //メンテナンスメッセージクローズ後に救援要請制御情報取得
            return CommonUtil.getOrderControl(true,false);
        }).then(function(response: any) {
            CommonUtil.setIndicator(false);
            //救援要請制御ダイアログかどうか
            if(response){
                PagingUtil.resetAndNext(ErrorTransition(ErrorReason.ORDER_CONTROL_ERROR), navigate);
            }
            else{
                //安全案内確認ダイアログ表示
                if(isFirst) {
                    SecurityVerificationModalDialog();
                }
            }
        }).catch(function(response: any){
            PagingUtil.resetAndNext(ErrorTransition(response), navigate);
        })
        
        setDisplayFooter(true);
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

    // ボタン押したときの処理
    function userEmailSend(e: { preventDefault: () => void; }) {
        if (processing.current) return;
        processing.current = true;

        setError1("");
        setError2("");
        e.preventDefault();
        console.log(email);
        
        var errorFlg: boolean = false;

        if(email.length === 0){
            setError1(HomeMessages.RSEWS_V01_M003);
            errorFlg = true;
        }
        else if(email !== email2){
            setError1(HomeMessages.RSEWS_V01_M005);
            errorFlg = true;
        }
        else if (!CommonUtil.checkMailAddress(email) || email.length > 256) {
            setError1(HomeMessages.RSEWS_V01_M004);
            errorFlg = true;
        }

        if(errorFlg){
            processing.current = false;
            scrollTop?.current?.scrollIntoView({behavior: "smooth"});
            return;
        }

        //インジケータ表示
        CommonUtil.setIndicator(true);
        validateMailAddress(email,
            function (info:MailValidateInfo) {
                // APIが正常の場合、ページ遷移
                if (info.isBlacklist === BlacklistFlag.UNREGISTERED && info.isOrder === OrderFlag.UNREGISTERED) {

                    registerCertificationInfo({mailAddress: email, transitionCode: trasition_code ,inflowCode: CommonUtil.getInflowCode() ,authCode: auth_code}
                        ,function (hash:string) {
                            // メール送信完了フラグの設定
                            const mailInfo:MailInfo = new MailInfo();
                            mailInfo.sendMailFlg = true;
                            mailInfo.save();

                            CommonUtil.setIndicator(false);
                            PagingUtil.resetAndNext("/send", navigate);
                        }
                        ,function (error:any) {
                            // 取得失敗時
                            console.log('送信失敗', error);
                            CommonUtil.setIndicator(false);
                            PagingUtil.resetAndNext(ErrorTransition(error), navigate);
                        }
                    );

                } 
                else {
                    if (info.isBlacklist === BlacklistFlag.REGISTERED) {
                        setError1(HomeMessages.RSEWS_V01_M007_1);
                        setError2(HomeMessages.RSEWS_V01_M007_2);
                        scrollTop?.current?.scrollIntoView({behavior: "smooth"});
                    } else if (info.isOrder === OrderFlag.REGISTERING) {
                        setError1(HomeMessages.RSEWS_V01_M006_1);
                        setError2(HomeMessages.RSEWS_V01_M006_2);
                        scrollTop?.current?.scrollIntoView({behavior: "smooth"});
                    } else  {
                        ModalDialog(AlertMessages.RSEWS_ER_00100)
                    }
                    
                    processing.current = false;
                    CommonUtil.setIndicator(false);
                }

            }
            ,function (error:any) {
                // 取得失敗時
                console.log('取得失敗', error);
                processing.current = false;
                CommonUtil.setIndicator(false);
                PagingUtil.resetAndNext(ErrorTransition(error), navigate);
            });
    }

// 画面設定
    return (


<div>
<HelmetProvider>
    <Helmet>
        <title>メールアドレス入力 | JAF救援要請ウェブサイト</title>
    </Helmet>
</HelmetProvider>
<Header pageNavigator={props.pageNavigator}/>
<div ref={scrollTop} className="contentsWrapper">
<div className="contents">

<p className="general">
ウェブサイトからJAFを呼ぶには、メールアドレスが必要です。受信可能なメールアドレスの入力をお願いします。<br />
入力されたメールアドレスは、本救援要請のみに使用します。<br />
<span className="small">
※入力いただいたメールアドレスに「{mail_sender.ADDRESS}」から利用登録確認メールをお送りします。<br />
迷惑メール設定をされている場合は、「{mail_sender.DOMAIN}」のドメインからメールを受信できるよう設定をお願いします。
</span>

</p>


<dl className="form1">
<dt>メールアドレス</dt>
<dd>
<input type="text" inputMode="email" className='mailAddress' onChange={(e) => setEmail(e.target.value)} placeholder="メールアドレス" />
<input type="text" inputMode="email" className='mailAddress' onChange={(e) => setEmail2(e.target.value)} placeholder="メールアドレス（確認）" />

</dd>
</dl>
<form onSubmit={userEmailSend}>
<p className="error">{errorComment1}<br></br>{errorComment2}</p>
<div className="privacy">
<button className="default" type="button" onClick={() => PagingUtil.backAndNext("/userLogin", navigate)}>救援要請済みの方はこちら</button>
</div>

<div className="privacy">
<label htmlFor="agree">個人情報の取扱いに同意</label>

<input type="checkbox" id="agree" value="同意する" checked={isChecked}  onChange={() => setIsChecked(!isChecked)} />

</div>
<a className="privacy" href="policy.html" target="_blank" rel="noopener">個人情報の取扱いについて表示</a>

<div className="submit">
<button className={!isChecked ? "submit arrow gray" : "submit arrow"} type="submit" disabled={!isChecked}>送信</button>
</div>
</form>

</div>
</div>

<Footer display={displayFooter}/>


        </div>
    )
}
 
export default Home
