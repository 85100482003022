import MasterInfo from "./common/MasterInfo"

//プッシュ情報を取得する
export async function sendPush() {
    if ('Notification' in window) {
        let permission = Notification.permission;

        if (permission === 'denied') {
            return null;
        }
    }

    if ('serviceWorker' in navigator) {
        window.sw = await navigator.serviceWorker.register('/service-worker.js', {scope: '/'});
    }

    //PublicKeyをセッションストレージから取得
    const mstInfo:MasterInfo = new MasterInfo();

    //ログアウト済の場合セッションストレージにデータが存在しない
    if(mstInfo.pushKeyInfoList.length === 0){
        return null;
    }

    //必ず1件のみ存在する
    const appServerKey = mstInfo.pushKeyInfoList[0]['keyValue'];
    const applicationServerKey = urlB64ToUint8Array(appServerKey);
    // push managerにサーバーキーを渡し、トークンを取得
    try {
        await window.sw.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey:applicationServerKey
        });
        let permission = "";
        if ('Notification' in window) {
            permission = Notification.permission;
    
            if (permission === 'denied') {
                return null;
            }
            else if (permission === 'default') {
                return null;
            }
        }

        let response = null;

        if (permission === 'granted') {
            
            return window.sw.pushManager.getSubscription().then(function(subscription) { 
                //プッシュ通知が解除されている
                if(!subscription){
                    return null;
                }
                
                const key = subscription.getKey('p256dh');
                const token = subscription.getKey('auth');
    
                let endpoint = subscription.endpoint;
                let userPublicKey = btoa(String.fromCharCode.apply(null, new Uint8Array(key)));
                let userAuthToken = btoa(String.fromCharCode.apply(null, new Uint8Array(token)));
    
                response = {
                    'endpoint':endpoint,
                    'userPublicKey':userPublicKey,
                    'userAuthToken':userAuthToken,
                }

                return response;
            } );
        }
        else{
            return response;
        }
        // changeAllowLink(false);
    } catch (e) {
        //プッシュ通知エラー
        console.log('webpush.jsでエラー発生', e);
        return null;
    }
}

/**
 * トークンを変換するときに使うロジック
 * @param {*} base64String 
 */
function urlB64ToUint8Array (base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
