// 初期設定
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { login } from '../common/api/chat/login';
import { ChatLoginResponse } from '../types/chatInfo';
import { ErrorTransition } from '../common/ErrorUtil';
import { getOrders } from "../common/api/order"
import { OrderInfo } from '../types/orderInfo';
import LoginUserInfo from '../common/LoginUserInfo';
import * as CommonUtil from '../common/CommonUtil';
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';
import { Header } from '../header/Header';

function Login(props:PageProps) {
    const navigate = useNavigate();

    const loginChat = (orderNum: string) => {
        login(orderNum, function (response:ChatLoginResponse) {
            CommonUtil.setIndicator(false);
            const loginUserInfo = new LoginUserInfo();
            loginUserInfo.chatAccessToken = response.accessToken;
            loginUserInfo.save();
            PagingUtil.backAndNext("/chat/message", navigate);
        }, function (error:any) {
            PagingUtil.resetAndNext(ErrorTransition(error), navigate);
        });
    }
    const location = useLocation();

    React.useEffect(() => {
        const initResult = CommonUtil.initialize(true, true, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }
        
        let reliefInfo:any = sessionStorage.getItem('reliefInfo');
        reliefInfo = JSON.parse(reliefInfo);
        CommonUtil.setIndicator(true);
        if (!reliefInfo || !reliefInfo['orderNum']) {
            //依頼一覧取得
            getOrders(function (orderInfoList:Array<OrderInfo>) {
                const orderInfo = orderInfoList[0];
                //アライアンスサーバー依頼番号を保存
                // eslint-disable-next-line react-hooks/exhaustive-deps 
                reliefInfo = {...reliefInfo, 'orderNum' : orderInfo['orderNum']};
                sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));

                loginChat(reliefInfo['orderNum'])
            },function (chk_error:any) {
                //依頼一覧取得エラー
                console.log('依頼一覧取得失敗', chk_error);
                PagingUtil.resetAndNext(ErrorTransition(chk_error), navigate);
            })
        } else {
            loginChat(reliefInfo['orderNum'])
        }
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

// 画面設定
    return (
<div>
<HelmetProvider>
    <Helmet>
        <title>JAF救援要請ウェブサイト</title>
    </Helmet>
</HelmetProvider>
<Header hideMenu pageNavigator={props.pageNavigator}/>
</div>
    )
}
 
export default Login
