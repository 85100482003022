import { db } from '../indexedDB/db';

/**
 * 画像ファイルをindexedDBに保存する
 * @param {File} image 画像ファイル
 */
export const setImageFile = (image:File, imageKey?:number):Promise<number| boolean> => {      
    return new Promise((resolve, reject) => {

        //imageKeyが無い場合は初期登録値
        if(!imageKey){
            imageKey = 1;
        }

        // DBにデータを追加
        db.items.put({
            id: imageKey,
            image: image
        })
        .then(function(response: any){ 
            resolve(response);

        })
        .catch(function(response: any){
            reject(false);
        })   
    });
}

/**
 * 画像ファイルをindexedDBから取得する
 * @param {number} id ID
 */
export const getImageFile = (id:number):Promise<any> => {      
    return new Promise((resolve, reject) => {        
        db.items
        .where('id')
        .equals(id)
        .toArray()
        .then(function(response: any){ 
            if (response.length === 0){
                reject(false);
                return;
            }

            const file = response[0]['image'];
            resolve(file);
        })
        .catch(function(response: any){
            reject(false);
        }) 
    });
}

/**
 * 画像ファイルをindexedDBから削除する
 * @param {File} id ID
 */
export const deleteImageFile = (id?:number):Promise<boolean> => {      
    return new Promise((resolve, reject) => {           
        if(id){        
            db.items.delete(id)
            .then(function(response: any){ 
                resolve(true);
            })
            .catch(function(response: any){
                reject(false);
            }) 
        }
        else{
            //IDが存在しない場合削除対象が存在しない為正常終了扱い
            resolve(false);
        }
    });
}

/**
 * 定義したindexedDBを削除する
 */
export const deleteAllImageFile = ():Promise<boolean> => {      
    return new Promise((resolve, reject) => {    
        //DBごと削除する
        db.delete()
        .then(function(response: any){ 
            resolve(true);
        })
        .catch(function(response: any){
            reject(false);
        }) 
    });
}