import $ from "jquery";

import * as messages from '../messages';
import * as CommonUtil from '../common/CommonUtil';
import MaintenanceInfo from '../common/MaintenanceInfo';
import MasterInfo from '../common/MasterInfo';
import { WheelType, CancelReason } from '../constants';
import { htmlFileRead } from "./LicenceUtil";

class CloseButtonType {
  static readonly CLOSE1:string = ".modal-close";
  static readonly CLOSE2:string = ".modal-close2";
  static readonly CLOSE3:string = ".modal-close3";
};

//ダイアログ表示処理
const modalAdd = (html:string, closeButtonType:CloseButtonType = "") => {
  if (closeButtonType === CloseButtonType.CLOSE1) {
    $(CloseButtonType.CLOSE1).removeClass("hide");
  } else {
    $(CloseButtonType.CLOSE1).addClass("hide"); 
  }
  if (closeButtonType === CloseButtonType.CLOSE2) {
    $(CloseButtonType.CLOSE2).removeClass("hide");
  } else {
    $(CloseButtonType.CLOSE2).addClass("hide"); 
  }
  if (closeButtonType === CloseButtonType.CLOSE3) {
    $(CloseButtonType.CLOSE3).removeClass("hide");
  } else {
    $(CloseButtonType.CLOSE3).addClass("hide"); 
  }

  $("#modal_contents").html(html);
  $(".modal-container").addClass("active");
  
  //背景スクロール制御
  $("body").addClass("overflow-hidden");
};

//画面に追加表示されている要素のクリア
export const cleanPage = (force = false) => {
  //各インジケータ非表示
  CommonUtil.setIndicator(false);
  CommonUtil.setChatIndicator(false);
  CommonUtil.setTransparentPanel(false);

  if (force) {
    CommonUtil.setPermissionIndicator(false);
  }

  modalRemove(true);
};

//ダイアログを非表示処理
//ハンバーガーメニュー表示中に画面遷移が行われる場合に画面スクロールを有効化する目的でforceにtrueを指定する
const modalRemove = (force:boolean = false) => {
  $(CloseButtonType.CLOSE1).addClass("hide"); 
  $(CloseButtonType.CLOSE2).addClass("hide"); 
  $(CloseButtonType.CLOSE3).addClass("hide"); 

  $(".modal-container").removeClass("active");
  
  //ハンバーガーメニュー表示中はスクロール制限解除しない
  if(!$("#navWrapper").is(":visible") || force){
    //背景スクロール制御解除
    $("body").removeClass("overflow-hidden");
  }
};

/** 初回の安全確認ダイアログ */
export const SecurityVerificationModalDialog = ():any => {
  const contents =
      '<p class="align-center strong">安全な場所に移動してから救援要請をお願いします。<br/><br /><img src="/images/safety.jpg" alt="" /></p>'
    + '<div class="submit">'
    + '<button id="security_confirm" class="action check">安全を確認</button>'
    + '</div>';
    
  modalAdd(contents);
  
  $("#security_confirm").off();
  $("#security_confirm").on("click", function() {
    modalRemove();
  });    
};

/** ダイアログ表示 */
export const ModalDialogMaintenance = (close:()=>void = ()=>{}):any => {
  const info = new MaintenanceInfo();
  const list = info.mainteMessageInfoList;

  console.log(list);

  const today:Date = new Date();
  let mainteMessage:string = "";
  
  mainteMessage += '<div class="scroll-daialog">';
  for(let index = 0; index < list.length; index++ ){
    if(list[index].message !== null){
      const value = list[index];
        //startDtのNULLを考慮
        let startDay:Date = new Date();
        let startPeriod:string ="";
        if(value.startDt !== null){
          startDay = new Date(value.startDt);
          let year_str:string = startDay.getFullYear().toString();
          let month_str:string  =  ("00" + (startDay.getMonth()+1)).slice(-2);
          let date_str:string  = ("00" + startDay.getDate()).slice(-2);
          startPeriod = year_str + "." + month_str + "." + date_str;
        }
        else{
          startPeriod = "";
        }

        //endDtのNULLを考慮
        let endDay:Date = new Date();
        if(value.endDt !== null){
          endDay = new Date(value.endDt);
        }

        //ステータスが公開、終了期間が過ぎていないメッセージを表示
        if(value.endDt === null || today <= endDay){
          //表示するメッセージを追加
          mainteMessage += '<div class="range-fixed"><h3>' + value.subject + '</h3>';
          mainteMessage += '<p class="date">' + startPeriod + '</p>';
          mainteMessage += '<p class="contents">' + value.message.replace(/\n/g, '<br>') + '</p>';
        }
     }
  }
  
  mainteMessage += '</div>';
  modalAdd(mainteMessage, CloseButtonType.CLOSE1);

  $("#modal_close").off();
  $("#modal_close").on("click", function() {
    modalRemove();
    close();
  });

  MaintenanceInfo.clean();
};

// 「確認」ボタンのみのダイアログ
export const ModalDialog = (COMMENT:string, close:()=>void = ()=>{}) => {
  const contents =
      '<p class="align-center strong">' + COMMENT + '</p>'
    + '<div class="submit">'
    + '<button class="action" id="notice_close">'+ messages.DialogButtonStr.CONFIRM +'</button>'
    + '</div>';

  modalAdd(contents);

  $("#notice_close").off();
  $("#notice_close").on("click", function () {
    modalRemove();
    close();
  });
};

// 「はい」ボタンのみのダイアログ（車両重量・救援要請制御・指定位置再確認）
export const NoticeModalDialog = (COMMENT:string, close:()=>void = ()=>{}) => {
  const contents =
      '<p class="align-center strong">' + COMMENT + '</p>'
    + '<div class="submit">'
    + '<button class="action" id="notice_close">'+ messages.DialogButtonStr.YES +'</button>'
    + '</div>';
    
  modalAdd(contents);

  $("#notice_close").off();
  $("#notice_close").on("click", function () {
    modalRemove();
    close();
  });
};

// 「はい」「いいえ」ボタンのみのダイアログは共通化（料金表・ログアウト・画像削除確認）
export const ConfirmModalDialog = (COMMENT:string, next:()=>void = ()=>{}, close:()=>void = ()=>{}) => {
  const contents =
      '<p class="align-center strong">' + COMMENT + '</p>'
    + '<div class="submit">'
    + '<button class="action" id="confirm_next">はい</button>'
    + '<button class="action" id="confirm_close">いいえ</button>'
    + '</div>';

  modalAdd(contents);

  $("#confirm_next").off();
  $("#confirm_next").on("click", function () {
    modalRemove();
    next();
  });

  $("#confirm_close").off();
  $("#confirm_close").on("click", function() {
    modalRemove();
    close();
  });
};



//指定位置確認ダイアログ
export const ReliefConfirmModalDialog = (confirm:()=>void, fix:()=>void):any => {
  const contents =
      '<p class="align-center strong"> 救援要請位置に間違いはありませんか。</p>'
    + '<div class="submit mobile_column">'
    + '<button class="action gray" id="fix">修正する</button>'
    + '<button class="action" id="confirm">確定する</button>'
    + '</div>';

  modalAdd(contents);

  $("#confirm").off();
  $("#confirm").on("click", function () {
    modalRemove();
    confirm();
  });

  $("#fix").off();
  $("#fix").on("click",function () {
    modalRemove();
    fix();
  });
};


// 電話離脱案内ダイアログ
export const FuzzyReliefModalDialog = (inRelief:boolean, tel:()=>boolean, close:()=>void):any => {
  let fuzzyHtml = '';
  //表示元が救援場所入力画面の場合「高速道路上は大変危険です」を表示
  if (inRelief) {
    fuzzyHtml = '<p class="align-center strong"><span class="red">高速道路上は大変危険です。</span></p>'
  };
  fuzzyHtml += '<p class="strong">ガードレールの外側など安全な場所に避難してからお電話をおかけください。位置情報を連携するため、お使いの電話番号を以下にご入力ください。';
  fuzzyHtml += '</p>';
  fuzzyHtml += '<input type="tel" inputMode="tel" id="telNum" name="電話番号" placeholder="090********">';
  fuzzyHtml += '<p class="red">' + messages.FuzzyReliefMessages.RSEWS_D06_M003 + '</p>';

  fuzzyHtml += '<div class="flexWrapper column">'
  fuzzyHtml += '<p class="generalError" id="telJafError1">' + messages.FuzzyReliefMessages.RSEWS_D06_M004 + '</p>';
  fuzzyHtml += '<p class="generalError" id="telJafError2">' + messages.FuzzyReliefMessages.RSEWS_D06_M005 + '</p>';
  fuzzyHtml += '<div class="flexWrapper column">'
  fuzzyHtml += '<button class="jaf" id="telJaf">JAFに電話する</button>';

  //表示元が救援場所入力画面の場合「閉じるボタン」表示
  if (inRelief) {
    fuzzyHtml += '<button class="action" id="fuzzy_close">閉じる</button></div>';
  } else{
    //表示元が要請内容入力画面の場合「ウェブで続けるボタン」表示
    fuzzyHtml += '<button class="action" id="fuzzy_close">ウェブで続ける</button></div>';
  };

  const dialog = {
      close: modalRemove
  };

  modalAdd(fuzzyHtml);

  $("#telJaf").off();
  $("#telJaf").on("click", function (e) {
      e.preventDefault();

      const val:any = $('#telNum').val();
      if(val === null || val === "") {
          $('#telJafError1').show();
          $('#telJafError2').hide();
      }
      else if(!CommonUtil.checkTelNo(val)) {
          $('#telJafError2').show();
          $('#telJafError1').hide();
      }else {
          const consumed = tel();
          if (consumed) {
              dialog.close();
          }
      }
  });

  $("#fuzzy_close").off();
  $("#fuzzy_close").on("click", function () {
      dialog.close()
      close();
  });

  return dialog;
};

// タイヤ選択ダイアログ
export const TireSelectModalDialog = (wheels:any, selected:()=>void, close:()=>void):any => {

  //タイヤ選択ダイアログの決定ボタンを活性化させる
  const tireDecisionActivation = () => {
    $("#tire").prop('disabled', false);
    $("#tire").removeClass("gray");
  }

  let tireHtml = '<p class="align-center strong">どのタイヤのトラブルですか?<br>';
  tireHtml += '<p class="align-center">（複数選択可）<br>';
  tireHtml += 'パンク、落輪、乗り上げなどトラブルが発生したタイヤをすべてお選びください。</p>';
  tireHtml += '<div class="tire'+wheels+'">';

  if(wheels === WheelType.TWO_WHEELS) {
    tireHtml += '<label for="tire1" class="tire2-1" id="tire2-1"><input type="checkbox" name="二輪" value="前" id="tire1"></label>';
    tireHtml += '<label for="tire2" class="tire2-2" id="tire2-2"><input type="checkbox" name="二輪" value="後" id="tire2"></label>';
  } else if(wheels === WheelType.FOUR_WHEELS) {
    tireHtml += '<label for="tire1" class="tire4-1" id="tire4-1"><input type="checkbox" name="四輪" value="左前" id="tire1"></label>';
    tireHtml += '<label for="tire2" class="tire4-2" id="tire4-2"><input type="checkbox" name="四輪" value="右前" id="tire2"></label>';
    tireHtml += '<label for="tire3" class="tire4-3" id="tire4-3"><input type="checkbox" name="四輪" value="左後" id="tire3"></label>';
    tireHtml += '<label for="tire4" class="tire4-4" id="tire4-4"><input type="checkbox" name="四輪" value="右後" id="tire4"></label>';
  }
  tireHtml += '</div>';
  tireHtml += '<div class="submit">';
  tireHtml += '<button class="action check gray" id="tire" disabled>決定</button>';
  tireHtml += '</div>';
  tireHtml += '</div>';
  // タイヤ選択ダイアログ×ボタン専用のIDで操作
  modalAdd(tireHtml, CloseButtonType.CLOSE2);

  let reliefInfo: any = sessionStorage.getItem('reliefInfo');
  reliefInfo = JSON.parse(reliefInfo);

  const numWheels = Number(reliefInfo['wheels']);
  //前回の入力内容を反映
  const tireInfo = reliefInfo['tireInfo']
  if (tireInfo !== undefined && tireInfo !== '') {
      for (let i = 1; i < numWheels + 1; i++) {
          const tirePosition: string = 'tire' + i
          if (tireInfo[tirePosition] === true) {
              $('#' + tirePosition).attr('checked', 'checked');
              tireDecisionActivation();
          }
      }
  }

  $("#tire").off();
  $("#tire").on("click", function () {
    modalRemove();

    selected();
  });

  $("#tire_close").off();
  $("#tire_close").on("click", function () {
    modalRemove();

    close();
  });

  for (let i = 1; i < numWheels + 1; i++) {
    const tirePosition: string = 'tire' + i
    $('#' + tirePosition).off();
    //仕様上、一度でもタイヤを選択した場合は未選択状態になってもdisableに戻ることはない。
    $('#' + tirePosition).on("click", function () {
      tireDecisionActivation();
    });
  }

};


// 要請キャンセルダイアログ
export const OrderCancelModalDialog = (cancel:(code:any,reason:any)=>boolean, close:()=>void):any => {
  const contents =
      '<p class="align-center strong">キャンセル理由を選択してください。</p>'
    + '<div class="modalmust must" id="d11-1">'
    + '<select id="modal11" required></select>'
    + '<p class="generalError" id="cancelErr">キャンセル理由が未選択です。</p>'
    + '</div>'
    + '<div class="modalmust" id="d11-2" style="display:grid">'
    + '<textarea name="その他キャンセル理由" rows="5" placeholder="その他キャンセル理由" id="cnclCounter" style="grid-column:1/3"></textarea>'
    + '<p class="generalError" id="cnclCountErr" style="float:left" ></p>'
    + '<p class="counter" style="grid-column:2/3">残り50文字</p>'
    + '</div>'
    + '<div class="submit" style="display:block">'
    + '<button class="action" id="cancel" style="display:block">キャンセルする</button>'
    + '</div>';

  // 要請キャンセルダイアログ×ボタン専用のIDで操作
  modalAdd(contents, CloseButtonType.CLOSE3);
  
  const mstInfo:MasterInfo = new MasterInfo();
  const cancelReasonInfoList = mstInfo.cancelReasonInfoList;
  $("#modal11").empty();
  $('#modal11').append($('<option value="" disabled selected>キャンセル理由</option>'));
  // キャンセル理由オプション追加
  for(let i=0; i<cancelReasonInfoList.length; i++){
      const option = document.createElement('option');
      option.value = cancelReasonInfoList[i]['cancelReasonCode'];
      option.text = cancelReasonInfoList[i]['cancelReasonName'];
      $('#modal11').append(option);
  }


  // その他キャンセル理由必須切り替え
  $("#modal11").off();
  $("#modal11").on("change", function() {
      const cancel = $("#modal11").val();
      if (cancel === CancelReason.OTHER_SUPPLEMENTS){
          $("#d11-2").addClass("must");
      }else{
          $("#d11-2").removeClass("must");
      }
  });

  //その他キャンセル理由テキストボックス残り文字数カウント
  $("#cnclCounter").off();
  $("#cnclCounter").on('input', function() {
      const value: any = $("#cnclCounter").val();
      let count = 50 - value.length;
      if(Math.sign(count) === -1) {
          count = 0
      }
      $("p.counter").text('残り' + count + '文字');
  });

  const dialog = {
      close: modalRemove
  };

  $("#cancel").off();
  $("#cancel").on('click', function(e) {
      e.preventDefault();

      let error = false;
      // バリデーションチェック
      if($('#modal11').val() == null) {
          $('#cancelErr').show();
          error = true;
      } else {
          $('#cancelErr').hide();
      }

      const code = $("#modal11").val();
      const reason:any = $('#cnclCounter').val();
      const trimReason = reason.trim();
      $('#cnclCounter').val(trimReason);
      if (trimReason.length > 50) {
          $('#cnclCountErr').html('その他キャンセル理由は50文字以内で入力してください。');
          $('#cnclCountErr').show();
          error = true;
      } else if (code === CancelReason.OTHER_SUPPLEMENTS && trimReason === "") {
          $('#cnclCountErr').html('その他キャンセル理由が未入力です。');
          $('#cnclCountErr').show();
          error = true;
      } else {
          $('#cnclCountErr').hide();
      }

      if (!error) {
          const consumed = cancel(code, trimReason);
          if (consumed) {
              dialog.close();
          }
      }
  });

  $("#cancel_close").off();
  $("#cancel_close").on("click", function () {
      dialog.close();
      close();
  });

  return dialog;
};

// チャット画像ダイアログ
export const ImageModalDialog = (image:any, imgWidth:number, imgHeight:number) => {
    const contents = `<div class="scroll-daialog"><canvas id="imageModal" src=${image} width=${imgWidth} height=${imgHeight}></div>`;

    modalAdd(contents, CloseButtonType.CLOSE1);    
    $("#modal_close").off();
    $("#modal_close").on("click", function() {
      modalRemove();
    });
};

//ライセンスについてダイアログ
export const LicenceModalDialog = (close:()=>void = ()=>{}):any => {  
  CommonUtil.setIndicator(true);
  
  let licenceHtml = '';
  htmlFileRead().then((element: any) => {
    console.log('ライセンス情報取得成功');
    licenceHtml = '<h3>ライセンスについて</h3><div class="scroll-daialog">'+ element +'</div>'
  }).catch((error) => {
    console.log('ライセンス情報取得失敗');
    licenceHtml = '<h3>ライセンスについて</h3><div class="scroll-daialog"></div>'
  }).finally(function () {
    modalAdd(licenceHtml, CloseButtonType.CLOSE1);
    $("#modal_close").off();
    $("#modal_close").on("click", function() {
      modalRemove();
      close();
    });
    CommonUtil.setIndicator(false);   
  });
}