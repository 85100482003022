import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import *  as CommonUtil from '../common/CommonUtil';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { ErrorTransition } from '../common/ErrorUtil';
import  { ErrorReason } from '../constants';
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';
import { OrderedImage } from '../constants';

function Ordered(props:PageProps) {
    const navigate = useNavigate();
    const location = useLocation();

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    React.useEffect(() => {
        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }

        CommonUtil.setIndicator(true);
        //救援要請情報の削除
        CommonUtil.clearInternalData().then(function(response: any){
            CommonUtil.draw("desktopImagePreview", OrderedImage.Desktop.URL).then(function(response: any){
                CommonUtil.draw("mobileImagePreview", OrderedImage.Mobile.URL).then(function(response: any){
                    CommonUtil.setIndicator(false);
                    setDisplayFooter(true);
                });
            });
            //通知パーミッション取得
            CommonUtil.requestWebPushPermission().then(function(response: any){           
            })
            .catch(function(response: any){
                PagingUtil.resetAndNext(ErrorTransition(response), navigate);
            })
        })
        .catch(function(response: any){
            PagingUtil.resetAndNext(ErrorTransition(ErrorReason.UNKWNOWN), navigate);
        })
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);


    return (
        <div>

            <HelmetProvider>
                <Helmet>
                    <title>要請内容登録完了 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header pageNavigator={props.pageNavigator}/>
            <div className="contentsWrapper">
                <div className="contents">

                    <ul className="status">
                        <li>救援場所入力</li>
                        <li>お客様情報入力</li>
                        <li>トラブル内容入力</li>
                        <li>内容確認</li>
                        <li className="current">登録完了</li>
                    </ul>

                </div>
            </div>

            <div className="contentsWrapper top0">
                <div className="contents">

                    <p className="general">
                        ロードサービス要請内容を登録しました。<br />
                        最下部のボタンから救援車両の位置確認、チャットでの問い合わせ等が可能です。<br />
                        <br />
                        状況に変更があった際にはプッシュ通知を行いますので、以下のような表示が出た際には「許可」をお願いします。
                    </p>

                    <div className="imgcntr w80">
                        <canvas id="desktopImagePreview" className="desktop" width={OrderedImage.Desktop.WIDTH} height={OrderedImage.Desktop.HEIGHT} ></canvas>
                        <canvas id="mobileImagePreview" className="mobile" width={OrderedImage.Mobile.WIDTH} height={OrderedImage.Mobile.HEIGHT} ></canvas>
                    </div>

                    <div className="submit">
                        <input type="submit" value="受付状況・要請内容確認等" onClick={() =>PagingUtil.resetAndNext("/receptionStatus", navigate)} />
                    </div>

                </div>

            </div>

            <Footer display={displayFooter}/>
        </div>
    )
}

export default Ordered