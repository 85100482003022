import React, { useLayoutEffect, useRef, MutableRefObject } from "react";
import { useLocation } from "react-router-dom";
import { Menu } from "./menu"
import { QueryParameter } from "../constants";
import PagingNavigator from '../common/PagingNavigator';
import * as CommonUtil from '../common/CommonUtil';

interface HeaderProps {
    tel?: boolean,
    back?: boolean,
    reload?: boolean,
    hideMenu?: boolean
    receptionStatus?: boolean,
    orderConfirmAfter?: boolean,
    chat?: boolean,
    logout?: boolean,
    orderContentTel?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void,
    onBackClick?: () => void,
    pageNavigator: MutableRefObject<PagingNavigator>,
}

export const Header = (props: HeaderProps) => {
    const {tel, back, reload, hideMenu, receptionStatus, orderConfirmAfter, chat, logout, orderContentTel, onBackClick, pageNavigator} = props;

    const scrollHeader = useRef<HTMLHeadElement>(null);    
    const location = useLocation();
    //多重押下対策
    const operation = useRef(new CommonUtil.Operation());

    useLayoutEffect(() => {
        //指定されていない場合全て先頭
        if (!location.state || !location.state.scroll) {
            scrollHeader?.current?.scrollIntoView(true);
        }
    }, [location]);

    //受付状況確認画面電話ボタン押下処理
    const callClick = () => {
        const getReliefInfo = sessionStorage.getItem("reliefInfo");
        const reliefInfo = getReliefInfo !== null ? JSON.parse(getReliefInfo) : "";
        const prefectureCode = reliefInfo['prefectureCode'] ? reliefInfo['prefectureCode'] : "";

        pageNavigator.current.next(`/call?${QueryParameter.PARAMETER_PREFECTURE_CODE}=${prefectureCode}`);
    }

	//戻るボタン押下処理
    const backClick = () => {
        operation.current.prevent();
        
        if(onBackClick){
            onBackClick();
        }
        else{
            pageNavigator.current.back();
        }
    }

    return (
        <header ref={scrollHeader}>
            <div id="headerWrapper">
                <div id="header">
                    <h1>
                        <img src="/images/logo.svg" alt="JAF（一般社団法人 日本自動車連盟）" />
                        <span>救援要請ウェブサイト</span>
                    </h1>
                    <div className="link">
                        <ul className="link">
                            { tel && <li className="tel" onClick={receptionStatus !== undefined ? callClick : orderContentTel}></li> }
                            { back && <li className="back" onClick={backClick}></li> }
                            { reload && <li className="reload" onClick={() => window.location.reload()}></li> }
                        </ul>
                        { !hideMenu && <div className="openbtn1"><span></span><span></span><span></span></div> }
                    </div>
                </div>
            </div>
            <Menu receptionStatus={receptionStatus} orderConfirmAfter={orderConfirmAfter} chat={chat} logout={logout}/>
        </header>
    )
}