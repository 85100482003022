/**
 * copyrightを取得する。
 *
 * @return copyright。
 */
export const copyright = () => {
    /* eslint-disable no-undef*/
    // default.jsから直接参照
    return getCopyright();
    /* eslint-disable no-undef*/
};

/**
 * footerFixedを取得する。
 * @param {string} footerId フッターのID
 */
export const adjustFooter = (footerId) => {
    /* eslint-disable no-undef*/
    // default.jsから直接参照
    footerFixed(footerId);
    /* eslint-disable no-undef*/
};

