import { ApiRetrySetting } from '../settings/settings';
import * as VersionUtil from '../common/VersionUtil';

type Runner = (url:string, config:any) => Promise<any>;

/**
 * API要求を行う。
 *
 * 本関数は、設定(ApiRetrySetting.COUNT)に従ってリトライを行う。
 *
 * @param {string}  url     APIのURL。
 * @param {any}     config  axiosの設定。
 * @param {any}     success 成功時に呼び出されるコールバック関数。
 * @param {any}     failure 失敗時に呼び出されるコールバック関数。
 * @param {Runner}  runner  API要求を行う関数。
 * @param {boolean} retry   リトライ実行フラグ。
 */
export const requestApi = (url:string, config:any, success:any, failure:any, runner:Runner, retry:boolean = false):void => {
    const cloned = {
        ...config,
        timeout: ApiRetrySetting.TIMEOUT,
    };

    //リトライ不要の場合リトライ回数に0を指定
    const retryCount = retry ? ApiRetrySetting.COUNT : 0;

    requestApiInternal(runner, url, cloned, success, failure, retryCount);
};

/**
 * API要求を行う。
 *
 * 本関数は、指定された回数だけリトライを行う。
 *
 * @param {Runner} runner  API要求を行う関数。
 * @param {string} url     APIのURL。
 * @param {any}    config  axiosの設定。
 * @param {any}    success 成功時に呼び出されるコールバック関数。
 * @param {any}    failure 失敗時に呼び出されるコールバック関数。
 * @param {number} count   リトライ回数。
 */
const requestApiInternal = (runner:Runner, url:string, config:any, success:any, failure:any, count:number):void => {
    VersionUtil.validate(() => {
        runner(url, config).then((results) => {
            console.log('API正常終了 url : ' + url, results);
            success(results);
        }).catch((error) => {
            console.log('API異常終了 url : ' + url, error);
            if (error.code === "ECONNABORTED" && count > 0) {
                // タイムアウトが一定数発生するまでリトライ
                console.log(`retry request (remained=${count})`);
                requestApiInternal(runner, url, config, success, failure, count - 1);
            } else {
                if (failure != null) {
                    failure(error);
                }
            }
        });
    }, failure);
};
