import {AlertMessages} from '../messages';
import  *  as CommonUtil from '../common/CommonUtil';
import * as constants from '../constants';

// エラー発生時の共通処理
export const ErrorTransition = (response:any):any => {
    if(response === constants.ErrorReason.ACCESS_DENIED_ERROR){
        //ページ遷移エラーページへ遷移
        return '/accessDeniedError';
    }
    else if(response === constants.ErrorReason.ORDER_CONTROL_ERROR){
        //ソーリーページへ遷移
        return '/sorry';
    }
    else if(response === constants.ErrorReason.RESPONSE_NOT_FOUND || response === constants.ErrorReason.RESPONSE_TYPE_MISMATCHED){
        //想定外レスポンスエラーページへ遷移
        return '/apiError';
    }
    else if(typeof(response) === "object" && ('response' in response)){
        const status = response?.response?.request?.status;
        switch (status) {
            case 401:
                //認証エラーページへ遷移
                return '/unauthorizedError';
            default:
                return '/error';
        }
    }
    else {
        //想定外エラーページへ遷移
        return '/error';
    }
};

// 非同期チャットAPIエラー発生時の共通処理
export const ErrorChatMessage = (response:any):any => {    
    //エラー時はインジケータ非表示
    CommonUtil.setIndicator(false);
    
    if(!('response' in response)){
        return AlertMessages.RSEWS_ER_22300;
    }
    else{
        let status = response.response.request.status
        switch (status) {
            case 400:
                return AlertMessages.RSEWS_ER_2400;
            case 401:
                return AlertMessages.RSEWS_ER_2401;
            case 403:
                return AlertMessages.RSEWS_ER_2403;
            case 404:
                return AlertMessages.RSEWS_ER_2404;
            case 500:
                return AlertMessages.RSEWS_ER_2500;
            default:
                return AlertMessages.RSEWS_ER_22300;
        }
    }
};
