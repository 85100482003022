import React from "react";
import { Link, useNavigate } from "react-router-dom"
import { Logout } from "../common/Logout";
import { ConfirmModalDialog, LicenceModalDialog } from '../common/Modal';
import * as PagingUtil from '../common/PagingUtil';
import * as CommonUtil from '../common/CommonUtil';

interface MenuProps {
    receptionStatus?: boolean,
    orderConfirmAfter?: boolean,
    chat?: boolean,
    logout?: boolean,
}

export const Menu = (props: MenuProps) => {

    const {receptionStatus, orderConfirmAfter, chat, logout} = props;

    const navigate = useNavigate();
    //多重押下対策
    const operation = React.useRef(new CommonUtil.Operation());

    const displayLicence = () => {
        LicenceModalDialog();
    }

    const menuLogout = () => {
        let comment = 'ログアウトします。<br/>よろしいですか？';
        // ログアウト確認ダイアログ表示
        ConfirmModalDialog(comment, () => {
            Logout();
            // ログイン画面へ遷移
            PagingUtil.resetAndNext("/userLogin", navigate);
        });
    }

    //呼び出し元の画面毎にスタック方法を分岐させる
    const pageTransition = (pageName:string) => {
        //救援要請後のスタックは受付状況確認画面をベースにするため削除しない
        if(CommonUtil.getCurrentPathName() === "/receptionstatus"){
            PagingUtil.next(pageName, navigate);
        }
        else{
            PagingUtil.backAndNext(pageName, navigate);
        } 
    }

    //受付状況確認画面リンク押下時
    function receptionStatusClick(){
        operation.current.prevent();

        if(PagingUtil.isBeforePage("/receptionstatus")){
            PagingUtil.back(navigate);
        }
        else{
            PagingUtil.backAndNext("/receptionstatus", navigate);
        }
    }

    return (
        <nav>
        <div id="navWrapper">
            <div id="nav">
            { (receptionStatus !== undefined || orderConfirmAfter !== undefined || chat !== undefined) &&
                <ul>
                    { receptionStatus !== undefined &&
                    ( receptionStatus ? <li><Link to="/receptionStatus" onClick={receptionStatusClick}>受付状況確認</Link></li> 
                                      : <li className='disabledLink'>受付状況確認</li> ) 
                    }
                    { orderConfirmAfter !== undefined &&
                    ( orderConfirmAfter ? <li><Link to="/orderConfirmAfter" onClick={() => pageTransition("/orderConfirmAfter")}>要請内容確認</Link></li>
                                        : <li className='disabledLink'>要請内容確認</li> ) 
                    }
                    { chat !== undefined &&
                    ( chat ? <li><Link to="/chat/login" onClick={() => pageTransition("/chat/login")}>チャット</Link></li>
                           : <li className='disabledLink'>チャット</li> ) 
                    }
                </ul>
            }
                <ul>
                    <li><a href="/terms.html" target="_blank" rel="noopener">利用約款</a></li>
                    <li><a href="/policy.html" target="_blank" rel="noopener">個人情報の取扱いについて</a></li>
                    <li><a href="/priceInfo.html" target="_blank" rel="noopener">料金案内</a></li>
                </ul>
                <ul>
                    <li><Link to="" onClick={displayLicence}>ライセンスについて</Link></li>
                    { logout !== undefined && 
                    ( logout ? <li><Link to="" onClick={menuLogout} id="logout">ログアウト</Link></li> : <li className="disabledLink">ログアウト</li> ) }
                </ul>
            </div>
        </div>
    </nav>
    )
}
