
import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import * as MapBox from '../mapbox';
import * as MasterInfoGet from "../masterInfoGet";
import { ReceptionStatusMessages } from '../messages';
import { map_box, ArrangementCode, ReceptionStatusCode } from '../constants';
import { Logout } from '../common/Logout';
import { getOrders, cancelOrder } from "../common/api/order"
import { getCarInfo } from "../common/api/carInfo"
import { CancelOrderRequest, OrderInfo } from '../types/orderInfo';
import { CarInfo } from '../types/carInfo';
import * as ReliefUtil from "../common/ReliefUtil";
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';

// モーダル呼び出し関数
import { OrderCancelModalDialog } from '../common/Modal';
import *  as CommonUtil from '../common/CommonUtil';
import { ErrorTransition } from '../common/ErrorUtil';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Cngestion } from '../header/Congestion';

function ReceptionStatus(props:PageProps) {

    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);
    let orderInfo:any = {};
    const navigate = useNavigate();
    const location = useLocation();

    //受付ステータス毎の受付状況文言
    const receptionStatusList:any = {
        [ReceptionStatusCode.WAITING_TO_ACCEPT] : "未受付",
        [ReceptionStatusCode.ACCEPTING] : "受付中",
        [ReceptionStatusCode.RECEPTION_COMPLETION] : "受付完了",
        [ReceptionStatusCode.EXPRESS_IN] : "車両急行中",
        [ReceptionStatusCode.ARRIVAL] : "車両到着",
        [ReceptionStatusCode.WAITING_TO_CANCEL] : "取消受付待ち",
        [ReceptionStatusCode.CANCELLING] : "取消処理中",
    };

    //即時受付かつ到着予定時刻がnullの場合に表示する文言
    const immediatelyMessageList:any = {
        [ReceptionStatusCode.WAITING_TO_ACCEPT] : ReceptionStatusMessages.RSEWS_V21_M002,
        [ReceptionStatusCode.ACCEPTING] : ReceptionStatusMessages.RSEWS_V21_M002,
        [ReceptionStatusCode.RECEPTION_COMPLETION] : ReceptionStatusMessages.RSEWS_V21_M003,
        [ReceptionStatusCode.EXPRESS_IN] : ReceptionStatusMessages.RSEWS_V21_M003,
        [ReceptionStatusCode.ARRIVAL] : ReceptionStatusMessages.RSEWS_V21_M003,
        [ReceptionStatusCode.RELIEF_COMPLETION] : ReceptionStatusMessages.RSEWS_V21_M003,
        [ReceptionStatusCode.WAITING_TO_CANCEL] : ReceptionStatusMessages.RSEWS_V21_M003,
        [ReceptionStatusCode.CANCELLING] : ReceptionStatusMessages.RSEWS_V21_M003,
        [ReceptionStatusCode.CANCEL_COMPLETION] : "",
    };

    //予約受付かつ到着予定時刻がnullの場合に表示する文言
    const reservationMessageList:any = {
        [ReceptionStatusCode.WAITING_TO_ACCEPT] : ReceptionStatusMessages.RSEWS_V21_M002,
        [ReceptionStatusCode.ACCEPTING] : ReceptionStatusMessages.RSEWS_V21_M002,
        [ReceptionStatusCode.RECEPTION_COMPLETION] : ReceptionStatusMessages.RSEWS_V21_M004,
        [ReceptionStatusCode.EXPRESS_IN] : ReceptionStatusMessages.RSEWS_V21_M004,
        [ReceptionStatusCode.ARRIVAL] : ReceptionStatusMessages.RSEWS_V21_M004,
        [ReceptionStatusCode.RELIEF_COMPLETION] : ReceptionStatusMessages.RSEWS_V21_M004,
        [ReceptionStatusCode.WAITING_TO_CANCEL] : ReceptionStatusMessages.RSEWS_V21_M004,
        [ReceptionStatusCode.CANCELLING] : ReceptionStatusMessages.RSEWS_V21_M004,
        [ReceptionStatusCode.CANCEL_COMPLETION] : "",
    };

    //多重押下対策
    const processing = useRef(false);

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    //救援車両の緯度
    let orderLatitude:number = 0;
    //救援車両の経度
    let orderLongitude:number = 0;

    // 初期処理
    useEffect(() => {
        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }
        
        //map初期化
        MapBox.deleteMap();

        function setPush() {
            CommonUtil.requestWebPushPermission().then(function(response: any){
            })
            .catch(function(response: any){
                PagingUtil.resetAndNext(ErrorTransition(response), navigate);
            })
        }

        //初期処理インジケータ表示
        CommonUtil.setIndicator(true);
        //依頼一覧取得
        getOrders(
            function (orderInfoList:Array<OrderInfo>) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                orderInfo = orderInfoList[0];

                //アライアンスサーバー依頼番号を保存
                // eslint-disable-next-line react-hooks/exhaustive-deps 
                reliefInfo = {...reliefInfo, 'orderNum' : orderInfo['orderNum']};
                reliefInfo = {...reliefInfo, 'prefectureCode' : orderInfo['prefectureCode']};
                
                sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));

                //メンテナンスメッセージの取得
                CommonUtil.getMainteMessage().then(function(response: any) {
                    //救援要請制御情報取得
                    return CommonUtil.getOrderControl(false, true, ReliefUtil.getPrefectureCode(reliefInfo));
                })
                .then(function() {
                    //[共通]マスタ情報取得処理
                    MasterInfoGet.RequestMaster(setDisplay, function (chk_error:any) {
                        PagingUtil.resetAndNext(ErrorTransition(chk_error), navigate);
                    })
                })
                .catch(function(response: any){
                    PagingUtil.resetAndNext(ErrorTransition(response), navigate);
                })
            }
            ,function (chk_error:any) {
                //依頼一覧取得エラー
                console.log('依頼一覧取得失敗', chk_error);
                PagingUtil.resetAndNext(ErrorTransition(chk_error), navigate);
            }
        )

        //画面要素生成
        const setDisplay = () => {
            //HTML要素取得
            const received = document.querySelector('#received');
            const recepStatus = document.querySelector('#recepStatus');
            const estime = document.querySelector('#estime');

            //受付日
            if(orderInfo['insDt'] !== null){
                //配列の先頭(最新)のデータを取得
                received!.textContent = setDateFormat(orderInfo['insDt']);
            }
        
            //受付状況
            recepStatus!.textContent = receptionStatusList[orderInfo['receptionStatus']];

            //到着予定時刻
            if(orderInfo['arrivalScheduleDt'] === null){
                //受付ステータス、救援予約日時の組み合わせにより表示する文言を切り分ける
                if(orderInfo['reliefReservationDt'] === null){
                    //即時受付
                    estime!.textContent = immediatelyMessageList[orderInfo['receptionStatus']];
                }
                else{
                    //予約受付
                    estime!.textContent = reservationMessageList[orderInfo['receptionStatus']];
                }
            }
            else{
                
                estime!.textContent = setDateFormat(orderInfo['arrivalScheduleDt']);

                let arrivalDt = new Date(orderInfo['arrivalScheduleDt']);                    
                let hh = ('0' + arrivalDt.getHours()).slice(-2).toString();
                let mm = ('0' + arrivalDt.getMinutes()).slice(-2).toString();

                estime!.textContent += " " + hh + ":" + mm;
            }

            //緯度
            // eslint-disable-next-line react-hooks/exhaustive-deps 
            orderLatitude = orderInfo['latitude']
            //経度
            // eslint-disable-next-line react-hooks/exhaustive-deps 
            orderLongitude = orderInfo['longitude']

            //マップを生成
            MapBox.receptionDisplayMap(map_box.MAP_STYLE, orderLongitude,orderLatitude,"mapObject");

            //救援要請車両座標
            let x1:number = orderLongitude;
            let y1:number = orderLatitude; 

            //号車情報取得
            getCarInfo(orderInfo['orderNum']
                ,function (carInfoList:Array<CarInfo>) {

                    if(carInfoList.length > 0){
                        //救援車両が手配されていた場合、2点が画面内に収まるように表示する
                        if(carInfoList[0]['longitude'] !== null && carInfoList[0]['latitude'] !== null){
                            //救援要請場所へ向かう車両の現在の緯度経度を設定
                            //緯度
                            let x2:number = Number(carInfoList[0]['longitude']);
                            //経度
                            let y2:number = Number(carInfoList[0]['latitude']);

                            //任意の2点で自動縮尺
                            MapBox.changeRange(x1,y1,x2,y2,"mapObject");
                        }
                        else{
                            //現在地にマーカーをセット
                            MapBox.setCustomMarker(x1,y1,"marker_user","mapObject");
                        }
                    }
                    else{
                        //現在地にマーカーをセット
                        MapBox.setCustomMarker(x1,y1,"marker_user","mapObject");
                    }

                    //初期処理インジケータ非表示
                    CommonUtil.setIndicator(false);

                    //通知パーミッション取得
                    setPush();

                    setDisplayFooter(true);

                }
                ,function (chk_error:any) {
                    // 号車情報取得失敗時
                    console.log('号車情報取得失敗', chk_error);
                    PagingUtil.resetAndNext(ErrorTransition(chk_error), navigate);
                }
            );
        }

        // YYYY/MM/DD形式に整形
        function setDateFormat(datetime:any) {
            let date = new Date(datetime);
            let year = date.getFullYear().toString();
            let month = ('0' + (date.getMonth() + 1)).slice(-2).toString();
            let day = ('0' + date.getDate()).slice(-2).toString();
            return year + "/" + month + "/" + day
        }
    
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);
    
    //JAFにチャット押下処理
    const chatClick = () => {
        PagingUtil.next("/chat/login", navigate);
    }

    //救援要請の確認押下処理
    const confirmClick = () => {
        PagingUtil.next("/orderConfirmAfter", navigate);
    }

    //救援要請をキャンセル押下処理
    const cancelClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        //多重押下対策
        e.preventDefault();
        if (processing.current) return;
        processing.current = true;

        const dialog = OrderCancelModalDialog((code, reason):boolean => {
            // 要請キャンセルダイアログ内「キャンセルする」ボタン押下時
            if (processing.current) return false;
            processing.current = true;

            const orderNum = reliefInfo['orderNum']; // アライアンスサーバ依頼番号
            const cancelReasonCode = code; // キャンセル理由区分コード
            const cancelReason = reason === '' ? null : reason; // キャンセル理由（自由入力）
            const cancel_param: CancelOrderRequest = {
                orderNum: orderNum,
                cancelReasonCode: cancelReasonCode,
                cancelReason: cancelReason,
                arrangementCode: ArrangementCode.RELIEF_RESERVATION_DT_CANCEL
            }

            CommonUtil.setIndicator(true);
            // 依頼キャンセルAPI呼び出し
            cancelOrder(cancel_param, function () {
                dialog.close();

                // ログアウト
                Logout().finally(() => { 
                    CommonUtil.setIndicator(false);
                    processing.current = false;
                    // 要請キャンセル完了画面へ遷移
                    PagingUtil.resetAndNext("/orderCancel", navigate);
                })
            }
            ,function (chk_error:any) {
                dialog.close();
                CommonUtil.setIndicator(false);
                processing.current = false;
                PagingUtil.resetAndNext(ErrorTransition(chk_error), navigate);
            });

            return false;
        }, () => {
            //「×」ボタン押下時
            processing.current = false;
        });

        processing.current = false;
    }

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>受付状況確認 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header tel reload receptionStatus={false} orderConfirmAfter chat logout pageNavigator={props.pageNavigator}/>
            <div className="contentsWrapper">
                <div className="contents">
                    <div className="mapWrapper auto">
                        <div id="map" className="map5"></div>
                        <div className="buttonWrapper relative wide">
                            <dl className="request noborder">
                                <dt>受付日</dt>
                                <dd id="received"></dd>
                                <dt>受付状況</dt>
                                <dd id="recepStatus"></dd>
                                <dt>到着予定時刻</dt>
                                <dd id="estime"></dd>
                            </dl>
                            <p className="pl">救援車両の位置更新は約5分間隔です。<br />一部のサービスカーは表示されない場合があります。</p>
                            <div className="submitWrapper narrow">
                                <div className="submit">
                                    <input type="submit" value="JAFにチャット"  onClick={chatClick} /> 
                                </div>
                                <div className="submit">
                                    <input type="submit" value="救援要請の確認"  onClick={confirmClick}/> 
                                </div>
                                <div className="submit">
                                    <input type="submit" value="救援要請をキャンセル"  onClick={(e) => cancelClick(e)}/> 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer display={displayFooter}/>
            <Cngestion />
        </div>
    )
}
 
export default ReceptionStatus