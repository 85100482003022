import React from 'react';
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async'
import purify from 'dompurify';
import * as CommonUtil from '../common/CommonUtil';
import * as PagingUtil from '../common/PagingUtil';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { useLocation } from 'react-router';
import { CallCenterNumber, QueryParameter } from '../constants';
import PageProps from '../types/pageProps';

function Call(props:PageProps) {

  const location = useLocation();
  const [callHtml, setCallHtml] = useState("");
  const [words, setWords] = useState("");

  //フッター表示用State
  const [displayFooter, setDisplayFooter] = useState(false);

  useEffect(() => {
    const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);

    const ret = createCall();
    setCallHtml(ret.html);
    setWords(ret.words);

    setDisplayFooter(true);
    return initResult['cleanup'];
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  []);

  const callInfoList = [
    {
      "controlCenterName": "関東コールセンター",
      "callCenterNumber": CallCenterNumber.KANTO,
      "areaInfoList":[
        {
          "areaName":"北海道",
          "prefectureInfoList":[
            {
              "prefectureCode":"1",
              "prefectureName":"札幌"
            },
            {
              "prefectureCode":"1",
              "prefectureName":"函館",
            },
            {
              "prefectureCode":"1",
              "prefectureName":"旭川",
            },
            {
              "prefectureCode":"1",
              "prefectureName":"釧路",
            },
            {
              "prefectureCode":"1",
              "prefectureName":"帯広",
            },
            {
              "prefectureCode":"1",
              "prefectureName":"北見",
            }
          ]
        },
        {
          "areaName":"東北",
          "prefectureInfoList":[
            {
              "prefectureCode":"2",
              "prefectureName":"青森"
            },
            {
              "prefectureCode":"3",
              "prefectureName":"岩手",
            },
            {
              "prefectureCode":"4",
              "prefectureName":"宮城",
            },
            {
              "prefectureCode":"5",
              "prefectureName":"秋田",
            },
            {
              "prefectureCode":"6",
              "prefectureName":"山形",
            },
            {
              "prefectureCode":"7",
              "prefectureName":"福島"
            },
          ]
        },
        {
          "areaName":"関東",
          "prefectureInfoList":[
            {
              "prefectureCode":"8",
              "prefectureName":"茨城",
            },
            {
              "prefectureCode":"9",
              "prefectureName":"栃木"
            },
            {
              "prefectureCode":"10",
              "prefectureName":"群馬",
            },
            {
              "prefectureCode":"11",
              "prefectureName":"埼玉",
            },
            {
              "prefectureCode":"12",
              "prefectureName":"千葉",
            },
            {
              "prefectureCode":"13",
              "prefectureName":"東京"
            },
            {
              "prefectureCode":"14",
              "prefectureName":"神奈川",
            },
            {
              "prefectureCode":"15",
              "prefectureName":"新潟"
            },
            {
              "prefectureCode":"19",
              "prefectureName":"山梨",
            },
            {
              "prefectureCode":"20",
              "prefectureName":"長野",
            }
          ]
        }
      ]
    },
    {
      "controlCenterName": "中部コールセンター",
      "callCenterNumber": CallCenterNumber.CHUBU,
      "areaInfoList":[
        {
          "areaName":"中部",
          "prefectureInfoList":[
            {
              "prefectureCode":"16",
              "prefectureName":"富山",
            },
            {
              "prefectureCode":"17",
              "prefectureName":"石川"
            },
            {
              "prefectureCode":"18",
              "prefectureName":"福井",
            },
            {
              "prefectureCode":"21",
              "prefectureName":"岐阜",
            },
            {
              "prefectureCode":"22",
              "prefectureName":"静岡",
            },
            {
              "prefectureCode":"23",
              "prefectureName":"愛知"
            },
            {
              "prefectureCode":"24",
              "prefectureName":"三重",
            }
          ]
        }
      ]
    },
    {
      "controlCenterName": "関西コールセンター",
      "callCenterNumber": CallCenterNumber.KANSAI,
      "areaInfoList":[
        {
          "areaName":"関西",
          "prefectureInfoList":[
            {
              "prefectureCode":"25",
              "prefectureName":"滋賀"
            },
            {
              "prefectureCode":"26",
              "prefectureName":"京都",
            },
            {
              "prefectureCode":"27",
              "prefectureName":"大阪",
            },
            {
              "prefectureCode":"28",
              "prefectureName":"兵庫"
            },
            {
              "prefectureCode":"29",
              "prefectureName":"奈良",
            },
            {
              "prefectureCode":"30",
              "prefectureName":"和歌山",
            }

          ]
        },
        {
          "areaName":"中国",
          "prefectureInfoList":[
            {
              "prefectureCode":"31",
              "prefectureName":"鳥取"
            },
            {
              "prefectureCode":"32",
              "prefectureName":"島根",
            },
            {
              "prefectureCode":"33",
              "prefectureName":"岡山",
            },
            {
              "prefectureCode":"34",
              "prefectureName":"広島",
            },
            {
              "prefectureCode":"35",
              "prefectureName":"山口",
            }
          ]
        },
        {
          "areaName":"四国",
          "prefectureInfoList":[
            {
              "prefectureCode":"36",
              "prefectureName":"徳島"
            },
            {
              "prefectureCode":"37",
              "prefectureName":"香川",
            },
            {
              "prefectureCode":"38",
              "prefectureName":"愛媛",
            },
            {
              "prefectureCode":"39",
              "prefectureName":"高知",
            }
          ]
        }
      ]
    },
    {
      "controlCenterName": "九州コールセンター",
      "callCenterNumber": CallCenterNumber.KYUSHU,
      "areaInfoList":[
        {
          "areaName":"九州",
          "prefectureInfoList":[
            {
              "prefectureCode":"40",
              "prefectureName":"福岡",
            },
            {
              "prefectureCode":"41",
              "prefectureName":"佐賀"
            },
            {
              "prefectureCode":"42",
              "prefectureName":"長崎",
            },
            {
              "prefectureCode":"43",
              "prefectureName":"熊本",
            },
            {
              "prefectureCode":"44",
              "prefectureName":"大分",
            },
            {
              "prefectureCode":"45",
              "prefectureName":"宮崎"
            },
            {
              "prefectureCode":"46",
              "prefectureName":"鹿児島",
            },
            {
              "prefectureCode":"47",
              "prefectureName":"沖縄",
            }
          ]
        }
      ]
    }
  ];

  const findCallInfo = (prefectureCode:string|null):any => {
      for (var i=0; i < callInfoList.length; i++ ) {
        const tempCallInfoList = callInfoList[i];
        for (var j=0; tempCallInfoList.areaInfoList && j < tempCallInfoList.areaInfoList.length; j++ ) {
          const tempAreaInfoList = tempCallInfoList.areaInfoList[j];
          for (var k=0; tempAreaInfoList.prefectureInfoList && k < tempAreaInfoList.prefectureInfoList.length; k++ ) {
            const tempPrefectureInfoList = tempAreaInfoList.prefectureInfoList[k];
            if (tempPrefectureInfoList.prefectureCode === prefectureCode) {
              return tempCallInfoList;
            }
          }
        }
      }
      return undefined;
  };

  function createCall() {
    let html = '';
    let words = '';

    //遷移元画面で特定した都道府県コードをURLパラメータで受け取る
    let prefectureCode:string|null = null;
    const urlParam = document.location.search;
    if (0 < urlParam.length) {
      const params = new URLSearchParams(urlParam.substring(1));
      prefectureCode = params.get(QueryParameter.PARAMETER_PREFECTURE_CODE);
    }

    let callInfo:any;
    if (prefectureCode) {
      callInfo = findCallInfo(prefectureCode);
    }

    //存在しない都道府県コードの場合
    if (callInfo === undefined) {
      words = 'お困りの地域の';
      for(var i=0; i<callInfoList.length; i++) {
        callInfo = callInfoList[i];
        if(html !== '') {
          html += '</p></div>';
        }
        html = createDivCall(html, callInfo);
      }
    }
    else {//存在する都道府県コードの場合
      words = '以下の';
      html = createDivCall(html, callInfo);
    }

    return { html: html, words: words };
  }

  function createDivCall(html:string, callInfo:any) {
    html += '<div class="call">';
    html += '<p class="call">';
    html += '<span class="center strong blue">'+callInfo.controlCenterName+'</span>';
    html += '<span class="tel desktop">'+callInfo.callCenterNumber+'</span>';
    html += ' <span class="tel mobile"><a href="tel:'+callInfo.callCenterNumber+'"><button class="jaf wide">'+callInfo.callCenterNumber+'</button></a></span>';
    html += '</p>';
    html += '<p class="call">';

    return createSpanPref(html, callInfo);
  }

  function createSpanPref(html:string, callInfo:any) {
    let tmpAreaName = '';
    let prefectureNames = '';

    for (var j=0; callInfo && j< callInfo.areaInfoList.length; j++ ) {
      const tempAreaInfoList = callInfo.areaInfoList[j];
      for (var k=0; k< tempAreaInfoList.prefectureInfoList.length; k++ ) {
        if(tmpAreaName !== tempAreaInfoList.areaName){
          tmpAreaName = tempAreaInfoList.areaName;

          if(prefectureNames !== '') {
            html +=  '<br>'+prefectureNames+'</span>';
          }
          html += '<span class="pref"><span class="strong">'+tempAreaInfoList?.areaName+'</span>';
          prefectureNames = '';
            prefectureNames += tempAreaInfoList.prefectureInfoList[k].prefectureName;
        }
        else{
          prefectureNames += '・'+tempAreaInfoList.prefectureInfoList[k].prefectureName;
        }
      }
    }
    html +=  '<br>'+prefectureNames+'</span>';

    return html;
  }


  return (

    <div>

    <HelmetProvider>
      <Helmet>
        <title>電話案内 | JAF救援要請ウェブサイト</title>
      </Helmet>
    </HelmetProvider>

    <Header back={PagingUtil.hasBeforePage()} pageNavigator={props.pageNavigator}/>
    <div className="contentsWrapper">
    <div className="contents">

    <p className="general">
    {words}<span className="mobile">ボタン</span><span className="desktop">番号</span>からJAFへお電話をお願いいたします。
    </p>

    <div dangerouslySetInnerHTML={{__html: purify.sanitize(callHtml)}}></div>


    <p className="general">
    ※通話料は有料。
    </p>

    </div>
    </div>

    <Footer display={displayFooter}/>
    
    </div>
  );
}

export default Call;