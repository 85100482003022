import SessionStorage from './SessionStorage';

const NAME:string = "pagingHistory";

export interface PagingHistoryInfo {
    pageName: string;
    option: object|null
}

/**
 * 画面の遷移履歴情報をスタック管理するクラス。
 */
export default class PagingHistory extends SessionStorage {
    /**
     * コンストラクタ。
     */
    constructor() {
        super(NAME);
    }
    /**
     * 現在の履歴情報を取得する
     */
    get _stack():Array<PagingHistoryInfo> {
        return this.get("paging", []) as Array<PagingHistoryInfo>;
    }
    
    /**
     * 先頭の履歴情報を取得する
     */
    get top():PagingHistoryInfo|null {
        const data = this._stack;
        if(data.length > 0){
            return data[0];
        }
        else{
            return null;
        }
    }

    /**
     * 現在の履歴情報を取得する
     */
    get current():PagingHistoryInfo|null {
        const data = this._stack;
        if(data.length > 0){
            return data[data.length-1];
        }
        else{
            return null;
        }
    }

    /**
     * 前画面の履歴情報を取得する
     */
    get before():PagingHistoryInfo|null {
        const data = this._stack;
        if(data.length > 1){
            return data[data.length-2];
        }
        else{
            return null;
        }
    }

    /**
     * 末尾に要素を追加する
     *
     * @param {PagingHistoryInfo} data 画面遷移情報
     */
    push(data:PagingHistoryInfo):void {
        if (!this._data.paging) {
            this._data.paging = [];
        }
        this._data.paging.push(data);
    }
    
    /**
     * 末尾の要素を取り出す
     *
     * @param {PagingHistoryInfo} data 画面遷移情報
     */
    pop():PagingHistoryInfo|null {
        if(this._data.paging && this._data.paging.length > 0){
            return this._data.paging.pop();
        }
        else{
            return null;
        }
    }
    
    /**
     * 履歴が存在するかを判定する
     *
     */
    isEmpty(): boolean{
        const data = this._stack;

        if(data.length > 0){
            return false;
        }
        else{
            return true;
        }
    }

    /**
     * SessionStorageから項目を削除する。
     */
    static clean() {
        SessionStorage.clear(NAME);
    }
}
