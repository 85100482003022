// 本番環境

export default class EnvSettings {
    static readonly API_DOMAIN          = "https://rsrescue-webapi.jaf8139.jp";
    static readonly CHAT_API_DOMAIN     = "https://rsrescue-chatapi.jaf8139.jp";
    static readonly MAIL_ADDRESS        = "info@rsrescue.jaf.or.jp";
    static readonly MAIL_DOMAIN         = "@rsrescue.jaf.or.jp";
    static readonly MAP_STYLE           = "mapbox://styles/jafrs202305/clk0tf30o003f01r57iszcruf";
    static readonly SERVICE_KEY         = "uPGEmgxz5P8enEib";
    static readonly CHAT_SERVICE_KEY    = "uPGEmgxz5P8enEib";
    static readonly KANTO_CALL_CENTER    = "03-4332-4056";
    static readonly CHUBU_CALL_CENTER    = "052-300-8649";
    static readonly KANSAI_CALL_CENTER    = "06-6734-7559";
    static readonly KYUSHU_CALL_CENTER    = "092-235-5262";
    static readonly POLLING_TIME = 30000; //30秒

    //MapBox関連設定
    public static MapBox = class extends EnvSettings {
        //static readonly REVERSE_GEOCODING_URL = "https://api.mapbox.com/search/v1/reverse/";           //リバースジオコーディングAPI
        static readonly REVERSE_GEOCODING_URL = "https://api.mapbox.com/search/v1/permanent/reverse/";   //パーマネントリバースジオコーディングAPI
    };
}
