import React, {useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import * as MapBox from '../mapbox';
import { map_box, MemberFlg } from '../constants';
import * as CommonUtil from '../common/CommonUtil';
import * as MasterInfoUtil from '../common/MasterInfoUtil';
import MasterInfo from "../common/MasterInfo"
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Cngestion } from '../header/Congestion';
import { ErrorTransition } from '../common/ErrorUtil';
import { getOrderInfo } from "../common/api/orderInfo"
import { OrderInfo } from "../types/orderInfo"
import { AlertMessages } from '../messages';
import { ModalDialog } from '../common/Modal';
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';


//画像のサイズ
const IMAGESIZE_WIDTH:number = 400;
const IMAGESIZE_HEIGHT:number = 400;

function OrderConfirmAfter(props:PageProps) {

    const getReliefInfo = sessionStorage.getItem('reliefInfo');
    const reliefInfo = (getReliefInfo === undefined || getReliefInfo === null) ? "" : JSON.parse(getReliefInfo);
    const mstInfo:MasterInfo = new MasterInfo();
    const { roadLocationInfoList } = mstInfo;

    const [fourWheel, setFourWheel] = useState(false);
    const [destination, setDestination] = useState(true);
    const [memberFlg, setMemberFlg] = useState(false);
    const [image, setImage] = useState(true);

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
        // 初期処理
    useEffect(() => {

        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }

        //map初期化
        MapBox.deleteMap();

        
        // 救援要請制御確認
        const checkOrderControl = (prefectureCode:string) => {
            //救援要請制御情報取得
            CommonUtil.getOrderControl(false,true,prefectureCode).then(function(response: any){ 
                CommonUtil.setIndicator(false);
                //救援要請制御ダイアログは表示されない                
            })
            .catch(function(response: any){
                PagingUtil.resetAndNext(ErrorTransition(response), navigate);
            })
        }

        // 依頼情報取得API
        const orderNum = reliefInfo['orderNum'];
        CommonUtil.setIndicator(true);
        //依頼情報取得
        getOrderInfo(orderNum
            ,function (chk_isOrderinfo:OrderInfo) {
                //マップを生成
                MapBox.staticMap(map_box.MAP_STYLE, chk_isOrderinfo.longitude,chk_isOrderinfo.latitude,"map1");
                //マーカーをセット
                MapBox.setCustomMarker(chk_isOrderinfo.longitude,chk_isOrderinfo.latitude, "marker_user","map1")
                //HTML要素取得
                const geocodeReliefAddress = document.querySelector('#geocodeReliefAddress');
                const locationCode = document.querySelector('#locationCode');
                const reliefLocationSupplementInfo = document.querySelector('#reliefLocationSupplementInfo'); 

                //救援要請制御情報取得
                checkOrderControl(chk_isOrderinfo['prefectureCode']);

                //住所取得
                geocodeReliefAddress!.textContent = chk_isOrderinfo.geocodeReliefAddress;
                //道路場所区分コード取得
                locationCode!.textContent = MasterInfoUtil.findRoadLocationName(chk_isOrderinfo.roadCode, chk_isOrderinfo.locationCode, roadLocationInfoList);
                //救援場所補足情報取得
                reliefLocationSupplementInfo!.textContent = chk_isOrderinfo.reliefLocationSupplementInfo;
                
                //HTML要素取得
                const userNameKanji = document.querySelector('#userNameKanji');
                const userNameKana = document.querySelector('#userNameKana');
                const telNum = document.querySelector('#telNum');
                const bkTelNum = document.querySelector('#bkTelNum');
                const memberFlg = document.querySelector('#memberFlg');
                const membershipNum = document.querySelector('#membershipNum');

                //氏名(漢字)取得
                userNameKanji!.textContent = chk_isOrderinfo.userNameKanji;
                //氏名(カナ)取得
                userNameKana!.textContent = chk_isOrderinfo.userNameKana;
                //電話番号取得
                telNum!.textContent = chk_isOrderinfo.telNum;
                //予備電話番号取得
                bkTelNum!.textContent = chk_isOrderinfo.bkTelNum;

                //会非フラグ取得
                memberFlg!.textContent = CommonUtil.getMemberInformation(chk_isOrderinfo.memberFlg);

                if(chk_isOrderinfo.memberFlg === MemberFlg.MEMBER){
                    setMemberFlg(true);
                    //会員番号取得
                    membershipNum!.textContent = chk_isOrderinfo.membershipNum;
                }
                else{
                    setMemberFlg(false);
                }

                //HTML要素取得
                const type = document.querySelector('#type');
                const carMakerCode = document.querySelector('#carMakerCode');
                const carNameCode = document.querySelector('#carNameCode');
                const carColorCode = document.querySelector('#carColorCode');
                const licencePlate = document.querySelector('#licencePlate');
                const carWeight = document.querySelector('#carWeight');
                const vehicleCode = document.querySelector('#vehicleCode');
                let twoWheelsFlg;
                //車両通称名マスタ取得
                const carNameInfo = mstInfo['carNameInfoList'];
                if(carNameInfo !== undefined) {
                    for(let i=0; i<carNameInfo.length; i++){
                        if(carNameInfo[i]["carMakerCode"] === chk_isOrderinfo.carMakerCode && carNameInfo[i]["carNameCode"] === chk_isOrderinfo.carNameCode){

                            twoWheelsFlg = carNameInfo[i]["twoWheelsFlg"];
                            if(twoWheelsFlg === "0"){
                                //4輪の場合、車両名称取得
                                carNameCode!.textContent = carNameInfo[i]["carName"];
                            }
                        }
                    }
                }

                //車両通称名マスタの二輪フラグが'1'の場合
                if(twoWheelsFlg === "1"){
                    //二輪の場合
                    //駆動区分コードに応じて車両情報の表示項目を変更する
                    setFourWheel(false);
                    type!.textContent = "二輪";

                    //車両種別（車種区分）マスタ取得
                    const vehicleInfo = mstInfo['vehicleInfoList'];
                    if(vehicleInfo !== undefined) {
                        for(let i=0; i<vehicleInfo.length; i++){
                            if(vehicleInfo[i]["vehicleCode"] === chk_isOrderinfo.vehicleCode){
                                //種別取得
                                vehicleCode!.textContent = vehicleInfo[i]["vehicleName"];
                            }
                        }
                    }
                }
                else{
                    //四輪の場合
                    //駆動区分コードに応じて車両情報の表示項目を変更する
                    setFourWheel(true);
                    type!.textContent = "四輪";

                    //車両メーカーマスタ取得
                    const carMakerInfo = mstInfo['carMakerInfoList'];
                    if(carMakerInfo !== undefined) {
                        for(let i=0; i<carMakerInfo.length; i++){
                            if(carMakerInfo[i]["carMakerCode"] === chk_isOrderinfo.carMakerCode){
                                //車両メーカー取得
                                carMakerCode!.textContent = carMakerInfo[i]["carMakerName"];
                            }
                        }
                    }

                    //車色マスタ取得
                    const carColorInfo = mstInfo['carColorInfoList'];
                    if(carColorInfo !== undefined) {
                        for(let i=0; i<carColorInfo.length; i++){
                            if(carColorInfo[i]["carColorCode"] === chk_isOrderinfo.carColorCode){
                                //カラー取得
                                carColorCode!.textContent = carColorInfo[i]["carColorName"];
                            }
                        }
                    }

                    //ナンバープレート(本拠地)の取得
                    const carNumberPlaceName = chk_isOrderinfo?.licencePlatePlace ?? "";

                    //登録ナンバー(分類番号)取得
                    let licencePlateClassification = "";
                    if(chk_isOrderinfo.licencePlateClassification !== null){
                        licencePlateClassification = chk_isOrderinfo.licencePlateClassification;
                    }

                    //登録ナンバー(事業用判別文字)取得
                    let licencePlateDistinction = "";
                    if(chk_isOrderinfo.licencePlateDistinction !== null){
                        licencePlateDistinction = chk_isOrderinfo.licencePlateDistinction;
                    }

                    //登録ナンバー(指定番号)取得
                    let licencePlateNumber = "";
                    if(chk_isOrderinfo.licencePlateNumber !== null){
                        licencePlateNumber = chk_isOrderinfo.licencePlateNumber
                    }

                    //ナンバープレート作成
                    licencePlate!.textContent = carNumberPlaceName + "　" + licencePlateClassification + "　" + licencePlateDistinction + "　" + licencePlateNumber;
                }

                if(chk_isOrderinfo.carWeight !== null){
                    //車両重量取得
                    carWeight!.textContent = chk_isOrderinfo.carWeight + "kg";
                }

                //HTML要素取得
                const orderCode = document.querySelector('#orderCode');
                const orderContentCode = document.querySelector('#orderContentCode');

                //トラブル内容マスタ取得
                // let orderInfo;
                let orderInfo = mstInfo['orderInfoList'];
                if(orderInfo !== undefined) {
                    for(let i=0; i<orderInfo.length; i++){
                        if(orderInfo[i]["orderCode"] === chk_isOrderinfo.orderCode){
                            //トラブル内容取得
                            orderCode!.textContent = orderInfo[i]["orderName"];
                        }
                    }
                }

                //ご希望内容マスタ取得
                let orderContentInfo = mstInfo['orderContentInfoList'];
                if(orderContentInfo !== undefined) {
                    for(let i=0; i<orderContentInfo.length; i++){
                        if(orderContentInfo[i]["orderCode"] === chk_isOrderinfo.orderCode && orderContentInfo[i]["orderContentCode"] === chk_isOrderinfo.orderContentCode){
                            //ご希望内容取得
                            orderContentCode!.textContent = orderContentInfo[i]["orderContentName"];
                        }
                    }
                }

                //運び先が入力されている場合、運び先を表示する。
                if(chk_isOrderinfo.geocodeEntryPlaceAddress !== null){
                    setDestination(true);
                    //HTML要素取得
                    const entryPlaceName = document.querySelector('#entryPlaceName');
                    //お運び先店舗名取得
                    entryPlaceName!.textContent = chk_isOrderinfo.entryPlaceName;
                    //mapboxを表示
                    //マップを生成
                    MapBox.staticMap(map_box.MAP_STYLE, chk_isOrderinfo.entryPlaceLongitude,chk_isOrderinfo.entryPlaceLatitude,"map2");
                    //マーカーをセット
                    MapBox.setCustomMarker(chk_isOrderinfo.entryPlaceLongitude,chk_isOrderinfo.entryPlaceLatitude, "marker_jaf","map2");
                }
                else{
                    //運び先を表示しない。
                    setDestination(false);
                }
                
                if(chk_isOrderinfo.image){

                    CommonUtil.draw("image", chk_isOrderinfo.image, IMAGESIZE_WIDTH, IMAGESIZE_HEIGHT).then(function(response: any){
                        setDisplayFooter(true);
                        if(response){
                            CommonUtil.setIndicator(false);
                            setImage(true);
                        }
                        else{
                            //ファイル読み込みエラー
                            CommonUtil.setIndicator(false);
                            setImage(false);
                            //アラートダイアログ表示
                            ModalDialog(AlertMessages.RSEWS_ER_92210);
                        }
                    });
                }
                else{
                    setDisplayFooter(true);
                    setImage(false);
                    CommonUtil.setIndicator(false);
                }
            }
            ,function (chk_error:any) {
                // 依頼情報取得失敗時
                console.log('依頼情報取得失敗', chk_error);
                CommonUtil.setIndicator(false);
                setImage(false);
                PagingUtil.resetAndNext(ErrorTransition(chk_error), navigate);
            })
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

    //受付状況の確認押下処理
    const receptionClick = () => {
        PagingUtil.backAndNext("/receptionStatus", navigate);
    }

    //ヘッダーの戻るボタン押下時の処理
    function backClick(){
        if(PagingUtil.isBeforePage("/receptionstatus")){
            PagingUtil.back(navigate);
        }
        else{
            PagingUtil.backAndNext("/receptionstatus", navigate);
        }
    }

    return (
        
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>要請内容確認（要請後） | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>

            <Header back receptionStatus orderConfirmAfter={false} chat logout onBackClick={backClick} pageNavigator={props.pageNavigator}/>

            <div className="contentsWrapper">
                <div className="contents">
                    <p className="title large">救援場所</p>

                    <div className="mapWrapper auto">
                        <div id="map1" className="map small">
                        </div>

                        <div className="buttonWrapper relative wide">
                            <dl className="request noborder">
                                <dt>住所</dt>
                                <dd className="newline" id="geocodeReliefAddress"></dd>
                                <dt>お車の位置</dt>
                                <dd className="newline" id="locationCode"></dd>
                                <dt>その他</dt>
                                <dd className="newline" id="reliefLocationSupplementInfo"></dd>
                            </dl>
                        </div>
                    </div>

                    <p className="title large">お客様情報</p>
                    <dl className="request">
                        <dt>氏名</dt>
                        <dd className="newline" id="userNameKanji"></dd>
                        <dt>氏名（カナ）</dt>
                        <dd className="newline" id="userNameKana"></dd>
                        <dt>電話番号</dt>
                        <dd id="telNum"></dd>
                        <dt>予備の電話番号</dt>
                        <dd id="bkTelNum"></dd>
                        <dt>JAFへの入会</dt>
                        <dd id="memberFlg"></dd>
                        <dt style={{ display: memberFlg ? '' : 'none' }}>会員番号</dt>
                        <dd style={{ display: memberFlg ? '' : 'none' }} id="membershipNum"></dd>
                    </dl>

                    <p className="title large">車両情報</p>


                    <dl className="request">
                        <dt>種別</dt>
                        <dd id="type"></dd>
                        <dt style={{ display: fourWheel ? '' : 'none' }}>車両メーカー</dt>
                        <dd style={{ display: fourWheel ? '' : 'none' }} id="carMakerCode"></dd>
                        <dt style={{ display: fourWheel ? '' : 'none' }}>車両名称</dt>
                        <dd style={{ display: fourWheel ? '' : 'none' }} id="carNameCode"></dd>
                        <dt style={{ display: fourWheel ? '' : 'none' }}>カラー</dt>
                        <dd style={{ display: fourWheel ? '' : 'none' }} id="carColorCode"></dd>
                        <dt style={{ display: fourWheel ? '' : 'none' }}>ナンバープレート</dt>
                        <dd style={{ display: fourWheel ? '' : 'none' }} id="licencePlate"></dd>
                        <dt style={{ display: fourWheel ? 'none' : '' }}>車両区分</dt>
                        <dd style={{ display: fourWheel ? 'none' : '' }} id="vehicleCode"></dd>
                        <dt>車両重量</dt>
                        <dd id="carWeight"></dd>
                    </dl>

                    <p className="title large">要請内容</p>

                    <dl className="request">
                        <dt>トラブル内容</dt>
                        <dd id="orderCode"></dd>
                        <dt>ご希望内容</dt>
                        <dd id="orderContentCode"></dd>
                        <dt>画像</dt>
                        <dd>
                            <div className="preview">
                                {image && <canvas id="image" className="imgStyle" width={IMAGESIZE_WIDTH} height={IMAGESIZE_HEIGHT} ></canvas> }
                            </div>
                        </dd>
                    </dl>

                    <p className="title large"  style={{ display: destination ? '' : 'none' }}>お運び先</p>
                    <div className="mapWrapper auto"  style={{ display: destination ? '' : 'none' }}>
                        <div id="map2" className="map small">
                        </div>

                        <div className="buttonWrapper relative wide">

                            <dl className="request">
                                <p className="newline" id="entryPlaceName"></p>
                            </dl>

                        </div>
                    </div>

                    <div className="submit half">
                        <input type="submit" value="受付状況の確認"  onClick={receptionClick}/> 
                    </div>
                </div>
            </div>
            <Footer display={displayFooter}/>
            <Cngestion />
        </div>
    )
}
export default OrderConfirmAfter