import { getChatLatestMessages } from '../common/api/chat/latestMessageInfo';
import { getChatMessages, sendChatMessage, updateChageMessageReadDate } from '../common/api/chat/messageInfo';
import * as ChatImageApi from '../common/api/chat/image';
import { LatestChatMessageList } from '../types/chatInfo';

// 最新チャットメッセージ取得
export const getLastMessageInfo = (messageId:string):any => {

    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    const orderNum = reliefInfo['orderNum'] || "";

    if(orderNum.length === 0){
        return false;
    }

    return new Promise((resolve, reject) => {
        getChatLatestMessages(orderNum, messageId
        ,function (response:LatestChatMessageList) {
            return resolve(response);
        }
        ,function (error:any) {
            // 取得失敗時
            console.log('取得失敗', error);
            return reject(error);
        })
    });

};

// チャットメッセージ取得
export const getMessageInfo = (messageId:string|null):any => {

    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    const orderNum = reliefInfo['orderNum'] || "";

    if( orderNum.length === 0){
        return false;
    }

    return new Promise((resolve, reject) => {
        getChatMessages(orderNum, messageId
        ,function (response:any) {
            return resolve(response);
        }
        ,function (error:any) {
            // 取得失敗時
            console.log('取得失敗', error);
            return reject(error);
        });
    });

};

// チャットメッセージ送信
export const sendMessageInfo = (message:string):any => {

    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    const orderNum = reliefInfo['orderNum'] || "";

    if(orderNum.length === 0){
        return false;
    }

    return new Promise((resolve, reject) => {
        sendChatMessage(orderNum, message
        ,function () {
            return resolve(null);
        }
        ,function (error:any) {
            // 取得失敗時
            console.log('送信失敗', error);
            return reject(error);
        });
    });

};


// チャットメッセージ既読
export const readyMessageInfo = (latestMessageId:string, earliestMessageId:string):any => {

    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    const orderNum = reliefInfo['orderNum'] || "";

    if(orderNum.length === 0){
        return false;
    }

    return new Promise((resolve, reject) => {
        updateChageMessageReadDate(orderNum, latestMessageId, earliestMessageId
        ,function () {
            return resolve(null);
        }
        ,function (error:any) {
            // 取得失敗時
            console.log('取得失敗', error);
            return reject(error);
        });
    });

};

// チャット画像ダウンロード
export const downloadChatImage = (chatImgMessageId: string):any => {

    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    const orderNum = reliefInfo['orderNum'] || "";

    if(orderNum.length === 0){
        return false;
    }

    return new Promise((resolve, reject) => {
        ChatImageApi.downloadChatImage(orderNum, chatImgMessageId
        ,function (chatImg:string) {
            return resolve(chatImg);
        }
        ,function (error:any) {
            // 取得失敗時
            console.log('取得失敗', error);
            return reject(error);
        });
    });

};
  
// チャット画像送信
export const sendImageInfo = (imageData: any,imageSize: any):any => {
    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    const orderNum = reliefInfo['orderNum'] || "";

    if(orderNum.length === 0){
        return false;
    }

    return new Promise((resolve, reject) => {
        ChatImageApi.uploadChatImage(imageData, imageSize
        ,function () {
            return resolve(null);
        }
        ,function (error:any) {
            // 取得失敗時
            console.log('取得失敗', error);
            return reject(error);
        })
    });
};


// チャット内 タイムスタンプ⇒年月日フォーマット変換
export const convTimeToDays = (timestamp:number):any => {

    const date = new Date(timestamp * 1000);
    const yyyy = `${date.getFullYear()}`;
    const MM = date.getMonth() + 1;
    const dd = date.getDate();
    const dayOfWeek = date.getDay();
    const dayOfWeekStr = [ "日", "月", "火", "水", "木", "金", "土" ][dayOfWeek];

    return `${yyyy}年${MM}月${dd}日（${dayOfWeekStr}）`;
};

// チャット内 タイムスタンプ⇒画面表示用フォーマット変換
export const convTimeToDateTime = (timestamp:number):any => {
    var response:any = {};

    const date = new Date(timestamp * 1000);
    const yyyy = `${date.getFullYear()}`;
    const MM = `0${date.getMonth() + 1}`.slice(-2);
    const dd = `0${date.getDate()}`.slice(-2);
    response['date'] = yyyy+""+MM+""+dd;

    const mm = `0${date.getMinutes()}`.slice(-2);
    const HH = `0${date.getHours()}`.slice(-2);
    response['time'] = HH+':'+mm

    return response;
};

