import MailInfo from '../common/MailInfo';
import MaintenanceInfo from '../common/MaintenanceInfo';
import MasterInfo from '../common/MasterInfo';
import LoginUserInfo from '../common/LoginUserInfo';
import { deleteAllImageFile } from '../common/ImageUtil';

export const Logout = ():Promise<boolean> => {
    return new Promise((resolve, reject) => {
        MailInfo.clean();
        sessionStorage.removeItem('reliefInfo');
        MasterInfo.clean();
        MaintenanceInfo.clean();
        LoginUserInfo.clean();

        //救援要請画像削除
        deleteAllImageFile().then(function(response: any){ 
                console.log('ログアウト成功');
                resolve(true);
        })
        .catch(function(response: any){
                //ログアウト処理を続行
                console.log('ログアウト失敗');
                reject(false);
        })
    });
};
