import { MutableRefObject } from "react";
import * as constants from '../constants';
import * as messages from '../messages';
// モーダル呼び出し関数
import { ModalDialogMaintenance, NoticeModalDialog, ModalDialog } from '../common/Modal';
import * as WebPush from '../webpush';
import { updateUserInfo } from "../common/api/userInfo"
import { registerFuzzyOrder } from "../common/api/fuzzyOrder"
import $ from "jquery";
import { MembershipCardFlg, MembershipSsoFlg, ManualInputFlg, TowHopeFlg, MemberFlg } from '../constants';
import * as PagingUtil from "./PagingUtil"
import PagingHistory from "./PagingHistory";
import { getMaintenanceMessage } from "./api/mainteInfo"
import * as OrderControl from "./api/orderControl"
import { MainteMessageInfo } from "../types/mainteInfo"
import { OrderControlInfo } from "../types/orderControlInfo"
import { deleteAllImageFile } from '../common/ImageUtil';
import * as VersionUtil from '../common/VersionUtil';
import * as MapBox from '../mapbox';
import MaintenanceInfo from '../common/MaintenanceInfo';
import MasterInfo from '../common/MasterInfo';
import * as MasterInfoUtil from '../common/MasterInfoUtil';
import * as ReliefUtil from '../common/ReliefUtil';
import LoginUserInfo from '../common/LoginUserInfo';
import PagingNavigator from '../common/PagingNavigator';
import { ErrorTransition } from '../common/ErrorUtil';

const email_pattern = "^[a-zA-Z0-9_+-]+(\\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\\.)+[a-zA-Z]{2,}$";
const telNo_pattern = "^[0-9]{10,11}$";
var permission_request = false

/**
 * ブラウザの閉じるボタン無効化
 * @param {BeforeUnloadEvent} e イベントオブジェクト
 */
const closeBlock = (e:BeforeUnloadEvent) => {
    if (!VersionUtil.isReloading()) {
        // イベントをキャンセルする
        e.preventDefault();
        // Chrome では returnValue を設定する必要がある
        e.returnValue = '';
    }
};

/**
 * ブラウザの戻る・閉じるボタン設定を行う。
 *
 * @param {boolean}                           prohibitBack  戻るボタンを無効化するかどうか。
 * @param {boolean}                           prohibitClose 閉じるボタンを無効化するかどうか。
 * @param {MutableRefObject<PagingNavigator>} pageNavigator 画面遷移オブジェクトの参照
 */
const initializeBrowserEvent = (pageName:string, prohibitBack:boolean, prohibitClose:boolean, pageNavigator:MutableRefObject<PagingNavigator>) => {
    // ブラウザの戻るボタンEvent処理
    const handleBrowserBack = (event:PopStateEvent) => {
        console.log(`browser back for ${pageName}`);
        // Event発生すると設定がpopされるので再push
        window.history.pushState(null, '', window.location.href);
        if (!prohibitBack) {
            // 戻るボタンが有効な場合には画面遷移履歴に従って前画面に戻す
            pageNavigator.current.back();
        }
    };

    // ブラウザの戻るボタン操作無効化
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handleBrowserBack);
    console.log(`start popstate listening for ${pageName}`);

    // ブラウザの閉じるボタン操作無効化
    if (prohibitClose) {
        window.addEventListener('beforeunload', closeBlock);
        console.log(`start beforeunload listening for ${pageName}`);
    }

    return function () {
        // Page終了時のクリーンアップ
        window.removeEventListener('popstate', handleBrowserBack);
        console.log(`stop popstate listening for ${pageName}`);

        if(prohibitClose){
            window.removeEventListener('beforeunload', closeBlock);
            console.log(`stop beforeunload listening for ${pageName}`);
        }
    };
}

/**
 * 初期表示時のブラウザ共通設定
 * @param {boolean} prohibitBack 戻るボタンの無効化
 * @param {boolean} prohibitClose 閉じるボタンの無効化
 * @param {MutableRefObject<PagingNavigator>} pageNavigator 画面遷移オブジェクトの参照
 */
export const initialize = (prohibitBack:boolean, prohibitClose:boolean, pageNavigator:MutableRefObject<PagingNavigator>, location:any):any => {
    setIndicator(false);

    const pageName = window.location.pathname;
    console.log(`initialize ${pageName}`);

    const response:any = {
        workable: false,
        cleanup: initializeBrowserEvent(pageName, prohibitBack, prohibitClose, pageNavigator),
    };

    if(PagingUtil.isHistoryEmpty()){
        //直接URL指定で遷移した場合
        if(!PagingUtil.isLandingPage(pageName)) {
            pageNavigator.current.resetAndNext(ErrorTransition(constants.ErrorReason.ACCESS_DENIED_ERROR));
            return response;
        }
    } else if (!location.state || !location.state.available) {
        //navigateで遷移していない場合

        //エラー表示後に再開を容易にするようにランディングページへの遷移を許容
        //エラー系ページはログアウト済みであるため、初期状態での再開が可能
        const pagingStack = new PagingHistory();
        if(PagingUtil.isLandingPage(pageName) && PagingUtil.isErrorPage(pagingStack.current!.pageName)){
            PagingHistory.clean();
        }
        else{
            //遷移前の画面に画面遷移
            const restoredState = pageNavigator.current.restore();
            if(restoredState){
                location.state = restoredState;
            }
            else{
                return response;
            }
        }
    }

    //event.js再読み込み
    const head = document.getElementsByTagName('head')[0] as HTMLElement;
    const scriptUrl = document.createElement('script');
    scriptUrl.type = 'text/javascript';
    scriptUrl.src = '/js/common/event.js';
    head.appendChild(scriptUrl);

    //救援要請情報判定
    if (PagingUtil.isTopPage(pageName)) {
        // アラートダイアログ表示⇒救援場所入力画面に戻る
        ModalDialog(messages.AlertMessages.RSEWS_ER_9998, () => {
            // ダイアログはいボタン押下時
            pageNavigator.current.backToTop();
        });
        response['workable'] = false;
    }
    else{
        //スタックが空の場合現在ページを追加
        PagingUtil.initializeIfNecessary(pageName);
        response['workable'] = true;
    }

    return response;
};

/**
 * インジケータの表示/非表示切り替え
 * @param {boolean} isDisplay インジケータを表示するか?
 */
export const setIndicator = (isDisplay:boolean):any => {
    if(isDisplay){
        $(".cmn_panel_loading").show();
    }
    else{
        $('.cmn_panel_loading').hide();
    }
};

/**
 * 通知パーミッションで使用するインジケータの表示/非表示切り替え
 * プッシュ通知関連処理のみで表示される為その他の箇所では使用しない
 * @param {boolean} isDisplay インジケータを表示するか?
 */
export const setPermissionIndicator = (isDisplay:boolean):any => {
    if(isDisplay){
        $(".permission_panel_loading").show();
    }
    else{
        $('.permission_panel_loading').hide();
    }
};

/**
 * 透明なパネルの表示/非表示切り替え
 * @param {boolean} isDisplay 透明なパネルを表示するか?
 */
export const setTransparentPanel = (isDisplay:boolean):any => {
    if(isDisplay){
        $(".cmn_panel_operation_preventer").show();
    }
    else{
        $(".cmn_panel_operation_preventer").hide();
    }
};


/**
 * チャット画面で使用する透明なパネルの表示/非表示切り替え
 * @param {boolean} isDisplay パネルを表示するか?
 */
export const setChatIndicator = (isDisplay:boolean):any => {
   if(isDisplay){
        $(".chat_panel_loading").show();
   }
   else{
        $(".chat_panel_loading").hide();
   }
};

/**
 * メールアドレスの形式チェック
 * @param {string} mailAddress メールアドレス
 */
export const checkMailAddress = (mailAddress:string):any => {
    return mailAddress.match(email_pattern);
};
/**
 * 電話番号の形式チェック
 * @param {string} telNo 電話番号
 */
export const checkTelNo = (telNo:string):any => {
    return telNo.match(telNo_pattern);
};


/**
 * 画像の形式チェック
 * @param {File} file 画像ファイル
 */
export const checkImageFile = (file:File):any => {
    let errorMessage = "";
    
    const fileName:string = file.name;
    const fileType:any = fileName.split('.').pop();
    
    if (!(constants.ImageSetting.ALLOW_EXTS.includes(fileType.toLowerCase()) || constants.ImageSetting.ALLOW_MIMES.includes(file.type))) {
        errorMessage = messages.OrderContentMessages.RSEWS_V14_M004;
    } else if(file.size > constants.ImageSetting.MAX_IMAGE_SIZE) {
        errorMessage = messages.OrderContentMessages.RSEWS_V14_M005;
    }
    
    return errorMessage;
};


/**
 * 混雑通知フローティングバーを表示
 */
export const displayCngestionFbar = ():any => {
    document.getElementById("commonFbarWrapper")?.classList.remove('conceal');
    document.getElementById("cngestionFbar")?.classList.remove('conceal');
};

/**
 * 位置情報パーミッション拒否フローティングバーを表示
 */
export const displayFngestionFbar = ():any => {
    document.getElementById("commonFbarWrapper")?.classList.remove('conceal');
    document.getElementById("pointFbar")?.classList.remove('conceal');
};


/**
 * メンテナンスメッセージの取得
 */
export const getMainteMessage = ():any => {
    //インジケータ表示
    setIndicator(true);
    return new Promise((resolve, reject) => {
        getMaintenanceMessage(function (infoList:Array<MainteMessageInfo>) {
            //メンテナンスメッセージが存在する場合ダイアログ表示
            if(infoList.length > 0){
                /** メンテナンス情報保存 */
                const info = new MaintenanceInfo();
                info.mainteMessageInfoList = infoList;
                info.save();
                /** ダイアログに内容表示 */
                ModalDialogMaintenance(function() {
                    resolve(true);
                });
            } else {
                resolve(false);
            }
        }
        ,function(response:any){
            reject(response);
        })
    });
};

/**
 * 救援要請制御情報の取得
 * @param {boolean} checkImpossible 受付停止の判定を行うか
 * @param {boolean} checkCngestion 混雑通知の判定を行うか
 */
export const getOrderControl = (checkImpossible:boolean, checkCngestion:boolean, prefectureCode:string|null = null):any => {
    //インジケータ表示
    setIndicator(true);
    return new Promise((resolve, reject) => {
        OrderControl.getOrderControl(prefectureCode, function(response:OrderControlInfo) {
            checkCongestion(response.reliefControlCode, checkImpossible, checkCngestion, resolve);
        }
        ,function(response:any){
            reject(response);
        });
    });
};

/**
 * 救援要請制御情報の判定
 * @param {string} reliefControlCode 救援要請制御コード
 * @param {boolean} checkImpossible 受付停止の判定を行うか
 * @param {boolean} checkCngestion 混雑通知の判定を行うか
 */
const checkCongestion = (reliefControlCode:string,checkImpossible:boolean, checkCngestion:boolean, resolve:any) => {
    if(checkImpossible && reliefControlCode === constants.ReliefControlCode.IMPOSSIBLE) {         
        //受付停止の場合
        const comment = '現在ウェブサイトからの救援要請を停止しています。'
        NoticeModalDialog(comment, () => {
            // ダイアログはいボタン押下時
            resolve(true);
        });

        return;
    }

    if(checkCngestion && reliefControlCode === constants.ReliefControlCode.CONGESTION) {
        //混雑通知の場合
        displayCngestionFbar();
    }
    resolve(false);
}

/**
 * 地図の現在位置から救援場所情報を取得する。
 *
 * @param  {any} reliefInfo 現在の救援情報。
 * @return {Promise} 救援場所情報の非同期応答。<br>
 *                   救援場所情報取得できない場合はnullで返却される。
 */
export const getReliefAddressInfo = async (reliefInfo:any) => {
    const geo = MapBox.getMarkerGeometry();
    const addressInfo = reliefInfo['reliefAddressInfo'];

    const response:any = {};
    if (addressInfo && 'geocodeReliefAddress' in addressInfo && addressInfo['longitude'] === geo['lng'] && addressInfo['latitude'] === geo['lat']) {
        response.longitude = addressInfo['longitude'];
        response.latitude = addressInfo['latitude'];
        response.geocodeReliefAddress = addressInfo['geocodeReliefAddress'];
        response.prefectureCode = addressInfo['prefectureCode'];
        response.areaCode = addressInfo['areaCode'];
        response.controlCenterCode = addressInfo['controlCenterCode'];
    } else {
        const data = await MapBox.getAddressInfo(geo['lng'], geo['lat']);

        const mstInfo:MasterInfo = new MasterInfo();
        const info = MasterInfoUtil.findPrefectureInfo(data?.prefecture, mstInfo.prefectureInfoList);
        if (info) {
            response.longitude = geo['lng'];
            response.latitude = geo['lat'];
            response.geocodeReliefAddress = data.placeName;
            response.prefectureCode = info.prefectureCode;
            response.areaCode = info.areaCode;
            response.controlCenterCode = info.controlCenterCode;
        } else {
            return null;
        }
    }
    return response;
};

/**
 * 地図の現在位置からお運び先情報を取得する。
 *
 * @param  {any} reliefInfo 現在の救援情報。
 * @return {Promise} お運び先情報の非同期応答。<br>
 *                   お運び先情報取得できない場合はnullで返却される。
 */
export const getEntryAddressInfo = async (reliefInfo:any) => {
    const geo = MapBox.getMarkerGeometry()
    const entryInfo = reliefInfo['entryInfo'];

    const response:any = {};
    if (entryInfo && 'geocodeEntryPlaceAddress' in entryInfo && entryInfo['entryPlaceLongitude'] === geo['lng'] && entryInfo['entryPlaceLatitude'] === geo['lat']) {
        response.entryPlaceLongitude = entryInfo['entryPlaceLongitude'];
        response.entryPlaceLatitude = entryInfo['entryPlaceLatitude'];
        response.geocodeEntryPlaceAddress = entryInfo['geocodeEntryPlaceAddress'];
        response.prefectureCode = entryInfo['prefectureCode'];
    } else {
        const data = await MapBox.getAddressInfo(geo['lng'], geo['lat']);

        const mstInfo:MasterInfo = new MasterInfo();
        const info = MasterInfoUtil.findPrefectureInfo(data?.prefecture, mstInfo.prefectureInfoList);
        if (info) {
            response.entryPlaceLongitude = geo['lng'];
            response.entryPlaceLatitude = geo['lat'];
            response.geocodeEntryPlaceAddress = data.placeName;
            response.prefectureCode = info.prefectureCode;
        } else {
            return null;
        }
    }
    return response;
};

    //選択内容を保存
    export const tireSaveSelection = () => {
        let reliefInfo: any = sessionStorage.getItem('reliefInfo');
        reliefInfo = JSON.parse(reliefInfo);
        let troubleTires = '';

        let numWheels = Number(reliefInfo['wheels']);
        var tireInfo: any = {};
        let arrTroubleTires = []
        for (var j = 1; j < numWheels + 1; j++) {
            var tirePosition = 'tire' + j
            var selected = $('#' + tirePosition).is(':checked')
            tireInfo[tirePosition] = selected
            if(selected) {
            //要請内容登録用のトラブルタイヤ位置「右前,左前・・・」もSessionStorageに保存
            arrTroubleTires.push($('#' + tirePosition).val());
            }
        }
        troubleTires = arrTroubleTires.join(',')
        //要請内容登録用のトラブルタイヤ位置「右前,左前・・・」も保存
        reliefInfo['orderInfo'] = {...reliefInfo['orderInfo'], ...{'troubleTires' : troubleTires}};
        // タイヤ選択ダイアログの選択状態をtrue/falseで記憶
        reliefInfo = {...reliefInfo, ...{'tireInfo' : tireInfo}};
        sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));
    }

    export const requestWebPushPermission = ():any => {
        //インジケータ表示
        setPermissionIndicator(true);
        return new Promise((resolve, reject) => {
            //プッシュ通知登録完了フラグ取得
            const loginUserInfo:LoginUserInfo = new LoginUserInfo();

            if(!('Notification' in window)){
                //プッシュ通知使用不可
                setPermissionIndicator(false);
                resolve(true);
                return;
            }

            let resolveFlg = false;
            if(Notification.permission === 'granted'){
                WebPush.sendPush().then((data:any) => {
                    if(data === null || (loginUserInfo.pushEndpoint && loginUserInfo.pushEndpoint === data.endpoint)){
                        //下記条件の場合プッシュ通知情報の更新を行わない
                        //1. プッシュ通知情報が取得できなかった場合
                        //2. sessionStorage内のエンドポイントURLとブラウザのエンドポイントURLが一致する場合
                        setPermissionIndicator(false);
                        resolve(true);
                    }
                    else{
                        //許可済かつ、未登録またはエンドポイントURLが更新されている場合
                        console.log("パーミッション許可済み");

                        //プッシュ通知情報取得要求
                        getPushResponse().then(function(response: any){
                            resolve(response);
                        })
                        .catch(function(response: any){
                            reject(response);
                        })
                        .finally(function () {
                            setPermissionIndicator(false);
                        });
                    };
                });
            }
            else if(Notification.permission === 'denied'){
                //拒否済の場合
                console.log("パーミッション拒否済み");

                //エンドポイントURLをリセット
                loginUserInfo.pushEndpoint = null;
                loginUserInfo.save();

                resolveFlg = true;
            }
            else{
                //未許可の場合
                console.log("パーミッション未許可");

                if(!permission_request){
                    permission_request = true;

                    //エンドポイントURLをリセット
                    loginUserInfo.pushEndpoint = null;
                    loginUserInfo.save();

                    //パーミッション許可待ちの間はユーザ操作できるようにインジケータを一時的に非表示にする、
                    setPermissionIndicator(false);
                    //プッシュ通知パーミッション表示
                    Notification.requestPermission().then(state => {
                        if (state === 'granted') {

                            setPermissionIndicator(true);

                            //プッシュ通知情報取得要求
                            getPushResponse().then(function(response: any){
                                resolve(response);
                            })
                            .catch(function(response: any){
                                resolve(response);
                            })
                            .finally(function () {
                                setPermissionIndicator(false);
                                permission_request = false;
                            });
                        }
                        else{
                            resolve(true);
                            permission_request = false;
                        }
                    })
                    .catch(function(){
                        resolve(true);
                        permission_request = false;
                    })
                }
                else{
                    resolveFlg = true;
                }
            }

            //処理終了時の共通処理
            if(resolveFlg){
                setPermissionIndicator(false);
                resolve(true);
            }
        });
    }

//プッシュ通知情報取得要求
export const getPushResponse = ():any => {
    return new Promise((resolve, reject) => {
        //プッシュ通知情報取得要求
        WebPush.sendPush().then((data:any) => {
            if(data === null){
                console.log("プッシュ通知情報取得要求失敗");
                reject(constants.ErrorReason.UNKWNOWN);
                return;
            }

            console.log("プッシュ通知情報取得要求成功", data);
            //リクエストパラメータ取得
            let endpoint = data.endpoint;
            let userPublicKey = data.userPublicKey;
            let userAuthToken = data.userAuthToken;

            console.log("endpoint　", endpoint);
            console.log("userPublicKey　", userPublicKey);
            console.log("userAuthToken　", userAuthToken);

            //利用者情報更新
            updateUserInfo({endPoint: endpoint, publicKey:userPublicKey, authTokenKey:userAuthToken}
            ,function () {
                //エンドポイントURL保存
                const loginUserInfo:LoginUserInfo = new LoginUserInfo();
                loginUserInfo.pushEndpoint = endpoint;
                loginUserInfo.save();

                resolve(true);
            }
            ,function (chk_error:any) {
                reject(chk_error);
            })
        });
    });
}

//電話離脱時依頼内容登録API呼び出し
export const postFuzzyRegist = (success:any, failure:any) => {

    var reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    let fuzzy_param:any = {};
    //sessionStorageから取得した救援情報と画面上の入力値をリクエストパラメータにセット
    let addressInfo = reliefInfo['reliefAddressInfo'];
    let userInfo = reliefInfo['userInfo'];
    let orderInfo = reliefInfo['orderInfo'];
    let entryInfo = reliefInfo['entryInfo'];

    if(addressInfo !== undefined && addressInfo !== null) {
        fuzzy_param = {...fuzzy_param, ...{
            controlCenterCode: addressInfo['controlCenterCode'],
            areaCode: addressInfo['areaCode'],
            prefectureCode: addressInfo['prefectureCode'],
            reliefLocationSupplementInfo: addressInfo['reliefLocationSupplementInfo'] === '' ? null : addressInfo['reliefLocationSupplementInfo'],
            latitude: addressInfo['latitude'].toString(),
            longitude: addressInfo['longitude'].toString(),
            geocodeReliefAddress: addressInfo['geocodeReliefAddress'] === undefined ? null : addressInfo['geocodeReliefAddress'],
            locationCode:addressInfo['locationCode'].toString(),
            roadCode: addressInfo['roadCode'] === undefined && $('#location').val() == null ? null : addressInfo['roadCode'].toString(),
        }};
    }
    if(userInfo !== undefined && userInfo !== null) {
        const mstInfo:MasterInfo = new MasterInfo();
        const carNumberPlaceName = MasterInfoUtil.findNumberPlaceName(userInfo?.licencePlatePlace,mstInfo?.carNumberPlaceInfoList);
        
        fuzzy_param = {...fuzzy_param, ...{
            userNameKanji: userInfo['userNameKanji'] === undefined ? null : userInfo['userNameKanji'],
            userNameKana: userInfo['userNameKana'] === undefined ? null : userInfo['userNameKana'],
            memberFlg: userInfo['memberFlg'] === undefined ? null : userInfo['memberFlg'],
            membershipNum: userInfo['membershipNum'] === undefined ? null : userInfo['membershipNum'],
            bkTelNum: userInfo['bkTelNum'] === undefined ? null : userInfo['bkTelNum'],
            carMakerCode: userInfo['carMakerCode'] === undefined ? (userInfo['twoWheelInfo'] ? userInfo['twoWheelInfo']['carMakerCode'] : null) : userInfo['carMakerCode'],
            vehicleCode: userInfo['vehicleCode'] === undefined ? (userInfo['twoWheelInfo'] ? userInfo['twoWheelInfo']['vehicleCode'] : null) : userInfo['vehicleCode'],// 車両区分
            carColorCode: userInfo['carColorCode'] === undefined ? null : userInfo['carColorCode'],
            carNameCode: userInfo['carNameCode'] === undefined ? (userInfo['twoWheelInfo'] ? userInfo['twoWheelInfo']['carNameCode'] : null) : userInfo['carNameCode'],
            licencePlatePlace: carNumberPlaceName === "" ? null : carNumberPlaceName,
            licencePlateClassification: userInfo['licencePlateClassification'] === undefined ? null : userInfo['licencePlateClassification'],
            licencePlateDistinction: userInfo['licencePlateDistinction'] === undefined ? null : userInfo['licencePlateDistinction'],
            licencePlateNumber: userInfo['licencePlateNumber'] === undefined ? null : userInfo['licencePlateNumber'],
            carWeight: getWeight(userInfo),// 二輪・四輪両方チェック
        }};
    }
    if(orderInfo !== undefined && orderInfo !== null) {
        fuzzy_param = {...fuzzy_param, ...{
            orderCode: orderInfo['orderCode'] === undefined ? null : orderInfo['orderCode'],
            orderContentCode: orderInfo['orderContentCode'] === undefined ? null : orderInfo['orderContentCode']
        }};
    }
    if(entryInfo !== undefined && entryInfo !== null) {
        fuzzy_param = {...fuzzy_param, ...{
            entryPlaceLatitude: entryInfo['entryPlaceLatitude'] === undefined ? null : entryInfo['entryPlaceLatitude'].toString(),
            entryPlaceLongitude: entryInfo['entryPlaceLongitude'] === undefined ? null : entryInfo['entryPlaceLongitude'].toString(),
            geocodeEntryPlaceAddress: entryInfo['geocodeEntryPlaceAddress'] === undefined ? null : entryInfo['geocodeEntryPlaceAddress'],
            entryPlaceName: entryInfo['entryPlaceName'] === undefined ? null : entryInfo['entryPlaceName'],// お運び先店舗名
        }};
    }

    fuzzy_param = {...fuzzy_param, ...{
        membershipCardFlg: MembershipCardFlg.NON_POSSESSION,
        membershipSsoFlg: MembershipSsoFlg.NO_LINKAGE,
        telNum: $('#telNum').val(),
        arrangementCode: null,//null固定
        cityCode: null,//null固定
        manualReliefAddress: null,//null固定
        manualInputFlg: ManualInputFlg.MAP_SELECT,
        reliefReservationDt: null,//null固定
        specialNotes: ReliefUtil.getSpecialNotes(reliefInfo),
        memo: null,
        driveCode: null,//null固定
        urgentArrangementCode: null,//null固定
        towHopeFlg: TowHopeFlg.UNWANTED,
        additionalInfo1: null,//null固定
        additionalInfo2: null,//null固定
        additionalInfo3: null,//null固定
        additionalInfo4: null,//null固定
        additionalInfo5: null,//null固定
        additionalInfo6: null,//null固定
        inflowCode: null,
    }};

    registerFuzzyOrder(
        fuzzy_param,
        success,
        failure);
}

export const getInflowCode = () => {
    const loginUserInfo:LoginUserInfo = new LoginUserInfo();
    return loginUserInfo.inflowCode;
}

//救援要請完了時に内部データを削除する
export const clearInternalData = ():Promise<boolean> => {
    return new Promise((resolve, reject) => {          

        //救援要請情報の削除
        sessionStorage.removeItem('reliefInfo');

        //救援要請画像の削除
        deleteAllImageFile().then(function(response: any){
            resolve(true);
        })
        .catch(function(response: any){
            reject(false);
        })
    });
}

//ボタンの多重押下を抑制する
export class Operation {
    //一定時間経過まで操作抑制用の透明のインジケータを表示することで多重押下を抑制する
    prevent(timeout=500) {
        if($(".cmn_panel_operation_preventer").css('display') === "none"){
            setTransparentPanel(true);
            setTimeout(() => {
                setTransparentPanel(false);
            }, timeout);
        }
    }
}

//種別に応じた車両重量を設定する
export const getWeight = (userInfo: any) => {
    return (userInfo.carKinds  === "二輪") ?  userInfo.bikeWeight: ((userInfo.carKinds  === "四輪") ?  userInfo.carWeight : "");
};

//現在のページ名を小文字で取得する
export const getCurrentPathName = ():string => { 

    let pageName = window.location.pathname?.toLowerCase();

    return pageName;
}

//画像を描画する
export async function draw(id: string, url: any, imageSizeWidth?: number, imageSizeHeight?: number) {
    return new Promise((resolve, reject) => {
        const canvas: any = document.getElementById(id);

        if (!canvas || !canvas.getContext) {
            resolve(false);
        }
        else{
            const context = canvas.getContext('2d', { willReadFrequently: true });
            const img: any = new Image();
            img.src = url; //相対URLの場合
            img.onload = () => {
                const canvasAspect = context.canvas.width / context.canvas.height, // canvasのアスペクト比
                imgAspect = img.width / img.height; // 画像のアスペクト比
                let left, top, width, height;

                if(imgAspect >= canvasAspect) {// canvasより画像が横長の場合  
                    left = 0;
                    width = context.canvas.width;
                    height = context.canvas.width / imgAspect;
                    top = (context.canvas.height - height) / 2;
                } else {// canvasより画像が縦長の場合
                    top = 0;
                    height = context.canvas.height;
                    width = context.canvas.height * imgAspect;
                    left = (context.canvas.width - width) / 2;
                }

                if(imageSizeWidth && imageSizeHeight){
                    context.clearRect(0, 0, imageSizeWidth, imageSizeHeight);
                }

                context.drawImage(img, 0, 0, img.width, img.height, left, top, width, height);
                resolve(true);
            }
            img.onerror = (function(error: any) {
                resolve(false);
            });
        }
    });
}

//JAFへの入会情報を取得する
export const getMemberInformation = (memberFlg :string) => {
    switch (memberFlg) {
        case MemberFlg.MEMBER: return 'JAF会員';
        case MemberFlg.NO_MEMBERS: return '入会していない';
        case MemberFlg.UNKWNOWN: return '不明';
        default: return '不明';
    }
}
