import { PrefectureInfo, CarMakerInfoList, CarNameInfoList, CarNumberPlaceInfoList, RoadLocationInfoList } from "../types/mstInfo";

export const findNumberPlaceName = (numberPlaceCode:any,licencePlatePlaceList:Array<CarNumberPlaceInfoList>) => {

    let placeName:string = '';
    if(numberPlaceCode !== undefined && numberPlaceCode !== "" && licencePlatePlaceList !== undefined && licencePlatePlaceList.length > 0){
        const tmp = licencePlatePlaceList?.find((place: CarNumberPlaceInfoList) => place.carNumberPlaceCode === numberPlaceCode)?.carNumberPlaceName;
        if(tmp){
            placeName = tmp;
        }
    }

    return placeName;
}

export const findNumberPlaceCode = (numberPlaceName:any,licencePlatePlaceList:Array<CarNumberPlaceInfoList>) => {

    let placeCode:string = '';
    if(numberPlaceName !== undefined && numberPlaceName !== "" && licencePlatePlaceList !== undefined && licencePlatePlaceList.length > 0){
        const tmp = licencePlatePlaceList?.find((place: CarNumberPlaceInfoList) => place.carNumberPlaceName === numberPlaceName)?.carNumberPlaceCode;
        if(tmp){
            placeCode = tmp;
        }
    }

    return placeCode;
}

export const findRoadLocationName = (roadCode:string,locationCode:string,roadLocationList:Array<RoadLocationInfoList>) => {

    let roadLocationName:string = '';
    if((roadCode !== undefined && roadCode !== "") && (locationCode !== undefined && locationCode !== "") && (roadLocationList !== undefined && roadLocationList.length > 0)){
        const tmp = roadLocationList?.find((roadLocationInfo: RoadLocationInfoList) => roadLocationInfo.roadCode === roadCode && roadLocationInfo.locationCode === locationCode)?.roadLocationName;
        if(tmp){
            roadLocationName = tmp;
        }
    }

    return roadLocationName;
}


export const findCarNameInfo = (carMaker: string, carName: string, carMakerInfoList: CarMakerInfoList[], carNameInfoList: CarNameInfoList[]) => {
        const carMakerCode = carMakerInfoList?.find((makerInfo: CarMakerInfoList) => makerInfo.carMakerName === carMaker)?.carMakerCode;
        return carNameInfoList?.find((carInfo: CarNameInfoList) => carInfo.carName === carName && carInfo.carMakerCode === carMakerCode)
};

/**
 * 都道府県名から都道府県情報を検出する。
 *
 * @param {string}                prefectureName     都道府県名。
 * @param {Array<PrefectureInfo>} prefectureInfoList 都道府県情報リスト。
 * @return {PrefectureInfo} 都道府県情報。<br>
 *                          見つからない場合はnullを返却する。
 */
export const findPrefectureInfo = (prefectureName:string, prefectureInfoList:Array<PrefectureInfo>):PrefectureInfo|null => {
    if (prefectureName) {
        for (let info of prefectureInfoList) {
            if (info.prefectureName === prefectureName) {
                return info;
            }
        }
    }
    return null;
};
