import { ReliefInfo } from "../types/reliefInfo";
import { MemberFlg } from '../constants';
import * as PagingUtil from '../common/PagingUtil';

/**
 * 救援情報を取得する。
 *
 * @return {ReliefInfo} 救援情報。
 */
export const getReliefInfo = ():ReliefInfo|null => {
    const reliefInfo:any = sessionStorage.getItem('reliefInfo');
    if (reliefInfo) {
        return JSON.parse(reliefInfo);
    }
    return null;
};

/**
 * 救援情報から都道府県コードを取得する。
 *
 * @param  {ReliefInfo} 救援情報。<br>
 *                      nullを指定した場合はSessionStorageから取得する。
 * @return {string} 都道府県コード。
 */
export const getPrefectureCode = (reliefInfo:ReliefInfo|null = null):string|null => {
    if (!reliefInfo) {
        reliefInfo = getReliefInfo();
    }

    if (reliefInfo) {
        if ('reliefAddressInfo' in reliefInfo) {
            // 救援場所情報から都道府県コードを取得
            return reliefInfo['reliefAddressInfo']['prefectureCode'];
        } else if ('prefectureCode' in reliefInfo) {
            // 受付状況確認後はこちら
            return reliefInfo['prefectureCode'];
        }
    }

    return null;
};

/**
 * 救援情報から特記事項を取得する。
 *
 * @param  {ReliefInfo} reliefInfo 救援情報。<br>
 *                                 nullを指定した場合はSessionStorageから取得する。
 * @return {string} 特記事項。
 */
export const getSpecialNotes = (reliefInfo:ReliefInfo|null = null):string|null => {
    if (!reliefInfo) {
        reliefInfo = getReliefInfo();
    }

    const notes:SpecialNotes = new SpecialNotes();
    notes.addPaymentOptions(reliefInfo?.userInfo?.memberFlg, reliefInfo?.userInfo?.paymentOptions);
    notes.add(reliefInfo?.userInfo?.otherCarName);
    notes.add(reliefInfo?.userInfo?.bikeName);
    notes.add(reliefInfo?.userInfo?.licencePlate);
    notes.add(reliefInfo?.orderInfo?.troubleDetail);
    notes.add(reliefInfo?.orderInfo?.troubleTires);

    return notes.get();
};

/**
 * 要請内容確認画面から遷移してきたかを判定して画面遷移を行う。
 *
 * @param  {string} pageName 遷移先の画面名
 * @param  {any}    navigate navigateオブジェクト
 */
export const transition = (pageName:string, navigate:any):void => {
    if(PagingUtil.isBeforePage('/orderConfirm'))  {
        PagingUtil.back(navigate);
    }
    else{
        PagingUtil.next(pageName, navigate);
    }
};

/**
 * 特記事項クラス。
 */
class SpecialNotes {
    static readonly SEPARATOR = "/";

    readonly _data:String[] = [];

    /**
     * お支払方法を特記事項保存内容として設定する。
     *
     * @param {string} memberFlg      会員種別。<br>
     *                                JAF会員の場合にはお支払方法は設定しない。
     * @param {string} paymentOptions お支払方法。
     */
    addPaymentOptions(memberFlg?:string, paymentOptions?:string) {
        if (memberFlg === MemberFlg.NO_MEMBERS || memberFlg === MemberFlg.UNKWNOWN) {
            this.add(paymentOptions);
        }
    }

    /**
     * 特記事項保存内容として設定する。
     *
     * 設定値が空の場合には設定しない。
     *
     * @param {string} value 設定値。
     */
    add(value?:string) {
        if (value) {
            this._data.push(value);
        }
    }

    /**
     * 特記事項を文字列で取得する。
     *
     * @return {string} 特記事項。
     */
    get() {
        const ret = this._data.join(SpecialNotes.SEPARATOR);
        if (ret) {
            return ret;
        }
        return null;
    };
};
