import SessionStorage from './SessionStorage';

const NAME:string = "loginUserInfo";

/**
 * ユーザのログイン情報を管理するクラス。
 */
export default class LoginUserInfo extends SessionStorage {
    /**
     * コンストラクタ。
     */
    constructor() {
        super(NAME);
    }

    /**
     * シークレットキーを設定する。
     *
     * @param {string} value シークレットキー。
     */
    set secretKey(value:string) {
        this.set("secretKey", value);
    }

    /**
     * シークレットキーを取得する。
     *
     * @return {string} シークレットキー。
     */
    get secretKey():string {
        return this.getString("secretKey", null);
    }

    /**
     * ソルトを設定する。
     *
     * @param {string} value ソルト。
     */
    set salt(value:string) {
        this.set("salt", value);
    }

    /**
     * ソルトを取得する。
     *
     * @return {string} ソルト。
     */
    get salt():string {
        return this.getString("salt", null);
    }

    /**
     * 署名情報を設定する。
     *
     * @param {string} value 署名情報。
     */
    set authorization(value:string) {
        this.set("authorization", value);
    }

    /**
     * 署名情報を取得する。
     *
     * @return {string} 署名情報。
     */
    get authorization():string {
        return this.getString("authorization", null);
    }

    /**
     * UNIXタイムスタンプを設定する。
     *
     * @param {string} value UNIXタイムスタンプ。
     */
    set rescueTime(value:string) {
        this.set("rescueTime", value);
    }

    /**
     * UNIXタイムスタンプを取得する。
     *
     * @return {string} UNIXタイムスタンプ。
     */
    get rescueTime():string {
        return this.getString("rescueTime", null);
    }

    /**
     * ユーザIDを設定する。
     *
     * @param {string} value ユーザID。
     */
    set userId(value:string) {
        this.set("userId", value);
    }

    /**
     * ユーザIDを取得する。
     *
     * @return {string} ユーザID。
     */
    get userId():string {
        return this.getString("userId", null);
    }

    /**
     * ユーザIDをSessionStorageから取得する。
     *
     * @return {string} ユーザID。
     */
    static getUserId():string {
        const info:LoginUserInfo = new LoginUserInfo();
        return info.userId;
    }

    /**
     * アクセストークンを設定する。
     *
     * @param {string} value アクセストークン。
     */
    set accessToken(value:string) {
        this.set("accessToken", value);
        // アクセストークンが変わった為難読化アクセストークンを削除
        this.remove("obfuscationKey");
        this.remove("obfuscationToken");
    }

    /**
     * アクセストークンを取得する。
     *
     * @return {string} アクセストークン。
     */
    get accessToken():string {
        return this.getString("accessToken", null);
    }

    /**
     * 難読化キーを設定する。
     *
     * @param {string} value 難読化キー。
     */
    set obfuscationKey(value:string) {
        this.set("obfuscationKey", value);
    }

    /**
     * 難読化キーを取得する。
     *
     * @return {string} 難読化キー。
     */
    get obfuscationKey():string {
        return this.getString("obfuscationKey", null);
    }

    /**
     * 難読化したアクセストークンを設定する。
     *
     * @param {string} value 難読化したアクセストークン。
     */
    set obfuscationToken(value:string) {
        this.set("obfuscationToken", value);
    }

    /**
     * 難読化したアクセストークンを取得する。
     *
     * @return {string} 難読化したアクセストークン。
     */
    get obfuscationToken():string {
        return this.getString("obfuscationToken", null);
    }

    /**
     * チャット用アクセストークンを設定する。
     *
     * @param {string} value チャット用アクセストークン。
     */
    set chatAccessToken(value:string) {
        this.set("chatAccessToken", value);
        // アクセストークンが変わった為難読化アクセストークンを削除
        this.remove("chatObfuscationKey");
        this.remove("chatObfuscationToken");
    }

    /**
     * チャット用アクセストークンを取得する。
     *
     * @return {string} チャット用アクセストークン。
     */
    get chatAccessToken():string {
        return this.getString("chatAccessToken", null);
    }

    /**
     * チャット用難読化キーを設定する。
     *
     * @param {string} value チャット用難読化キー。
     */
    set chatObfuscationKey(value:string) {
        this.set("chatObfuscationKey", value);
    }

    /**
     * チャット用難読化キーを取得する。
     *
     * @return {string} チャット用難読化キー。
     */
    get chatObfuscationKey():string {
        return this.getString("chatObfuscationKey", null);
    }

    /**
     * 難読化したチャット用アクセストークンを設定する。
     *
     * @param {string} value 難読化したチャット用アクセストークン。
     */
    set chatObfuscationToken(value:string) {
        this.set("chatObfuscationToken", value);
    }

    /**
     * 難読化したチャット用アクセストークンを取得する。
     *
     * @return {string} 難読化したチャット用アクセストークン。
     */
    get chatObfuscationToken():string {
        return this.getString("chatObfuscationToken", null);
    }

    /**
     * プッシュ通知のエンドポイントURLを設定する。
     *
     * @param {string|null} value エンドポイントURL。
     */
    set pushEndpoint(value:string|null) {
        this.set("pushEndpoint", value);
    }

    /**
     * プッシュ通知のエンドポイントURLを取得する。
     *
     * @return {string} エンドポイントURL。
     */
    get pushEndpoint():string {
        return this.getString("pushEndpoint", null);
    }

    /**
     * 流入元コードを設定する。
     *
     * @param {string} value 流入元コード。
     */
    set inflowCode(value:string) {
        this.set("inflowCode", value);
    }

    /**
     * 流入元コードを取得する。
     *
     * @return {string} 流入元コード。
     */
    get inflowCode():string {
        return this.getString("inflowCode", null);
    }

    /**
     * SessionStorageから項目を削除する。
     */
    static clean() {
        SessionStorage.clear(NAME);
    }
}
