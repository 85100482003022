// 暗号トークン生成
import crypto from 'crypto-js';
import * as AsyncDigest from '../async_digest.js';


//アクセストークンの暗号化
export const getToken = (PLAINTOKEN:string,CIPHER:string):any => {
    return AsyncDigest.async_digestMessage(CIPHER).then(
        function(hashed){

            const key = crypto.enc.Utf8.parse(hashed.substr(0,32));
            const iv =  crypto.lib.WordArray.random(128 / 8);

            //暗号化オプション（IV:初期化ベクトル, CBCモード, パディングモード：PKCS7)
            var options = {iv: iv, mode: crypto.mode.CBC, padding: crypto.pad.Pkcs7};

            //暗号化内容のエンコーディングは「UTF-8」
            var baseText = crypto.enc.Utf8.parse(PLAINTOKEN);

            //暗号化
            var encrypt  = crypto.AES.encrypt(baseText, key, options);
            var set_text = iv.toString() + encrypt.ciphertext.toString();
            var base64 = crypto.enc.Base64.stringify(crypto.enc.Hex.parse(set_text));
            var hmac_text = crypto.enc.Hex.stringify(crypto.HmacSHA256(base64, key));
            var ciphertext = hmac_text + base64;

            return ciphertext;
        }
    );
};


