import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import $ from "jquery";
import { NoticeModalDialog } from "../common/Modal";
import { vehicleNameSuggest, vehiclePlaceSuggest } from "../suggest";
import { UserInfoMessages } from '../messages';
import { ReliefInfo } from "../types/reliefInfo";
import { CarColorInfoList, CarMakerInfoList, CarNameInfoList, CarNumberPlaceInfoList } from "../types/mstInfo";
import *  as CommonUtil from '../common/CommonUtil';
import * as MasterInfoUtil from '../common/MasterInfoUtil';
import MasterInfo from "../common/MasterInfo"
import { Header } from "../header/Header";
import { Footer } from '../footer/Footer';
import { Cngestion } from "../header/Congestion";
import { ErrorTransition } from '../common/ErrorUtil';
import * as ReliefUtil from "../common/ReliefUtil";
import * as PagingUtil from '../common/PagingUtil';
import { WheelType, ErrorReason, MemberFlg } from '../constants';
import PageProps from '../types/pageProps';

const userNameKanjiRegex = /^[^\x21-\x7E\uFF61-\uFF9F]*$/;
const userNameKanaRegex = /^[ァ-ヶー　 ]*$/;
const membershipNumRegex = /^[0-9]{12}$/;
const licencePlateClassificationRegex = /^[0-9A-Z]{1,3}$/;
const licencePlateDistinctionRegex = /^[ぁ-んA-Z]{1}$/;
const licencePlateNumberRegex = /^[0-9]{1,5}$/;
const carWeightRegex = /^[0-9]{1,5}$/;

const UserInfo = (props:PageProps) => {

    const getReliefInfo = sessionStorage.getItem('reliefInfo');
    const reliefInfo: ReliefInfo = (getReliefInfo === undefined || getReliefInfo === null) ? "" : JSON.parse(getReliefInfo);
    const { userInfo } = reliefInfo;

    const mstInfo:MasterInfo = new MasterInfo();
    const { carMakerInfoList, carNameInfoList, carColorInfoList, carNumberPlaceInfoList } = mstInfo;
    const errorStyle = {
        color: "red",
    };

    const navigate = useNavigate();
    const location = useLocation();

    //フォーム用State
    const [userNameKanji, setUserNameKanji] = useState(userInfo?.userNameKanji !== undefined ? userInfo?.userNameKanji : "");
    const [userNameKana, setUserNameKana] = useState(userInfo?.userNameKana !== undefined ? userInfo?.userNameKana : "");
    const [telNum, setTelNum] = useState(userInfo?.telNum !== undefined ? userInfo?.telNum : "");
    const [bkTelNum, setBkTelNum] = useState(userInfo?.bkTelNum !== undefined ? userInfo?.bkTelNum : "");
    const [memberFlg, setMemberFlg] = useState(userInfo?.memberFlg !== undefined ? userInfo?.memberFlg : MemberFlg.MEMBER);
    const [membershipNum, setMembershipNum] = useState(userInfo?.membershipNum !== undefined ? userInfo?.membershipNum : "");
    const [paymentOptions, setPaymentOptions] = useState(userInfo?.paymentOptions !== undefined ? userInfo?.paymentOptions : "");
    const [carKinds, setCarKinds] = useState(userInfo?.carKinds !== undefined ? userInfo?.carKinds : "四輪");
    const [carMaker, setCarMaker] = useState(userInfo?.carMaker !== undefined ? userInfo?.carMaker : "");
    const [carName, setCarName] = useState(userInfo?.carName !== undefined ? userInfo?.carName : "");
    const [otherCarName, setOtherCarName] = useState(userInfo?.otherCarName !== undefined ? userInfo?.otherCarName : "");
    const [carColor, setCarColor] = useState(userInfo?.carColor !== undefined ? userInfo?.carColor : "");
    const [vehicle, setVehicle] = useState(userInfo?.twoWheelInfo?.carName !== undefined ? userInfo?.twoWheelInfo.carName : "");
    const [bikeName, setBikeName] = useState(userInfo?.bikeName !== undefined ? userInfo?.bikeName : "");
    const [licencePlatePlace, setLicencePlatePlace] = useState(MasterInfoUtil.findNumberPlaceName(userInfo?.licencePlatePlace,carNumberPlaceInfoList));
    const [licencePlateClassification, setLicencePlateClassification] = useState(userInfo?.licencePlateClassification !== undefined ? userInfo?.licencePlateClassification : "");
    const [licencePlateDistinction, setLicencePlateDistinction] = useState(userInfo?.licencePlateDistinction !== undefined ? userInfo?.licencePlateDistinction : "");
    const [licencePlateNumber, setLicencePlateNumber] = useState(userInfo?.licencePlateNumber !== undefined ? userInfo?.licencePlateNumber : "");
    const [licencePlate, setLicencePlate] = useState(userInfo?.licencePlate !== undefined ? userInfo?.licencePlate : "");
    const [carWeight, setCarWeight] = useState(userInfo?.carWeight !== undefined ? userInfo?.carWeight : "");
    const [bikeWeight, setBikeWeight] = useState(userInfo?.bikeWeight !== undefined ? userInfo?.bikeWeight : "");

    //バリデーション表示用State
    const [notUserNameKanji, setNotUserNameKanji] = useState(false);
    const [userNameKanjiValidation, setUserNameKanjiValidation] = useState(false);
    const [notUserNameKana, setNotUserNameKana] = useState(false);
    const [userNameKanaValidation, setUserNameKanaValidation] = useState(false);
    const [notTelNum, setNotTelNum] = useState(false);
    const [telNumValidation, setTelNumValidation] = useState(false);
    const [bkTelNumValidation, setBkTelNumValidation] = useState(false);
    const [notMemberFlg, setNotMemberFlg] = useState(false);
    const [membershipNumValidation, setMembershipNumValidation] = useState(false);
    const [notPaymentOptions, setNotPaymentOptions] = useState(false);
    const [notCarMaker, setNotCarMaker] = useState(false);
    const [notCarName, setNotCarName] = useState(false);
    const [otherVehiclesValidation, setOtherCarNameValidation] = useState(false);
    const [invalidCarName, setInvalidCarName] = useState(false);
    const [invalidLicencePlatePlace, setInvalidLicencePlatePlace] = useState(false);
    const [licencePlateClassificationValidation, setLicencePlateClassificationValidation] = useState(false);
    const [licencePlateDistinctionValidation, setLicencePlateDistinctionValidation] = useState(false);
    const [licencePlateNumberValidation, setLicencePlateNumberValidation] = useState(false);
    const [licencePlateValidation, setLicencePlateValidation] = useState(false);
    const [carWeightValidation, setCarWeightValidation] = useState(false);
    const [bikeWeightValidation, setBikeWeightValidation] = useState(false);
    const [notVehicle, setNotVehicle] = useState(false);
    const [bikeNameValidation, setBikeNameValidation] = useState(false);

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    //四輪車両メーカープルダウンリスト
    const fourWheelsList = carMakerInfoList?.map((fourWheel: CarMakerInfoList) => <option key={fourWheel.carMakerCode}>{fourWheel.carMakerName}</option>);

    const fourWheelInfo = MasterInfoUtil.findCarNameInfo(carMaker, carName, carMakerInfoList, carNameInfoList);
    const fourWheelInfoJqueryValue = MasterInfoUtil.findCarNameInfo(carMaker, $('#txt_vehicle_name').val() as string, carMakerInfoList, carNameInfoList);

    //二輪車両区分プルダウンリスト
    const twoWheelsList = carNameInfoList?.filter((carInfo: CarNameInfoList) => carInfo.twoWheelsFlg === "1").map((twoWheel: CarNameInfoList) => <option key={twoWheel.carNameCode}>{twoWheel.carName}</option>);
    
    //プルダウンで選択した、二輪車情報取得
    const twoWheelInfo = carNameInfoList?.find((carInfo: CarNameInfoList) => carInfo.carName === vehicle);
    //カラープルダウンリスト
    const colors = carColorInfoList?.map((color: CarColorInfoList) => <option key={color.carColorCode}>{color.carColorName}</option>);
    
    const carColorCode = carColorInfoList?.find((color: CarColorInfoList) => color.carColorName === carColor)?.carColorCode;

    const carNumberPlaceCode = MasterInfoUtil.findNumberPlaceCode(licencePlatePlace,carNumberPlaceInfoList);

    //多重押下対策
    const processing = useRef(false);

    //スクロール対象定義
    const scrollTop = useRef<HTMLDivElement>(null);
    const scrollUserInfo = useRef<HTMLParagraphElement>(null);
    const scrollCarInfo = useRef<HTMLParagraphElement>(null);

    useLayoutEffect(() => {
        if (location.state !== null && location.state.scroll === "userInfo") {
            scrollUserInfo?.current?.scrollIntoView(true);
        } else if (location.state !== null && location.state.scroll === "carInfo") {
            scrollCarInfo?.current?.scrollIntoView(true);
        }        
    }, [location]);

    useEffect(() => {
        const initResult = CommonUtil.initialize(true, true, props.pageNavigator, location);
        if (!initResult['workable']) {            
            return initResult['cleanup'];
        }

        const relief = sessionStorage.getItem('reliefInfo');
        const reliefInfo = relief !== null ? JSON.parse(relief) : "";
        
        CommonUtil.getOrderControl(true,true,reliefInfo?.reliefAddressInfo.prefectureCode).then(function(response: any){ 
            CommonUtil.setIndicator(false);
            //救援要請制御ダイアログかどうか
            if(response){
                PagingUtil.resetAndNext(ErrorTransition(ErrorReason.ORDER_CONTROL_ERROR), navigate);
            }
        })
        .catch(function(response: any){
            PagingUtil.resetAndNext(ErrorTransition(response), navigate);
        })   
        setDisplayFooter(true);
        return initResult['cleanup'];    
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);


    //JAFへの入会項目の選択時、選択していない入力項目を初期化
    const changeMemberFlg = (e: React.SetStateAction<string>) => {
        setMemberFlg(e)
        if(e === MemberFlg.MEMBER){
            setPaymentOptions("");
            setNotPaymentOptions(false);
        }
        else if(e === MemberFlg.NO_MEMBERS || e === MemberFlg.UNKWNOWN){
            setMembershipNum("");        
            setMembershipNumValidation(false);
        }
    };
    
    //種別選択時、選択していない種別の入力項目を初期化
    const changeCarKinds = (e: React.SetStateAction<string>) => {
        setCarKinds(e);
        if(e === "四輪"){
            setVehicle("");
            setBikeName("");
            setLicencePlate("");
            setBikeWeight("");

            setNotVehicle(false);
            setBikeNameValidation(false);
            setLicencePlateValidation(false);
            setBikeWeightValidation(false);
        }
        else if(e === "二輪"){
            changeCarMaker("");
            setCarColor("");
            setLicencePlatePlace("");
            setLicencePlateClassification("");
            setLicencePlateDistinction("");
            setLicencePlateNumber("");
            setCarWeight("");

            setNotCarMaker(false);
            setNotCarName(false);
            setOtherCarNameValidation(false);
            setInvalidCarName(false);
            setInvalidLicencePlatePlace(false);
            setLicencePlateClassificationValidation(false);
            setLicencePlateDistinctionValidation(false);
            setLicencePlateNumberValidation(false);
            setCarWeightValidation(false);
        }
    };

    //車両メーカー選択時、車両名称初期化
    const changeCarMaker = (e: React.SetStateAction<string>) => {
        setCarName("");
        setOtherCarName("");
        setOtherCarNameValidation(false);
        setCarMaker(e);
    };  

    //その他の車両表示
    const showOtherVehicle = (e: React.SetStateAction<string>) => {
        setCarName(e);
        setOtherCarNameValidation(false);
    }

    //サジェスト用関数
    $("#txt_vehicle_name").off();
    $("#txt_vehicle_name").on("click focus", () => {
        vehicleNameSuggest(carMaker, $("#txt_vehicle_name").val());
    });
    $("#txt_vehicle_name").on("input", () => {
        vehicleNameSuggest(carMaker, $("#txt_vehicle_name").val());
    });

    $("#txt_vehicle_place").off();
    $("#txt_vehicle_place").on("click focus", () => {
        vehiclePlaceSuggest($("#txt_vehicle_place").val());
    });
    $("#txt_vehicle_place").on("input", () => {
        vehiclePlaceSuggest($("#txt_vehicle_place").val());
    });

    //車両名称完全一致チェック
    const isCarNameValid = (carMaker: string, carName: string) => {
        const carMakerInfo = carMakerInfoList?.find((carMakerInfo: CarMakerInfoList) => { return carMakerInfo.carMakerName === carMaker });
        const carInfoList = carNameInfoList?.filter((carInfo: CarNameInfoList) => carInfo.carMakerCode === carMakerInfo?.carMakerCode);
        return carInfoList?.some((carInfo: CarNameInfoList) => carInfo.carName === carName);
    }
    
    //ナンバープレート(本拠地)完全一致チェック
    const isLicencePlatePlaceValid = (licencePlatePlace: string) => {
        return carNumberPlaceInfoList?.some((place: CarNumberPlaceInfoList) => place.carNumberPlaceName === licencePlatePlace);
    }

    //バリデーションチェック
    const validate = () => {
        //初期化
        setNotUserNameKanji(false);
        setUserNameKanjiValidation(false);
        setNotUserNameKana(false);
        setUserNameKanaValidation(false);
        setNotTelNum(false);
        setTelNumValidation(false);
        setBkTelNumValidation(false);
        setNotMemberFlg(false);
        setMembershipNumValidation(false);
        setNotPaymentOptions(false);
        setNotCarMaker(false);
        setNotCarName(false);
        setOtherCarNameValidation(false);
        setInvalidCarName(false);
        setInvalidLicencePlatePlace(false);
        setLicencePlateClassificationValidation(false);
        setLicencePlateDistinctionValidation(false);
        setLicencePlateNumberValidation(false);
        setLicencePlateValidation(false);
        setCarWeightValidation(false);
        setBikeWeightValidation(false);
        setNotVehicle(false);
        setBikeNameValidation(false);

        const errorFlgList: boolean[] = [];
        let result:any = {};

        // 氏名の入力チェック
        const trimUserNameKanji = userNameKanji.trim();
        setUserNameKanji(trimUserNameKanji);
        if (trimUserNameKanji === "") {
            setNotUserNameKanji(true);
            errorFlgList.push(true);
        } else if (trimUserNameKanji.length > 25) {
            setUserNameKanjiValidation(true);
            errorFlgList.push(true);
        } else if (!userNameKanjiRegex.test(trimUserNameKanji)) {
            setUserNameKanjiValidation(true);
            errorFlgList.push(true);
        }
        else{
            result['trimUserNameKanji'] = trimUserNameKanji;
        }

        // 氏名(カナ)の入力チェック
        const trimUserNameKana =userNameKana.trim();
        setUserNameKana(trimUserNameKana);
        if (trimUserNameKana === "") {
            setNotUserNameKana(true);
            errorFlgList.push(true);
        } else if (trimUserNameKana.length > 25) {
            setUserNameKanaValidation(true);
            errorFlgList.push(true);
        } else if (!userNameKanaRegex.test(trimUserNameKana)) {
            setUserNameKanaValidation(true);
            errorFlgList.push(true);
        }
        else{
            result['trimUserNameKana'] = trimUserNameKana;
        }

        // 電話番号の入力チェック
        if (telNum === "") {
            setNotTelNum(true);
            errorFlgList.push(true);
        } else if (!CommonUtil.checkTelNo(telNum)) {
            setTelNumValidation(true);
            errorFlgList.push(true);
        }

        // 予備の電話番号の入力チェック
        if (bkTelNum !== "" && !CommonUtil.checkTelNo(bkTelNum)) {
            setBkTelNumValidation(true);
            errorFlgList.push(true);
        }

        // JAFへの入会の未選択チェック
        if (memberFlg === "") {
            setNotMemberFlg(true);
            errorFlgList.push(true);
        }

        // 会員番号の入力チェック
        if (membershipNum !== "" && !membershipNumRegex.test(membershipNum)) {
            setMembershipNumValidation(true);
            errorFlgList.push(true);
        }

        // 支払方法の入力チェック
        if ((memberFlg === MemberFlg.NO_MEMBERS || memberFlg === MemberFlg.UNKWNOWN) && paymentOptions === "") {
            setNotPaymentOptions(true);
            errorFlgList.push(true);
        }

        if (carKinds === "四輪") {

            // 車両メーカーの入力チェック
            if (carMaker === "") {
                setNotCarMaker(true);
                errorFlgList.push(true);
            }
            
            // 車両名称の入力チェック
            if (carName === "") {
                setNotCarName(true);
                errorFlgList.push(true);
            }

            // その他の車両名称の入力チェック
            const trimOtherCarName = otherCarName.trim();
            setOtherCarName(trimOtherCarName);
            if (trimOtherCarName !== "" && trimOtherCarName.length > 100) {
                setOtherCarNameValidation(true);
                errorFlgList.push(true);
            }
            else{
                result['trimOtherCarName'] = trimOtherCarName;
            }

            // 車両名称の完全一致チェック
            if (carName !== "" && !isCarNameValid(carMaker, carName)) {
                setInvalidCarName(true);
                errorFlgList.push(true);
            }

            // ナンバープレート(本拠地)の完全一致チェック
            if (licencePlatePlace !== "" && !isLicencePlatePlaceValid(licencePlatePlace)) {
                setInvalidLicencePlatePlace(true);
                errorFlgList.push(true);
            }

            // ナンバープレート(分類番号)の入力チェック
            if (licencePlateClassification !== "" && !licencePlateClassificationRegex.test(licencePlateClassification)) {
                setLicencePlateClassificationValidation(true);
                errorFlgList.push(true);
            }

            // ナンバープレート(事業用判別文字)の入力チェック
            if (licencePlateDistinction !== "" && !licencePlateDistinctionRegex.test(licencePlateDistinction)) {
                setLicencePlateDistinctionValidation(true);
                errorFlgList.push(true);
            }

            // ナンバープレート(指定番号)の入力チェック
            if (licencePlateNumber !== "" && !licencePlateNumberRegex.test(licencePlateNumber)) {
                setLicencePlateNumberValidation(true);
                errorFlgList.push(true);
            }
            
            // 四輪の車両重量の入力チェック
            if (carWeight !== "" && !carWeightRegex.test(carWeight)) {
                setCarWeightValidation(true);
                errorFlgList.push(true);
            }
        }


        if (carKinds === "二輪") {

            // 車両区分の入力チェック
            if (vehicle === "") {
                setNotVehicle(true);
                errorFlgList.push(true);
            }

            // バイク名称の入力チェック
            const trimBikeName = bikeName.trim();
            setBikeName(trimBikeName);
            if (trimBikeName.length > 100) {
                setBikeNameValidation(true);
                errorFlgList.push(true);
            }
            else{
                result['trimBikeName'] = trimBikeName;
            }

            // ナンバープレート(自由入力)の入力チェック
            const trimLicencePlate = licencePlate.trim();
            setLicencePlate(trimLicencePlate);
            if (trimLicencePlate !== "" && trimLicencePlate.length > 20) {
                setLicencePlateValidation(true);
                errorFlgList.push(true);
            }
            else{
                result['trimLicencePlate'] = trimLicencePlate;
            }

            //二輪の車両重量の入力チェック
            if (bikeWeight !== "" && !carWeightRegex.test(bikeWeight)) {
                setBikeWeightValidation(true);
                errorFlgList.push(true);
            }
        }

        //入力チェックでエラーが発生した場合は空にする
        if(errorFlgList.length !== 0){
            result =  [];
        }

        return result;
    };

    //車両重量超過チェック
    const isCarOverWeightValid = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        //多重押下対策
        e.preventDefault();
        if (processing.current) return;
        processing.current = true;

        //バリデーションチェック
        const result:any = validate();
        if (Object.keys(result).length !== 0) {
            const carInfo = carNameInfoList?.find((car: CarNameInfoList) => car.carMakerCode === fourWheelInfo?.carMakerCode && car.carNameCode === fourWheelInfo?.carNameCode);
            const carOverWeightFlg = (carInfo !== undefined && carInfo.carOverWeightFlg !== undefined) && carInfo.carOverWeightFlg === "1";

            //車両重量超過チェック,バリデーションチェック
            if (carOverWeightFlg && carKinds !== "二輪") {
                //車両重量確認ダイアログ表示：車両名称コンボボックスで入力した車両通称名の車両重量超過フラグが1の場合
                const comment =
                    "<p class='strong'>車両重量3,000㎏、最大積載量2,000㎏を超えている場合があります。<br>"
                    + "車検証を確認してください。<br>"
                    + "重量が超えている場合は救援要請をお断りいたします。"
                    + "ただし、キー閉じ込み・燃料切れのトラブルは救援要請いただけます。</p>";
                NoticeModalDialog(comment, () => {
                    // 車両重量確認ダイアログ内処理
                    nextPage(result);
                });
            } else {
                nextPage(result);
            }
        } else {
            scrollTop?.current?.scrollIntoView({behavior: "smooth"});
            processing.current = false;
        }
    }

    const nextPage = (result:any) => {
        try {
            const setUserInfo = {
                userNameKanji:result['trimUserNameKanji'],
                userNameKana:result['trimUserNameKana'],
                telNum,
                bkTelNum,
                memberFlg,
                membershipNum,
                paymentOptions,
                carKinds,
                ...(carKinds === "四輪" && {
                    carMakerCode: fourWheelInfo?.carMakerCode,
                    vehicleCode: fourWheelInfo?.vehicleCode,
                    carColorCode: carColorCode ? carColorCode : "",
                    carNameCode: fourWheelInfo?.carNameCode,
                    carMaker,
                    carName,
                    otherCarName:result['trimOtherCarName'],
                    otherCarFlg: fourWheelInfo?.otherCarNameFlg,
                    carColor,
                    licencePlatePlace: carNumberPlaceCode ? carNumberPlaceCode : "",
                    licencePlateClassification,
                    licencePlateDistinction,
                    licencePlateNumber,
                    carWeight,
                }),
                ...(carKinds === "二輪" && {
                    twoWheelInfo,
                    bikeName:result['trimBikeName'],
                    licencePlate:result['trimLicencePlate'],
                    bikeWeight,
                })
            }

            const setReliefInfo = {...reliefInfo, ...{userInfo: setUserInfo},
                ...(carKinds === "四輪" && {
                    wheels: WheelType.FOUR_WHEELS,
                }),
                ...(carKinds === "二輪" && {
                    wheels: WheelType.TWO_WHEELS,
                })
            }

            sessionStorage.setItem("reliefInfo", JSON.stringify(setReliefInfo));
            ReliefUtil.transition("/orderContent", navigate);

        } catch (error) {
            processing.current = false;
        }
        processing.current = false;
    };

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>車両・お客様情報入力 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header back pageNavigator={props.pageNavigator}/>
            <div ref={scrollTop} className="contentsWrapper">
                <div className="contents">
                    <ul className="status">
                        <li>救援場所入力</li>
                        <li className="current">お客様情報入力</li>
                        <li>トラブル内容入力</li>
                        <li>内容確認</li>
                        <li>登録完了</li>
                    </ul>
                </div>
            </div>

            <div className="contentsWrapper top0">
                <div className="contents">
                    <p ref={scrollUserInfo} className="title large">お客様情報</p>
                    <div className="flexWrapper vertical">
                        <p className="title must">氏名 </p>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="fullName"
                                className="v13"
                                placeholder="連盟 太郎"
                                value={userNameKanji}
                                onChange={(e) => setUserNameKanji(e.target.value)}
                            />
                        </div>
                        <p className="cmt">※全角入力　氏名の間にスペースをお願いします。</p>
                        {notUserNameKanji && (
                            <p className="cmt" style={errorStyle}>
                                {UserInfoMessages.RSEWS_V13_M002}
                            </p>
                        )}
                        {userNameKanjiValidation && (
                            <p className="cmt" style={errorStyle}>
                            {UserInfoMessages.RSEWS_V13_M003}
                            </p>
                        )}
                        <p className="title must">氏名（カナ）</p>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                name="fullNameKana"
                                className="v13"
                                placeholder="レンメイ タロウ"
                                value={userNameKana}
                                onChange={(e) => setUserNameKana(e.target.value)}
                            />
                        </div>
                        <p className="cmt">※全角入力　氏名の間にスペースをお願いします。</p>
                        {notUserNameKana && (
                            <p className="cmt" style={errorStyle}>
                            {UserInfoMessages.RSEWS_V13_M004}
                            </p>
                        )}
                        {userNameKanaValidation && (
                            <p className="cmt" style={errorStyle}>
                            {UserInfoMessages.RSEWS_V13_M005}
                            </p>
                        )}
                        <p className="title must">電話番号 </p>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                inputMode="tel"
                                name="電話番号"
                                value={telNum}
                                onChange={(e) => setTelNum(e.target.value)}
                                placeholder="090********"
                                className="half"
                            />
                        </div>
                        <p className="cmt">
                            ※JAFから折り返し連絡を受け取れる電話番号を入力してください。
                        </p>
                        {notTelNum && (
                            <p className="cmt" style={errorStyle}>
                            {UserInfoMessages.RSEWS_V13_M006}
                            </p>
                        )}
                        {telNumValidation && (
                            <p className="cmt" style={errorStyle}>
                            {UserInfoMessages.RSEWS_V13_M007}
                            </p>
                        )}
                        <p className="title nomust">予備の電話番号 </p>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                inputMode="tel"
                                name="予備の電話番号"
                                value={bkTelNum}
                                onChange={(e) => setBkTelNum(e.target.value)}
                                placeholder="090********"
                                className="half"
                            />
                        </div>
                        {bkTelNumValidation && (
                            <p className="cmt" style={errorStyle}>
                            {UserInfoMessages.RSEWS_V13_M008}
                            </p>
                        )}
                    </div>
                    <p className="title must">JAFへの入会</p>
                    <div className="jafWrapper">
                        <div className="radioWrapper">
                            <input
                                className="cover"
                                type="radio"
                                name="call"
                                value="1"
                                id="call5"
                                checked={memberFlg === MemberFlg.MEMBER}
                                onChange={(e) => changeMemberFlg(e.target.value)}
                            />
                            <label tabIndex={0} htmlFor="call5" className="cover three">
                                JAF会員
                            </label>
                            <input
                                className="cover"
                                type="radio"
                                name="call"
                                value="2"
                                id="call6"
                                checked={memberFlg === MemberFlg.NO_MEMBERS}
                                onChange={(e) => changeMemberFlg(e.target.value)}
                            />
                            <label tabIndex={0} htmlFor="call6" className="cover three">
                                入会していない
                            </label>
                            <input
                                className="cover"
                                type="radio"
                                name="call"
                                value="3"
                                id="call7"
                                checked={memberFlg === MemberFlg.UNKWNOWN}
                                onChange={(e) => changeMemberFlg(e.target.value)}
                            />
                            <label tabIndex={0} htmlFor="call7" className="cover three">
                                不明
                            </label>
                        </div>
                        {notMemberFlg && (
                            <p className="cmt" style={errorStyle}>
                            {UserInfoMessages.RSEWS_V13_M009}
                            </p>
                        )}
                        {/* JAF会員選択時 */}
                        {memberFlg === MemberFlg.MEMBER && (
                            <div className="membernum">
                                <p className="title nomust">会員番号</p>
                                <input
                                    type="text"
                                    inputMode="numeric"
                                    name="membershipNum"
                                    placeholder="123456789123"
                                    className="half"
                                    value={membershipNum}
                                    onChange={(e) => setMembershipNum(e.target.value)}
                                />
                                {membershipNumValidation && (
                                    <p className="cmt" style={errorStyle}>
                                    {UserInfoMessages.RSEWS_V13_M010}
                                    </p>
                                )}
                            </div>
                        )}
                        {/* 入会していない、不明選択時 */}
                        {(memberFlg === MemberFlg.NO_MEMBERS || memberFlg === MemberFlg.UNKWNOWN) && (
                            <div>
                                <p className="title must">お支払方法</p>
                                <ul className="payment">
                                    <li>
                                        <input
                                            type="radio"
                                            name="お支払方法"
                                            value="クレジット"
                                            id="pay2"
                                            checked={paymentOptions === "クレジット"}
                                            onChange={(e) => setPaymentOptions(e.target.value)}
                                        />
                                        <label htmlFor="pay2" tabIndex={0}>クレジット</label>
                                    </li>
                                    <li>
                                        <input
                                            type="radio"
                                            name="お支払方法"
                                            value="現金"
                                            id="pay1"
                                            checked={paymentOptions === "現金"}
                                            onChange={(e) => setPaymentOptions(e.target.value)}
                                        />
                                        <label htmlFor="pay1" tabIndex={0}>現金</label>
                                    </li>
                                </ul>
                                {notPaymentOptions && (
                                    <p className="cmt" style={errorStyle}>
                                    {UserInfoMessages.RSEWS_V13_M011}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                    <p ref={scrollCarInfo} className="title large">車両情報 </p>
                    <p className="title must">種別</p>
                    <div className="tire">
                        <input
                            className="cover tire"
                            type="radio"
                            name="tire"
                            value="四輪"
                            id="tire4"
                            checked={carKinds === "四輪"}
                            onChange={(e) => changeCarKinds(e.target.value)}
                        />
                        <label htmlFor="tire4" tabIndex={0} className="cover t4">
                            四輪
                        </label>
                        <input
                            className="cover tire"
                            type="radio"
                            name="tire"
                            value="二輪"
                            id="tire2"
                            checked={carKinds === "二輪"}
                            onChange={(e) => changeCarKinds(e.target.value)}
                        />
                        <label htmlFor="tire2" tabIndex={0} className="cover t2">
                            二輪
                        </label>
                    </div>
                    <p className="cmt">※三輪車は四輪を選択</p>
                    {carKinds === "四輪" && (
                        <div className="tire4_switch">
                            <div className="flexWrapper vertical">
                                <div>
                                    <p className="title must">車両メーカー </p>
                                    <select
                                        name="car_maker"
                                        id="txt_car_maker"
                                        className="v13"
                                        value={carMaker}
                                        onChange={(e) => changeCarMaker(e.target.value)}>
                                        <option value="" disabled>不明の場合、「その他」を選択</option>
                                        {fourWheelsList}
                                    </select>
                                    {notCarMaker && (
                                        <p className="cmt" style={errorStyle}>
                                        {UserInfoMessages.RSEWS_V13_M012}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div className="other">
                                        <p className="title must">車両名称 </p>
                                    </div>
                                    <input
                                        disabled={carMaker === ""}
                                        autoComplete="off"
                                        type="text"
                                        name="txt_vehicle_name"
                                        id="txt_vehicle_name"
                                        value={carName}
                                        onChange={(e) => setCarName(e.target.value)}
                                        onBlur={(e) => showOtherVehicle(e.target.value)}
                                        data-options=""
                                        className="v13"
                                        placeholder="不明の場合、「その他」を選択"
                                    />
                                    {notCarName && (
                                        <p className="cmt" style={errorStyle}>
                                        {UserInfoMessages.RSEWS_V13_M013}
                                        </p>
                                    )}
                                    {invalidCarName && (
                                        <p className="cmt" style={errorStyle}>
                                        {UserInfoMessages.RSEWS_V13_M023}
                                        </p>
                                    )}
                                </div>
                                { (fourWheelInfo?.otherCarNameFlg === "1" || fourWheelInfoJqueryValue?.otherCarNameFlg === "1") 
                                &&
                                <div>
                                    <p className="title nomust">その他の車両名称 </p>
                                    <input type="text" name="車種その他" placeholder="車種を入力してください。" value={otherCarName} onChange={(e) => setOtherCarName(e.target.value)} className="v13" />
                                </div>
                                }
                                { otherVehiclesValidation && 
                                        <p className="cmt" style={errorStyle}>
                                        {UserInfoMessages.RSEWS_V13_M014}
                                    </p>
                                }
                                <div>
                                    <p className="title nomust">カラー</p>
                                    <select
                                        name="car_maker"
                                        id=""
                                        className="v13"
                                        value={carColor}
                                        onChange={(e) => setCarColor(e.target.value)}>
                                        <option value="">不明の場合、選択不要</option>
                                        {colors}
                                    </select>
                                </div>
                            </div>
                            <p className="title nomust">ナンバープレート</p>
                            <div className="flexWrapper">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="txt_vehicle_place"
                                    id="txt_vehicle_place"
                                    value={licencePlatePlace}
                                    onChange={(e) => setLicencePlatePlace(e.target.value)}
                                    onBlur={(e) => setLicencePlatePlace(e.target.value)}
                                    data-options=""
                                    className="v13"
                                    placeholder="不明の場合、選択不要"
                                />
                                <div className="inputWrapper">
                                    <input
                                        type="text"
                                        name="番号"
                                        placeholder="500"
                                        className="half"
                                        value={licencePlateClassification}
                                        onChange={(e) =>
                                            setLicencePlateClassification(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="inputWrapper">
                                    <input
                                        type="text"
                                        name="番号"
                                        placeholder="あ"
                                        className="half"
                                        value={licencePlateDistinction}
                                        onChange={(e) => setLicencePlateDistinction(e.target.value)}
                                    />
                                </div>
                                <div className="inputWrapper">
                                    <input
                                        type="text"
                                        inputMode="numeric"
                                        name="番号"
                                        placeholder="1234"
                                        className="half"
                                        value={licencePlateNumber}
                                        onChange={(e) => setLicencePlateNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                            {invalidLicencePlatePlace && (
                                <p className="cmt" style={errorStyle}>
                                {UserInfoMessages.RSEWS_V13_M022}
                                </p>
                            )}
                            {licencePlateClassificationValidation && (
                                <p className="cmt" style={errorStyle}>
                                {UserInfoMessages.RSEWS_V13_M015}
                                </p>
                            )}
                            {licencePlateDistinctionValidation && (
                                <p className="cmt" style={errorStyle}>
                                {UserInfoMessages.RSEWS_V13_M016}
                                </p>
                            )}
                            {licencePlateNumberValidation && (
                                <p className="cmt" style={errorStyle}>
                                {UserInfoMessages.RSEWS_V13_M017}
                                </p>
                            )}
                            <p className="title nomust">車両重量</p>
                            <input
                                type="text"
                                inputMode="numeric"
                                name="vehicleWeight"
                                placeholder="1234"
                                className="car_num"
                                value={carWeight}
                                onChange={(e) => setCarWeight(e.target.value)}
                            />
                            kg
                            {carWeightValidation && (
                                <p className="cmt" style={errorStyle}>
                                {UserInfoMessages.RSEWS_V13_M019}
                                </p>
                            )}
                        </div>
                    )}
                    {carKinds === "二輪" && (
                        <div className="tire2_switch">
                            <div className="flexWrapper vertical">
                                <div>
                                    <p className="title must">車両区分</p>
                                    <select
                                        name="car_maker"
                                        id=""
                                        className="v13"
                                        value={vehicle}
                                        onChange={(e) => setVehicle(e.target.value)}>
                                        <option value="">
                                            不明の場合、「その他」を選択
                                        </option>
                                        {twoWheelsList}
                                    </select>
                                    {notVehicle && (
                                        <p className="cmt" style={errorStyle}>
                                        {UserInfoMessages.RSEWS_V13_M020}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <p className="title nomust">バイク名称</p>
                                    <input
                                        type="text"
                                        name="バイク名称"
                                        className="v13"
                                        placeholder="バイク名称を記入してください。"
                                        value={bikeName}
                                        onChange={(e) => setBikeName(e.target.value)}
                                    />
                                    {bikeNameValidation && (
                                        <p className="cmt" style={errorStyle}>
                                        {UserInfoMessages.RSEWS_V13_M021}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <p className="title nomust">ナンバープレート</p>
                            <div className="flexWrapper vertical">
                                <input
                                    type="text"
                                    name="番号"
                                    className="v13"
                                    placeholder="1 品川 あ 12345"
                                    value={licencePlate}
                                    onChange={(e) => setLicencePlate(e.target.value)}
                                />
                                {licencePlateValidation && (
                                    <p className="cmt" style={errorStyle}>
                                    {UserInfoMessages.RSEWS_V13_M018}
                                    </p>
                                )}
                            </div>
                            <p className="title nomust">車両重量</p>
                            <input
                                type="text"
                                inputMode="numeric"
                                name="vehicleWeight"
                                placeholder="150"
                                className="car_num"
                                value={bikeWeight}
                                onChange={(e) => setBikeWeight(e.target.value)}
                            />
                            kg
                            {bikeWeightValidation && (
                                <p className="cmt" style={errorStyle}>
                                {UserInfoMessages.RSEWS_V13_M019}
                                </p>
                            )}
                        </div>
                    )}
                    <div className="submit small">
                        <button tabIndex={0} className="action arrow" onClick={(e) => isCarOverWeightValid(e)}>
                            次へ
                        </button>
                    </div>
                </div>
            </div>            
            <Footer display={displayFooter}/>
            <Cngestion />
        </div>
    );
};

export default UserInfo;
