import { api_url } from '../../constants';
import { ApiGet } from '../ApiGet';
import { ServiceInfo } from '../../types/serviceInfo';
import { ApiDef, validateResponse } from './common';

type Callback = (response:ServiceInfo) => void;
type Error = (error:any) => void;

/**
 * 署名情報やアクセストークンを暗号化するためのシークレットキーとソルトを取得する。
 *
 * @param {string}   serviceKey サービスキー。
 * @param {string}   rescueTime UNIXタイムスタンプ。
 * @param {Callback} success    成功時に呼び出されるコールバック関数。
 * @param {Error}    failure    失敗時に呼び出されるコールバック関数。
 */
export const getServiceInfo = (serviceKey:string, rescueTime:string, success:Callback, failure:Error):void => {
    const URL = api_url.API_URL + "service";

    const config = {
        headers:{
            "X-Service-Key": serviceKey,
            "X-RsRescue-Time": rescueTime,
        }
    };

    ApiGet(URL, config, (response:any) => {
        console.log('サービス情報取得API正常終了', response);

        const DEFS:ApiDef[] = [
            new ApiDef("secretKey", "string", 300, true),
            new ApiDef("salt",      "string", 300, true),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, (error:any) => {
        console.log('サービス情報取得API異常終了', error);
        failure(error);
    });
};
