
import { PageTransitionList, ErrorReason } from "../constants";
import PagingHistory from "./PagingHistory";
import * as CommonUtil from '../common/CommonUtil';
import * as Modal from '../common/Modal';
import { ErrorTransition } from '../common/ErrorUtil';

/**
 * 先頭画面に遷移させるか判定
 */
export const isTopPage = (pageName:string):boolean => {    
    const pageList = PageTransitionList.PAGE_LIST;

    //対象画面かの判定
    if(!containsTargetPage(pageName)){
        return false;
    }

    const getReliefInfo = sessionStorage.getItem('reliefInfo');
    const reliefInfo = getReliefInfo !== null ? JSON.parse(getReliefInfo) : null;

    var errorFlg = false;
    if (reliefInfo !== null) {

        //対象画面位置発見フラグ
        var existFlg = false;
        
        for(let i=pageList.length-1; i>=0; i--){
            for(let j=0; j < pageList[i].length; j++){
                if(existFlg){
                    //自身の画面は判定対象外もしくは、入力必須項目がない場合は対象外
                    if(pageList[i][j]['pageName'] === pageName || pageList[i][j]['requreList'].length === 0){
                        continue;
                    }
                    const pageObject = pageList[i][j];
                    errorFlg = isSetReliefInfo(reliefInfo, pageObject)
                    if(errorFlg){
                        break;
                    }
                }
                else{
                    if(pageList[i][j]['pageName'] === pageName){
                        existFlg = true;
                        //同階層もチェックする為リセット
                        j=-1;
                    }
                }                    
            }

            if(errorFlg){
                break;
            }
        }
    }
    else{
        errorFlg = true;
    }

    return errorFlg;
};


/**
 * PAGE_LISTの中に対象の画面が含まれるか判定
 * @param {string} pageName 対象画面
 */
const containsTargetPage = (pageName:string):boolean => {
    return PageTransitionList.PAGE_LIST.filter((valueList, index) => {
        if(index === 0){
            return false;
        }

        return valueList.filter((value) => {
        
            if(value.pageName === pageName){
                return true;
            }
            else{
                return false;
            }
        }).length > 0;
    }).length > 0
}

/**
 * 救援要請情報の存在確認
 * @param {any} reliefInfo JSON.parse(getReliefInfo)の結果
 * @param {any} pageObject 各画面の情報
 */
const isSetReliefInfo = (reliefInfo:any, pageObject:any):boolean => {
    if (!(pageObject['infoKeyName'] in reliefInfo)) {
        return true
    }

    for(let i=0; i < pageObject['requreList'].length; i++){
        if(Array.isArray(pageObject['requreList'][i])){
            let errorCount = 0
            for(let j=0; j < pageObject['requreList'][i].length; j++){
                const requreArrayList:Array<string> = pageObject['requreList'][i][j].split(',');
                errorCount = requreErrorCheck(reliefInfo, pageObject,errorCount,requreArrayList);
            }
            if(errorCount >= pageObject['requreList'][i].length){
                return true;
            }
        }
        else{
            if(!(pageObject['requreList'][i] in reliefInfo[pageObject['infoKeyName']])){
                return true;             
            }
        }
    }
    return false;
};

/**
 * ArrayList内のエラーチェック
 * @param {any} reliefInfo JSON.parse(getReliefInfo)の結果
 * @param {any} pageObject 各画面の情報
 * @param {number} errorCount エラーカウント
 * @param {Array<string>} requreArrayList 区切り文字を展開したリスト
 */
const requreErrorCheck = (reliefInfo:any, pageObject:any,errorCount:number,requreArrayList:Array<string>):any => {
    
    for(let i=0; i < requreArrayList.length; i++){
        let checkObject = reliefInfo[pageObject['infoKeyName']];
        if(i > 0){
            for(let j=0; j < i; j++){
                if(requreArrayList[j] in checkObject){
                    checkObject = checkObject[requreArrayList[j]];
                }
                else{
                    checkObject = null;
                }
            }
        }

        if(checkObject !== null && !(requreArrayList[i] in checkObject)){
            errorCount++           
        }
    }
    return errorCount;
};

//スタックの末尾に追加
export const pushHistory = (pageName:string, option:object) => {
    const pagingStack = new PagingHistory();
    pagingStack.push({pageName: pageName, option: option})
    pagingStack.save();
}

// 前画面と一致しているか判定
export const isBeforePage = (pageName:string):boolean => {
    const pagingStack = new PagingHistory();
    const backPage = pagingStack.before;

    if(pageName.toLowerCase() === backPage?.pageName?.toLowerCase()){
        return true;
    }
    else{
        return false;
    }
}

//エラーページかどうかチェック
export const isErrorPage = (pageName:string):boolean => {
    if(pageName !== null && (
        pageName.toLowerCase() === '/sorry' ||
        pageName.toLowerCase() === '/unauthorizederror' ||
        pageName.toLowerCase() === '/apierror' ||
        pageName.toLowerCase() === '/error' ||
        pageName.toLowerCase() === '/accessdeniederror'
        )){
        return true;
    }
    else{
        return false;
    }
}

//ランディングページかどうかチェック
export const isLandingPage = (pageName:string):boolean => {
    if(pageName !== null && (pageName.toLowerCase() === ('/') || pageName.toLowerCase() === ('/regist/confirm'))){
        return true;
    }
    else{
        return false;
    }
}

// 前画面があるかどうか判定
export const hasBeforePage = ():boolean => {
    const pagingStack = new PagingHistory();
    return (pagingStack.before) ? true : false;
}

// スタックが空の場合の追加処理
export const initializeIfNecessary = (pageName:string) => {
    const pagingStack = new PagingHistory();
    if(pagingStack.isEmpty()){
        //スタックに追加
        pushHistory(pageName, {available: true});
    }
}

// 次の画面をスタックに追加して画面遷移
export const next = (pageName:string, navigate:any, state?:object|null) => {
    //navigate時に固定でavailableをセットする
    var option = {available: true};
    if(state){
        option = Object.assign(option, state);
    }

    //履歴情報を追加する
    pushHistory(pageName, option);
    
    Modal.cleanPage();

    //画面遷移
    console.log(`navigate to ${pageName}`);
    navigate(pageName, {state: option})
}

// 自身のスタックを削除して1つ前の画面に遷移
export const back = (navigate:any) => {    
    const pagingStack = new PagingHistory();
    const backPage = pagingStack.before;
    if(backPage){
        pagingStack.pop();
        pagingStack.save();

        Modal.cleanPage();

        console.log(`navigate to ${backPage.pageName}`);
        navigate(backPage.pageName, {state: backPage.option});
    }
}

// スタックをクリアして次の画面に遷移
export const resetAndNext = (pageName:string, navigate:any, state?:object|null) => {
    PagingHistory.clean();
    next(pageName, navigate, state);
}

// 自身のスタックを削除して次の画面に遷移
export const backAndNext = (pageName:string, navigate:any, state?:object|null) => {
    const pagingStack = new PagingHistory();
    pagingStack.pop();
    pagingStack.save();
    next(pageName, navigate, state);
}

// 先頭の画面へ遷移する
export const backToTop = (navigate:any) => {
    const pagingStack = new PagingHistory();
    const topPage = pagingStack.top;
    
    if(topPage){
        resetAndNext(topPage.pageName, navigate, topPage.option);
    }
    else{
        //画面遷移履歴が存在しない場合エラー画面へ遷移
        resetAndNext(ErrorTransition(ErrorReason.UNKWNOWN), navigate);
    }
}

//画面遷移履歴が存在するか
export const isHistoryEmpty = ():boolean => {
    const pagingStack = new PagingHistory();

    return pagingStack.isEmpty();
}

/**
 * 現在の画面に対して補正を行う
 * @param {any} navigate navigateオブジェクト
 * @return {object|null} 補正先画面のオプション情報
 */
export const restore = (navigate:any):object|null => {    
    const pagingStack = new PagingHistory();
    const currentPage = pagingStack.current;

    //前画面情報が無い場合はreturn
    if(!currentPage){
        return null;
    }

    //画面遷移が不要な場合オプション情報のみを返却する
    if(CommonUtil.getCurrentPathName() === currentPage.pageName.toLowerCase()){
        return currentPage.option;
    }
    else{
        console.log(`navigate to ${currentPage.pageName}`);
        navigate(currentPage.pageName, {state: currentPage.option});
        return null;
    }
}

