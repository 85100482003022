import { api_url } from '../../constants';
import { MailValidateInfo } from '../../types/mailValidateInfo';
import { ApiPost_Authorization } from '../ApiPost';
import { ApiDef, validateResponse } from './common';
import Url from '../../common/Url';

type Callback = (response:MailValidateInfo) => void;
type Error = (error:any) => void;

/**
 * メールアドレス登録確認を行う。
 *
 * @param {string}   mailAddress メールアドレス。
 * @param {Callback} success     成功時に呼び出されるコールバック関数。
 * @param {Error}    failure     失敗時に呼び出されるコールバック関数。
 */
export const validateMailAddress = (mailAddress:string, success:Callback, failure:Error):void => {
    const url:Url = new Url(api_url.API_URL + "mailValidate");

    const config = {
        headers:{
            "Content-Type": "application/json; charset=utf-8"
        }
    };

    const request = {mailAddress: mailAddress};
    ApiPost_Authorization(url.create(), request, config, (response:any) => {
        console.log('メールアドレス登録確認成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("isOrder",     "string", 1, true),
            new ApiDef("isBlacklist", "string", 1, true),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};
