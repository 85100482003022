// 初期処理
import { getGeneralMasterList, getMasterInfo } from "./common/api/mstInfo"
import MasterInfo from "./common/MasterInfo"
import { GeneralInfo } from "./types/mstInfo"

//マスタ情報取得処理
export const RequestMaster = (success:any, failure:any):any => {
    //マスタ情報取得
    getGeneralMasterList(
        function (chk_isMstInfoList:any) {
            //キャッシュ汎用マスタ情報を取得
            const mstInfo:MasterInfo = new MasterInfo();
            const generalInfo:GeneralInfo[] = mstInfo.generalInfoList;

            const types = [];

            //初回セット
            if(generalInfo.length  === 0){
                //全マスタ情報を取得する
                for(let i=0; i<chk_isMstInfoList.length; i++){
                    types.push(chk_isMstInfoList[i]['generalKey']);
                }
            }
            else{
                //2回目以降のマスタ取得
                for(let i=0; i<chk_isMstInfoList.length; i++){
                    for(let j=0; j<generalInfo.length; j++){
                        //キーが一致するもので比較を行う
                        if(chk_isMstInfoList[i]['generalKey'] === generalInfo[j]['generalKey']){
                            //汎用マスタ情報とキャッシュ汎用マスタ情報でバージョン数値を比較
                            if(chk_isMstInfoList[i]['generalValue'] !== generalInfo[j]['generalValue']){
                                types.push(generalInfo[j]['generalKey']);
                            }
                            break;
                        }
                        //ローカルにキーが存在しなかった場合
                        if(j === generalInfo.length-1){
                            types.push(chk_isMstInfoList[i]['generalKey']);
                        }
                    }
                }
            }

            //セッションストレージにセットするマスタ情報がある場合
            if(types.length > 0){
                //再取得するマスタ情報取得
                getMasterInfo(types
                    ,function (re_mstInfo_response:any) {
                        mstInfo.update(re_mstInfo_response);
                        mstInfo.save();
                        success();
                    }
                    ,function (chk_error:any) {
                        //マスタ情報取得エラー
                        console.log('再取得するマスタ情報取得失敗', chk_error);
                        failure(chk_error);
                    }
                );
            }
            else{
                success();
            }
        }
        ,function (chk_error:any) {
            //マスタ情報取得エラー
            console.log('マスタ情報取得失敗', chk_error);
            failure(chk_error)
    });
}