// 初期設定
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation } from "react-router-dom";
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Logout } from '../common/Logout';
import * as CommonUtil from '../common/CommonUtil';
import PageProps from '../types/pageProps';

function AccessDeniedError(props:PageProps) {
    const location = useLocation();

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    // 初期処理
    useEffect(() => {
        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }

        CommonUtil.setIndicator(true);
        Logout().finally(() => {
            CommonUtil.setIndicator(false);
        })

        setDisplayFooter(true);
        return initResult['cleanup'];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //JAFに電話するボタン押下時
    const error = () => {
        window.location.href = 'https://jaf.or.jp/common/call-road-service/call-jaf';
    }

    // 画面設定
    return (
        <div>

            <HelmetProvider>
                <Helmet>
                    <title>エラー | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header hideMenu pageNavigator={props.pageNavigator}/>

            <div className="contentsWrapper">
                <div className="contents privacyWrapper">
                    <p className="general">
                        ご迷惑をおかけして申し訳ありませんが、現在ウェブサイトからJAFを呼ぶことが出来ません。<br />
                        以下のボタンから電話で救援要請いただけます。
                    </p>

                    <div className="submit half">
                        <button className="jaf" onClick={error}>JAFに電話する</button>
                    </div>
                </div>
            </div>
            <Footer display={displayFooter}/>
        </div>
    )
}
 
export default AccessDeniedError
