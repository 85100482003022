import { ErrorReason } from '../../constants';

/**
 * API定義クラス。
 */
export class ApiDef {
    readonly name:string;
    readonly type:string;
    readonly size:number;
    readonly required:boolean;
    readonly children:Array<ApiDef>;

    /**
     * コンストラクタ。
     *
     * @param {string}        name     APIのキー名。
     * @param {string}        type     APIキーの型。
     * @param {number}        size     APIキーに紐づく値のサイズ。
     * @param {boolean}       required APIキーが必須かどうか。
     * @param {Array<ApiDef>} children APIキーに紐付く値に格納されている子要素のAPI定義。
     */
    constructor(name:string, type:string, size:number, required:boolean, children:Array<ApiDef> = []) {
        this.name = name;
        this.type = type;
        this.size = size;
        this.required = required;
        this.children = children;
    }

    /**
     * API定義を検査する。
     *
     * @param  {any} data 検査対象のデータ。
     * @return {ErrorReason|null} エラー有無。<br>
     *                            エラーが検出されなければnullを返却し、
     *                            エラーが検出された場合はErrorReasonを返却する。
     */
    validate(data:any):ErrorReason|null {
        //console.log(`validate ${this.name}`);
        if (!(this.name in data)) {
            console.log(`${this.name} is not found`);
            return ErrorReason.RESPONSE_NOT_FOUND;
        } else {
            const child = data[this.name];
            if (child === null) {
                if (this.required) {
                    console.log(`${this.name} is null`);
                }
                return null;
            }

            const type = typeof(child);
            if (type !== this.type) {
                console.log(`${this.name} is ${type} but definition is ${this.type}`);
                return ErrorReason.RESPONSE_TYPE_MISMATCHED;
            } else if (this.type === "object") {
                if (Array.isArray(child)) {
                    for (let i = 0; i < child.length; ++i) {
                        const reason = this.validateChildren(child[i]);
                        if (reason) {
                            return reason;
                        }
                    }
                } else {
                    const reason = this.validateChildren(child);
                    if (reason) {
                        return reason;
                    }
                }
            }
        }
        return null;
    }

    /**
     * 子要素のAPI定義を検査する。
     *
     * @param  {any} data 検査対象のデータ。
     * @return {ErrorReason|null} エラー有無。<br>
     *                            エラーが検出されなければnullを返却し、
     *                            エラーが検出された場合はErrorReasonを返却する。
     */
    validateChildren(data:any):ErrorReason|null {
        for (let i = 0; i < this.children.length; ++i) {
            const reason = this.children[i].validate(data);
            if (reason) {
                return reason;
            }
        }
        return null;
    }
};

/**
 * 応答データに必須なキーが含まれているかどうかを確認する。
 *
 * @param  {string[]} keys     必須なキーの一覧。
 * @param  {any}      response 応答データ。
 * @return {boolean} 応答データに必須なキーが含まれているかどうか。<br>
 *                   true: すべてのキーが存在する。<br>
 *                   false: 存在しないキーがある。
 */
export const validateResponse = (defs:ApiDef[], data:any):ErrorReason|null => {
    if (!data) {
        console.log("response data is not found");
        return ErrorReason.RESPONSE_NOT_FOUND;
    }

    for (let i = 0; i < defs.length; ++i) {
        const reason = defs[i].validate(data);
        if (reason) {
            return reason;
        }
    }
    return null;
};
