import { chat_api_url } from '../../../constants';
import { ApiGet_ChatAccessToken } from '../../ApiGet';
import { ApiPost_ChatAccessToken } from '../../ApiPost';
import { ApiDef, validateResponse } from '../common';
import Url from '../../../common/Url';
import LoginUserInfo from '../../LoginUserInfo';

type UploadCallback = () => void;
type DownloadCallback = (chatImg:string) => void;
type Error = (error:any) => void;

/**
 * 画像データをアップロードする。
 *
 * @param {any}            image   画像データ。
 * @param {number}         size    画像サイズ。
 * @param {UploadCallback} success 成功時に呼び出されるコールバック関数。
 * @param {Error}          failure 失敗時に呼び出されるコールバック関数。
 */
export const uploadChatImage = (image:any, size:number, success:UploadCallback, failure:Error):void => {
    const URL = chat_api_url.API_URL + "image";

    const config = {
        headers:{
            "Content-Type" : "multipart/form-data; charset=utf-8",
            "X-RsRescue-Token": LoginUserInfo.getUserId(),
        }
    };

    ApiPost_ChatAccessToken(URL, image, config, (response:any) => {
        console.log('画像アップロード成功', response);

        success();
    }, failure);
};

/**
 * 画像データをダウンロードする。
 *
 * @param {string}           chatroomId       ルームID。
 * @param {number}           chatImgMessageId 画像メッセージID。
 * @param {DownloadCallback} success          成功時に呼び出されるコールバック関数。
 * @param {Error}            failure          失敗時に呼び出されるコールバック関数。
 */
export const downloadChatImage = (chatroomId:string, chatImgMessageId:string, success:DownloadCallback, failure:Error):void => {
    const url:Url = new Url(chat_api_url.API_URL + "image");
    url.addString("chatroomId", chatroomId);
    url.addString("chatImgMessageId", chatImgMessageId);

    const config = {
        headers:{
            "X-RsRescue-Token": LoginUserInfo.getUserId(),
        }
    };

    ApiGet_ChatAccessToken(url.create(), config, (response:any) => {
        console.log('画像ダウンロード成功', response);

        const DEFS:ApiDef[] = [ new ApiDef("chatImg", "string", 0, true) ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data.chatImg);
        } else {
            failure(reason);
        }
    }, failure);
};
