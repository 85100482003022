import React,{ useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import * as CommonUtil from '../common/CommonUtil';
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';

function OrderCancel(props:PageProps) {
    const navigate = useNavigate();
    const location = useLocation();

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    // 初期処理
    React.useEffect(() => {
        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
        setDisplayFooter(true);
        return initResult['cleanup'];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //救援要請をキャンセル押下処理
    const logoutClick = () => {
        //ログイン画面に遷移
        PagingUtil.resetAndNext("/userLogin", navigate)
    };

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>要請キャンセル完了 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>

            <Header pageNavigator={props.pageNavigator}/>
            <div className="contentsWrapper">
                <div className="contents">

                    <p className="general">
                    救援要請をキャンセルしました。
                    </p>

                    <div className="submit">
                        <input type="submit" value="ログアウト" onClick={logoutClick}/>
                    </div>

                </div>
            </div>
            <Footer display={displayFooter}/>
        </div>
    )
}

export default OrderCancel
