import { chat_api_url } from '../../../constants';
import { LatestChatMessageList } from '../../../types/chatInfo';
import { ApiGet_ChatAccessToken } from '../../ApiGet';
import { ApiDef, validateResponse } from '../common';
import Url from '../../../common/Url';
import LoginUserInfo from '../../LoginUserInfo';

type Callback = (response:LatestChatMessageList) => void;
type Error = (error:any) => void;

/**
 * チャットルームに投稿された新規メッセージを取得する。
 *
 * @param {string}   chatroomId ルームID。
 * @param {string}   messageId  指定されたメッセージIDより大きいIDを持つメッセージ(新着メッセージ)を取得する。
 * @param {Callback} success    成功時に呼び出されるコールバック関数。
 * @param {Error}    failure    失敗時に呼び出されるコールバック関数。
 */
export const getChatLatestMessages = (chatroomId:string, messageId:string, success:Callback, failure:Error):void => {
    const url:Url = new Url(chat_api_url.API_URL + "latestMessageInfo");
    url.addString("chatroomId", chatroomId);
    url.addNumber("messageId", Number(messageId));

    const config = {
        headers:{
            "X-RsRescue-Token": LoginUserInfo.getUserId(),
        }
    };

    ApiGet_ChatAccessToken(url.create(), config, (response:any) => {
        console.log('最新チャットメッセージ取得成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("messages",        "object", 0,  true, [
                new ApiDef("messageUniqId",     "string", 35,  true),
                new ApiDef("chatroomId",        "string", 25,  true),
                new ApiDef("userId",            "string", 11,  true),
                new ApiDef("controlCenterCode", "string", 1,   true),
                new ApiDef("chatMessageCode",   "string", 1,   true),
                new ApiDef("chatMessage",       "string", 512, true),
                new ApiDef("chatImgMessageId",  "string", 10,  false),
                new ApiDef("chatImg",           "string", 0,   false),
                new ApiDef("sent",              "string", 10,  true),
            ]),
            new ApiDef("latestMessageId", "string", 10, false),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};
