// 初期設定
import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';

import { TransitionCode, AuthCode, OrderFlag, BlacklistFlag } from '../constants';
import {LoginMessages,AlertMessages} from '../messages';

import { validateMailAddress } from "../common/api/mailValidate"
import { registerCertificationInfo } from "../common/api/certificationInfo"
import { MailValidateInfo } from '../types/mailValidateInfo';

import { ModalDialog } from '../common/Modal';
import { ErrorTransition } from '../common/ErrorUtil';
import * as CommonUtil from '../common/CommonUtil';
import * as PagingUtil from '../common/PagingUtil';
import MailInfo from '../common/MailInfo';
import PageProps from '../types/pageProps';


const trasition_code = TransitionCode.RELIEF_ORDER;
const auth_code = AuthCode.LOGIN_CONFIRM;

function UserLogin(props:PageProps) {

    const navigate = useNavigate();
    const location = useLocation();
    const processing = useRef(false);

    const [email, setEmail] = useState("");

    const [errorComment1, setError1] = useState("");
    const [errorComment2, setError2] = useState("");

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    //スクロール対象定義
    const scrollTop = useRef<HTMLDivElement>(null);

    // 初期処理
    React.useEffect(() => {
        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }

        // すでに送信済みなら、送信済み画面へ移動
        const mailInfo:MailInfo = new MailInfo();
        if (mailInfo.sendLoginMailFlg) {
            PagingUtil.resetAndNext("/userLoginResult", navigate);
            return initResult['cleanup'];
        }

        // メール送信完了フラグの設定
        mailInfo.sendLoginMailFlg = false;
        mailInfo.save();

        //メンテナンスメッセージの取得
        CommonUtil.getMainteMessage().then(function(response: any){
            CommonUtil.setIndicator(false);
        })
        .catch(function(response: any){
            PagingUtil.resetAndNext(ErrorTransition(response), navigate);
        })

        setDisplayFooter(true);
        return initResult['cleanup'];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ボタン押したときの処理
    function userEmailSend(e: { preventDefault: () => void; }) {
        setError1("");
        setError2("");
        e.preventDefault();
        if (processing.current) return;
        processing.current = true;
        console.log(email);

        var errorFlg: boolean = false;

        if (email.length === 0) {
            setError1(LoginMessages.RSEWS_V31_M002);
            errorFlg = true;
        }
        else if (!CommonUtil.checkMailAddress(email) || email.length > 256) {
            setError1(LoginMessages.RSEWS_V31_M005);
            errorFlg = true;
        }

        if(errorFlg){
            processing.current = false;
            scrollTop?.current?.scrollIntoView({behavior: "smooth"});
            return;
        }

        CommonUtil.setIndicator(true);
        validateMailAddress(email
            ,function (info:MailValidateInfo) {
                // APIが正常の場合、ページ遷移
                if (info.isBlacklist === BlacklistFlag.UNREGISTERED && info.isOrder === OrderFlag.REGISTERING) {
                    registerCertificationInfo({ mailAddress: email, transitionCode: trasition_code, inflowCode:null, authCode: auth_code }
                        ,function (hash:string) {
                            // メール送信完了フラグの設定
                            const mailInfo:MailInfo = new MailInfo();
                            mailInfo.sendLoginMailFlg = true;
                            mailInfo.save();

                            PagingUtil.resetAndNext("/userLoginResult", navigate);
                            CommonUtil.setIndicator(false);
                            processing.current = false;
                        }
                        ,function (error:any) {
                            // 取得失敗時
                            console.log('送信失敗', error);
                            processing.current = false;
                            PagingUtil.resetAndNext(ErrorTransition(error), navigate);
                            return;
                        }
                    );

                }
                else{
                    if (info.isBlacklist === BlacklistFlag.REGISTERED) {
                        setError1(LoginMessages.RSEWS_V31_M004);
                        scrollTop?.current?.scrollIntoView({behavior: "smooth"});
                    } else if (info.isOrder === OrderFlag.UNREGISTERED) {
                        setError1(LoginMessages.RSEWS_V31_M003_1);
                        setError2(LoginMessages.RSEWS_V31_M003_2);
                        scrollTop?.current?.scrollIntoView({behavior: "smooth"});
                    } else  {
                        ModalDialog(AlertMessages.RSEWS_ER_03100)
                    }
                    CommonUtil.setIndicator(false);
                    processing.current = false;
                }

            }
            ,function (error:any) {
                // 取得失敗時
                console.log('取得失敗', error);
                CommonUtil.setIndicator(false);
                processing.current = false;
                PagingUtil.resetAndNext(ErrorTransition(error), navigate);

            }
        );
    }


    //新規救援要請される方はこちらボタン押下時
    const goHome = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (processing.current) return;
        processing.current = true;
        console.log("goHome");
        PagingUtil.backAndNext("/", navigate);
    }

    // 画面設定
    return (
        <div>

            <HelmetProvider>
                <Helmet>
                    <title>ログイン | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header pageNavigator={props.pageNavigator}/>
            <div ref={scrollTop} className="contentsWrapper">
                <div className="contents">

                    <p className="general">
                        救援要請時に指定したメールアドレスを入力してください。<br />
                    </p>
                    <dl className="form1 one">
                        <dt>メールアドレス</dt>
                        <dd>
                            <input type="text" inputMode="email" className='mailAddress' onChange={(e) => setEmail(e.target.value)} placeholder="メールアドレス" />
                        </dd>
                    </dl>
                    <form onSubmit={userEmailSend}>
                        <p className="error">{errorComment1}<br></br>{errorComment2}</p>
                        <div className="privacy">
                            <button className="default" type="button" onClick={goHome}>新規救援要請される方はこちら</button>
                        </div>
                        <div className="submit">
                            <button className="submit arrow" type="submit">ログイン</button>
                        </div>
                    </form>

                </div>
            </div>
            <Footer display={displayFooter}/>
        </div>
    )
}

export default UserLogin
