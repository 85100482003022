
import React from 'react'
import { useEffect, useState , useRef} from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import $ from 'jquery';
import { FuzzyReliefModalDialog, ReliefConfirmModalDialog, ModalDialog } from '../common/Modal';
import { map_box,QueryParameter,RoadLocationCode, ErrorReason } from '../constants';
import { ReliefMessages, AlertMessages } from '../messages';
import * as MapBox from '../mapbox';
import * as MasterInfoGet from "../masterInfoGet";
import { Logout } from '../common/Logout';
import * as CommonUtil from '../common/CommonUtil';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Cngestion } from '../header/Congestion';
import { ErrorTransition } from '../common/ErrorUtil';
import * as ReliefUtil from "../common/ReliefUtil";
import { OrderResponse } from '../types/orderInfo';
import MasterInfo from "../common/MasterInfo"
import GeoLocation from "../common/GeoLocation"
import { Location } from "../types/location"
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';


function Relief(props:PageProps) {

    const errorStyle = {
        color: "red",
    };

    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);
    
    /* state変数を定義する↓ */
    const [other, setOther] = useState(false);
    const [disabled, setDisabled] = useState((reliefInfo && reliefInfo['reliefAddressInfo']) ? false : true);
    const [locationCode, setLocationCode] = useState(reliefInfo?.reliefAddressInfo?.location !== undefined ? reliefInfo?.reliefAddressInfo.location : "");
    
    // バリデーションエラー表示用State
    const [unselectedLocation, setUnselectedLocation] = useState(false);
    const [notEnteredSpot, setNotEnteredSpot] = useState(false);
    const [spotInputLimit, setSpotInputLimit] = useState(false);

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    //多重押下対策
    const operation = useRef(new CommonUtil.Operation());
    const location = useLocation();

    //スクロール対象定義
    const scrollTop = useRef<HTMLDivElement>(null);


    // 初期処理
    useEffect(() => {
        const initResult = CommonUtil.initialize(true, true, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }

        //メンテナンスメッセージの取得
        CommonUtil.getMainteMessage().then(function(response: any){
            //メンテナンスメッセージクローズ後に救援要請制御情報取得
            return CommonUtil.getOrderControl(true, true, ReliefUtil.getPrefectureCode(reliefInfo));
        })
        .then(function(response: any){
            //救援要請制御ダイアログかどうか
            if(response){
                PagingUtil.resetAndNext(ErrorTransition(ErrorReason.ORDER_CONTROL_ERROR), navigate);
                return;
            }
            MasterInfoGet.RequestMaster(getPosition, function (chk_error:any) {
                PagingUtil.resetAndNext(ErrorTransition(chk_error), navigate);
            });
        })
        .catch(function(response: any){
            PagingUtil.resetAndNext(ErrorTransition(response), navigate);
        })


        //マスタ情報取得完了後
        function getPosition(){
            // 位置情報パーミッション判定
            GeoLocation.getCurrentPosition().then((location:Location) => {
                CommonUtil.setIndicator(false);

                let currentPos:string = 'false';
                if (location.latitude && location.longitude) {
                    currentPos = 'true';
                } else {
                    currentPos = 'false';
                    // パーミッション拒否
                    CommonUtil.displayFngestionFbar();
                }

                //要請内容確認画面での指定位置再確認ダイアログ表示判定用
                // eslint-disable-next-line react-hooks/exhaustive-deps 
                reliefInfo = {...reliefInfo, ...{'currentPos' : currentPos}};
                sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));
                setDisplay();
            });
        };

        //画面の選択項目を生成
        const setDisplay = () => {
            //マスタ情報取得
            const mstInfo:MasterInfo = new MasterInfo();

            $('#location').empty();
            $('#location').append($('<option value="" disabled>お車の位置を選択してください。</option>'));
            // 車両位置オプション追加
            let roadLocationInfo = mstInfo.roadLocationInfoList;
            for(let i=0;i<roadLocationInfo.length;i++){
                let option = document.createElement('option');
                option.value = roadLocationInfo[i]['roadLocationCode']
                option.text = roadLocationInfo[i]['roadLocationName'];
                option.setAttribute('data-locationcode', roadLocationInfo[i]['locationCode'])
                option.setAttribute('data-roadcode', roadLocationInfo[i]['roadCode'])
                $('#location').append(option);
            }
            $('#location').val(locationCode);

            // 救援要請情報判定
            if(!(reliefInfo['reliefAddressInfo'] === undefined)) {
                // 救援要請情報を入力項目にフィルイン
                let addressInfo = reliefInfo['reliefAddressInfo'];
                $('#spot').val(addressInfo['reliefLocationSupplementInfo']);
                if(locationCode === RoadLocationCode.OTHER) {
                    setOther(true)
                } else {
                    setOther(false)
                }
            }

            setTimeout(function(){
                MapBox.deleteMap();
                MapBox.displayMap(map_box.MAP_STYLE, true, null,null,"marker_relief");
            },100);
        }
        setDisplayFooter(true);
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

    const navigate = useNavigate();

    // 次へボタン押下
    const nextClick = () => {
        operation.current.prevent();
        setUnselectedLocation(false);
        setNotEnteredSpot(false);
        setSpotInputLimit(false);

        let trimSpot:any = $('#spot').val();
        trimSpot = trimSpot.trim();
        $('#spot').val(trimSpot);
        var errorFlg: boolean = false;

        if(locationCode === "") {
            setUnselectedLocation(true);
            errorFlg = true;
        }
        else if(locationCode === RoadLocationCode.OTHER && $('#spot').val() === '') { 
            setNotEnteredSpot(true);
            errorFlg = true;
        } else if(trimSpot.length > 100) {
            setSpotInputLimit(true);
            errorFlg = true;
        }

        if(errorFlg){
            scrollTop?.current?.scrollIntoView({behavior: "smooth"});
            return;
        }

        if(locationCode === RoadLocationCode.FREEWAY) {
            const dialog = FuzzyReliefModalDialog(true, ():boolean => {
                operation.current.prevent();
                setRelief(dialog);
                return false;
            }, () => {

            });

        } else {
            ReliefConfirmModalDialog(() => {
                operation.current.prevent();
                setRelief(null);         
            }, () => {

            });
        }
    }

    // 車両位置選択イベント
    const changeLocate = (selectValue:any) => {
        setLocationCode(selectValue);

        // 入力欄を必須項目に変更
        if(selectValue === RoadLocationCode.OTHER) {
            setOther(true)
        } else {
            setOther(false)
        }
    }
    // マップクリック時の処理
    let clickMap = (event:any) => {
        //クリック箇所の判定
        if(!MapBox.checkMapClick(event.target))
        {
            return;
        }
        else{
            setDisabled(false)
        }
    }

    //指定位置確認ダイアログ/電話離脱案内ダイアログクローズ後の共通処理
    const setRelief = (fuzzyReliefDialog?:any) => {
        // マーカーの中心位置から救援場所情報を取得
        CommonUtil.getReliefAddressInfo(reliefInfo).then((response) => {
            if (response) {
                const reliefAddressInfo = {
                    'longitude': response.longitude,
                    'latitude': response.latitude,
                    'geocodeReliefAddress': response.geocodeReliefAddress,
                    'prefectureCode': response.prefectureCode,
                    'areaCode': response.areaCode,
                    'controlCenterCode': response.controlCenterCode,
                    'location': $('#location').val(),
                    'locationCode': $('#location option:selected').data('locationcode'),
                    'roadCode': $('#location option:selected').data('roadcode'),
                    'reliefLocationSupplementInfo': $('#spot').val(),
                };
                reliefInfo = {...reliefInfo, ...{'reliefAddressInfo' : reliefAddressInfo}};
                sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));

                if(fuzzyReliefDialog !== null){
                    fuzzyRegist(fuzzyReliefDialog);
                }else{
                    ReliefUtil.transition("/userInfo", navigate);
                }
            } else {
                ModalDialog(AlertMessages.RSEWS_ER_91110);
            }
        });
    }

    //電話離脱時依頼内容登録API呼び出し
    const fuzzyRegist = (dialog:any) => {
        // 電話離脱時依頼内容登録API成功時
        const registSuccess = (success: OrderResponse) => {
            //最新の救援要請情報再取得
            reliefInfo = sessionStorage.getItem('reliefInfo');
            reliefInfo = JSON.parse(reliefInfo);
            let prefectureCode = reliefInfo['reliefAddressInfo']['prefectureCode'];
            dialog.close();
            //ログアウト処理
            Logout().finally(() => {
                CommonUtil.setIndicator(false);
                //sessionStorageから取得した都道府県コードをURLパラメータとして電話案内画面へ渡す
                PagingUtil.resetAndNext(`/call?${QueryParameter.PARAMETER_PREFECTURE_CODE}=` + prefectureCode, navigate);
            })
        };

        // 電話離脱時依頼内容登録APIエラー時
        const registFail = (e: any) => {
            CommonUtil.setIndicator(false);
            console.log('電話離脱時依頼内容登録失敗', e);
            dialog.close();
            PagingUtil.resetAndNext(ErrorTransition(e), navigate);
        };
        
        CommonUtil.setIndicator(true);
        CommonUtil.postFuzzyRegist(registSuccess, registFail);
    }

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>救援場所入力 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header pageNavigator={props.pageNavigator}/>
            <div ref={scrollTop} className="contentsWrapper">
                <div className="contents">
                    <ul className="status">
                        <li className="current">救援場所入力</li>
                        <li>お客様情報入力</li>
                        <li>トラブル内容入力</li>
                        <li>内容確認</li>
                        <li>登録完了</li>
                    </ul>
                </div>
            </div>
            <div className="contentsWrapper top0">
                <div className="contents height_full">
                    <p className="general bold">
                        JAFを呼ぶ場所にピンを合わせてください。
                    </p>
                <div className="mapWrapper">
                <div id="map"className="map cross" onClick={clickMap}>
                </div>
                <div className="buttonWrapper">
                    <div className="select must">
                        <select
                            name="場所区分"
                            id="location"
                            className="must"
                            value={locationCode}
                            onChange={(e) => changeLocate(e.target.value)}
                            required>
                        </select>
                        { unselectedLocation && (
                            <p className="cmt" style={errorStyle}>
                                {ReliefMessages.RSEWS_V11_M002}
                            </p>
                        )}
                    </div>
                    <div id="area_info" className={other ? "select must" : "select nomust"}>
                        <textarea name="" id="spot" placeholder="周辺施設（○○店 ○○コンビニ ○○駐車場　等）" ></textarea>
                        { notEnteredSpot && (
                            <p className="cmt" style={errorStyle}>
                            {ReliefMessages.RSEWS_V11_M003}
                            </p>
                        )}
                        { spotInputLimit && (
                            <p className="cmt" style={errorStyle}>
                            {ReliefMessages.RSEWS_V11_M004}
                            </p>
                        )}
                    </div>
                    <div className="column2">
                        <button className={disabled ? "action arrow gray" : "action arrow"} disabled={disabled} onClick={nextClick}>次へ</button>
                    </div>
                </div>
                </div>

                </div>
            </div>
            <Footer display={displayFooter}/>
            <Cngestion />
        </div>
    )
}

export default Relief
