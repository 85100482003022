/**アラートメッセージ定義 */

/** メールアドレス入力画面 */
export class HomeMessages {
  static readonly RSEWS_V01_M003 = 'メールアドレスが未入力です。';
  static readonly RSEWS_V01_M004 = 'メールアドレスが不正です。';
  static readonly RSEWS_V01_M005 = 'メールアドレスが一致していません。';
  static readonly RSEWS_V01_M006_1 = 'このメールアドレスは既に登録されています。';
  static readonly RSEWS_V01_M006_2 = '救援要請済みの方は「救援要請済みの方はこちら」からログインをお願いします。';
  static readonly RSEWS_V01_M007_1 = 'このメールアドレスは使用できません。';
  static readonly RSEWS_V01_M007_2 = '救援要請の場合はお電話にてお願いします。';
}

/** 救援場所入力画面 */
export class ReliefMessages {
  static readonly RSEWS_V11_M002 = 'お車の位置が未選択です。';
  static readonly RSEWS_V11_M003 = '周辺施設が未入力です。';
  static readonly RSEWS_V11_M004 = '周辺施設は100文字以内で入力してください。';
}

/** 車両・お客様情報入力画面 */
export class UserInfoMessages {
  static readonly RSEWS_V13_M002 = '氏名が未入力です。';
  static readonly RSEWS_V13_M003 = '氏名は全角25文字以内で入力してください。';
  static readonly RSEWS_V13_M004 = '氏名（カナ）が未入力です。';
  static readonly RSEWS_V13_M005 = '氏名（カナ）は全角カタカナ25文字以内で入力してください。';
  static readonly RSEWS_V13_M006 = '電話番号が未入力です。';
  static readonly RSEWS_V13_M007 = '電話番号は半角数字10～11桁で入力してください。';
  static readonly RSEWS_V13_M008 = '予備の電話番号は半角数字10～11桁で入力してください。';
  static readonly RSEWS_V13_M009 = 'JAFへの入会が未選択です。';
  static readonly RSEWS_V13_M010 = '会員番号は半角数字12桁で入力してください。';
  static readonly RSEWS_V13_M011 = '支払方法が未選択です。';
  static readonly RSEWS_V13_M012 = '車両メーカーが未選択です。';
  static readonly RSEWS_V13_M013 = '車両名称が未選択です。';
  static readonly RSEWS_V13_M014 = 'その他の車両名称は100文字以内で入力してください。';
  static readonly RSEWS_V13_M015 = 'ナンバープレート(分類番号)は半角数字・半角英大文字3桁以内で入力してください。';
  static readonly RSEWS_V13_M016 = 'ナンバープレート(事業用判別文字)は全角ひらがな・半角英大文字1文字で入力してください。';
  static readonly RSEWS_V13_M017 = 'ナンバープレート(指定番号)は半角数字5桁以内で入力してください。';
  static readonly RSEWS_V13_M018 = 'ナンバープレートは20文字以内で入力してください。';
  static readonly RSEWS_V13_M019 = '車両重量は半角数字5桁以内で入力してください。';
  static readonly RSEWS_V13_M020 = '車両区分が未選択です。';
  static readonly RSEWS_V13_M021 = 'バイク名称は100文字以内で入力してください。';
  static readonly RSEWS_V13_M022 = 'ナンバープレート(本拠地)が正しくありません';
  static readonly RSEWS_V13_M023 = '車両名称が正しくありません。';
}


/** 要請内容入力画面 */
export class OrderContentMessages {
  static readonly RSEWS_V14_M001 = 'トラブル内容が未選択です。';
  static readonly RSEWS_V14_M002 = 'ご希望内容が未選択です。';
  static readonly RSEWS_V14_M003 = 'トラブル状況等は100文字以内で入力してください。';
  static readonly RSEWS_V14_M004 = 'ファイル形式が不正です。';
  static readonly RSEWS_V14_M005 = 'ファイルサイズが大きすぎます。';
}

/** お運び先入力画面 */
export class OrderCarryMessages {
  static readonly RSEWS_V15_M002 = 'お運び先店舗名は40文字以内で入力してください。';
}

/** ログイン画面 */
export class LoginMessages {
  static readonly RSEWS_V31_M002 = 'メールアドレスが未入力です。';
  static readonly RSEWS_V31_M003_1 = 'このメールアドレスは登録されていません。';
  static readonly RSEWS_V31_M003_2 = 'これから救援要請される方は「新規救援要請される方はこちら」から利用登録をお願いします。';
  static readonly RSEWS_V31_M004 = 'このメールアドレスは使用できません。';
  static readonly RSEWS_V31_M005 = 'メールアドレスが不正です。';
}

/** 受付状況確認画面 */
export class ReceptionStatusMessages {
  static readonly RSEWS_V21_M002 = '申し訳ございません。救援号車は確定次第ご連絡いたします。';
  static readonly RSEWS_V21_M003 = 'チャットにてご連絡いたします。『JAFにチャット』からご確認ください。';
  static readonly RSEWS_V21_M004 = 'ご予約いただきました日時にお伺いさせていただきます。';
}

/** チャット画面 */
export class ChatMessages {
  static readonly RSEWS_V23_M001 = 'メッセージが未入力です。';
  static readonly RSEWS_V23_M002 = 'メッセージは200文字以内で入力してください。';
}

/** 電話離脱案内ダイアログ */
export class FuzzyReliefMessages {
  static readonly RSEWS_D06_M003 = 'JAFから折り返し連絡を受け取れる電話番号を入力してください。';
  static readonly RSEWS_D06_M004 = '電話番号が未入力です。';
  static readonly RSEWS_D06_M005 = '電話番号は半角数字10～11桁で入力してください。';
}

/** アラートメッセージダイアログ */
export class AlertMessages {
  static readonly RSEWS_ER_00100 = 'システムエラーが発生しました。<br>(RSEWS-ER00100)';
  static readonly RSEWS_ER_03100 = 'システムエラーが発生しました。<br>(RSEWS-ER03100)';

  static readonly RSEWS_ER_22300 = 'システムエラーが発生しました。<br>(RSEWS-ER22300)';
  static readonly RSEWS_ER_2400  = 'システムエラーが発生しました。<br>(RSEWS-ER2400)';
  static readonly RSEWS_ER_2401  = 'システムエラーが発生しました。<br>(RSEWS-ER2401)';
  static readonly RSEWS_ER_2403  = 'システムエラーが発生しました。<br>(RSEWS-ER2403)';
  static readonly RSEWS_ER_2404  = 'システムエラーが発生しました。<br>(RSEWS-ER2404)';
  static readonly RSEWS_ER_2500  = 'システムエラーが発生しました。<br>(RSEWS-ER2500)';  

  //逆ジオ住所取得エラー
  static readonly RSEWS_ER_91110 = '救援場所が特定できませんでした。<br>正しい救援場所を指定してください。<br>(RSEWS-ER91110)';
  static readonly RSEWS_ER_91510 = '救援場所が特定できませんでした。<br>正しいお運び先を指定してください。<br>(RSEWS-ER91510)';

  //ファイル読み込みエラー
  static readonly RSEWS_ER_91410  = '画像の読み込みに失敗しました。<br>再度画像を選択してください。<br>(RSEWS-ER91410)';
  static readonly RSEWS_ER_91420  = '画像の削除に失敗しました。<br>(RSEWS-ER91420)';
  static readonly RSEWS_ER_91610  = '画像の読み込みに失敗しました。<br>再度画像を選択してください。<br>(RSEWS-ER91610)';

  static readonly RSEWS_ER_92210  = '画像の読み込みに失敗しました。<br>(RSEWS-ER92210)';
  static readonly RSEWS_ER_92310  = '画像の読み込みに失敗しました。<br>再度画像を選択してください。<br>(RSEWS-ER92310)';
  static readonly RSEWS_ER_92320  = '画像の送信に失敗しました。<br>(RSEWS-ER92320)';
  static readonly RSEWS_ER_92330  = '画像の送信に失敗しました。<br>(RSEWS-ER92330)';

  // バージョン不一致時の更新確認
  static readonly RSEWS_ER_9997  = '新しいバージョンを検出しました。<br>再表示を行います。<br>(RSEWS-ER9997)';

  //救援場所情報が存在しない場合に表示される
  static readonly RSEWS_ER_9998  = 'システムエラーが発生しました。<br>(RSEWS-ER9998)';

  //その他、予期しないエラーが発生した場合
  static readonly RSEWS_ER_9999  = 'システムエラーが発生しました。<br>(RSEWS-ER9999)';
}

/** ダイアログ内のボタン文言 */
export class DialogButtonStr {
  static readonly YES = "はい";
  static readonly CONFIRM  = "確認";
}
