// 初期設定
import React, { useState, useRef, useEffect} from 'react';
import { OperatorUser,ChatMessageCode,ImageSetting, ChatPollingSetting } from '../constants';
import { AlertMessages, ChatMessages } from '../messages';
import * as ReliefUtil from "../common/ReliefUtil";

import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import $ from "jquery";

// モーダル呼び出し関数
import { ModalDialog, ImageModalDialog } from '../common/Modal';
// チャット
import { getLastMessageInfo,getMessageInfo,sendMessageInfo,convTimeToDays,convTimeToDateTime,sendImageInfo,downloadChatImage,readyMessageInfo } from '../common/Chat';
import * as CommonUtil from '../common/CommonUtil';
import * as StyleUtil from '../common/StyleUtil';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Cngestion } from '../header/Congestion';
import { ErrorTransition,ErrorChatMessage } from '../common/ErrorUtil';
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';

var fast_log_date = "";
var pre_date = "";

let reliefInfo:any = null;

//モーダルに表示する画像のサイズ
const IMAGESIZE_WIDTH:number = 400;
const IMAGESIZE_HEIGHT:number = 400;

function Chat(props:PageProps) {
    const textIndent = {
        textIndent: "-0.9rem",
    }

    // エラーのスタイル
    const errorStyle = {
        color: "red",
    };

    const getReliefInfo = sessionStorage.getItem('reliefInfo');
    reliefInfo = (getReliefInfo === undefined || getReliefInfo === null) ? "" : JSON.parse(getReliefInfo);

    const [text, setText] = useState("");
    const interval:any = useRef(null);

    const processing = useRef(false);

    const [isCheckedCamera, setIsCheckedCamera] = useState(true);
    const [isCheckedPicture, setIsCheckedPicture] = useState(true);
    const [isCheckedText, setIsCheckedText] = useState(true);
    const [message, setMessage] = useState('');

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    const latestMessageId = useRef<string>("");
    const earliestMessageId = useRef<string>("");
    const messageIdList = useRef<string[]>([]);
    const isPreChatNecessary = useRef<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        
        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }
        
        const cleanup = initResult['cleanup'];
        initResult['cleanup'] = () => {
            cleanup();
            // ポーリングイベント削除
            clearChatPolling();
        };
        
        //救援要請制御情報取得
        CommonUtil.getOrderControl(false, true, ReliefUtil.getPrefectureCode(reliefInfo))
        .then(function(response: any){ 
            //救援要請制御ダイアログは表示されない

            //念の為初期表示時にメッセージクリア
            $("#chat_room").html("");

            // 初期読み込み
            preChatLoad(null, true)
            .then(function(resdata: any){                 
                //初期読み込みが完了したらポーリング処理開始
                if (interval.current === null) {
                    // ポーリング処理
                    interval.current = setInterval(function(){
                        //画面遷移後にポーリング処理が残り続けてしまった場合の処置
                        if(window.location.pathname !== "/chat/message"){                    
                            clearInterval(interval.current);
                            interval.current = null;
                            return;
                        }

                        // 読み込み処理
                        chatLoad(latestMessageId.current, true)
                        .then(function(resdata: any){
                        })
                        .catch(function(response: any){   
                            ModalDialog(ErrorChatMessage(response));
                        })
                    },ChatPollingSetting.TIME);
                }
                setDisplayFooter(true);
            })
            .catch(function(response: any){
                PagingUtil.resetAndNext(ErrorTransition(response), navigate);  
            })
        })
        .catch(function(response: any){
            CommonUtil.setIndicator(false);
            PagingUtil.resetAndNext(ErrorTransition(response), navigate);        
        })
        
        $('#chat_room').off();
        // スクロールにより過去ログの読み込み処理
        $("#chat_room").scroll(function () {
            const scroll_h = $(this).scrollTop();

            //すでにすべての過去ログ取得済みの場合はチャットメッセージ取得APIは実行しない
            if(scroll_h === 0 && isPreChatNecessary.current === true){
                console.log(earliestMessageId.current);
                preChatLoad(earliestMessageId.current, false)
                .then(function(resdata: any){
                })
                .catch(function(response: any){
                    PagingUtil.resetAndNext(ErrorTransition(response), navigate);
                })
            }
        });

        $(document).off("change", "#chatpicture");
        $(document).on("change", "#chatpicture", function (e) {
            changeFile(e, this);
        });

        $(document).off("change", "#camera");
        $(document).on("change", "#camera", function (e) {
            changeFile(e, this);
        });

        $(document).off("click", ".chat_img");
        $(document).on( "click", ".chat_img", function (){
            var imgId = $(this).attr('id') || "";
            console.log(imgId);
            downLoadChatImage(imgId);
        });

        //ポーリングイベント削除
        function clearChatPolling() {        
            if(interval.current !== null){
                clearInterval(interval.current);
                interval.current = null;
            }
        }
    
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

    //ファイル選択処理
    function changeFile(e:any, content:any) {
        if (e.target.files.length === 0) return;

        let file = e.target.files[0]
        if(checkImageFile(file)){
            return;
        }

        setMessage('');
        sendImage(content);
    }

    function checkImageFile(file:File) {
        // ファイルの拡張子・MIMEタイプ・サイズチェック
        let errorMessage = CommonUtil.checkImageFile(file);
        
        if (errorMessage !== "") {
            setMessage(errorMessage);
            return true;
        }
        return false;
    }

    
    function downLoadChatImage(chatImgMessageId: string) {
        CommonUtil.setIndicator(true);

        downloadChatImage(chatImgMessageId).then(
            function(image: any){
                ImageModalDialog(image, IMAGESIZE_WIDTH, IMAGESIZE_HEIGHT);
                CommonUtil.draw("imageModal", image).then(function(response: any){
                })
                .catch(function(error: any){
                });
                CommonUtil.setIndicator(false);
            }
        )
        .catch(function(error: any){
            PagingUtil.resetAndNext(ErrorTransition(error), navigate);
            CommonUtil.setIndicator(false);
        });
    }

    function preChatLoad(messageId: string|null, initialFlg: boolean) {
        
        CommonUtil.setIndicator(true);
        return new Promise((resolve, reject) => {
            getMessageInfo(messageId).then(
            async function(resdata: any){

                const res_data = resdata.messages;

                //取得件数が0件の場合は、スクロール時にチャットメッセージ取得APIを実行しないようにする
                if(res_data.length === 0){
                    isPreChatNecessary.current = false;
                    CommonUtil.setIndicator(false);
                    resolve(null);
                    return;
                }

                //初期表示の場合のみ最新メッセージIDを保存
                if(resdata.latestMessageId != null && resdata.latestMessageId !== "" && initialFlg){
                    // 最新メッセージID保存
                    latestMessageId.current = resdata.latestMessageId;
                }
                //最古メッセージIDを保存
                if(resdata.earliestMessageId != null && resdata.earliestMessageId !== ""){
                    // 最古メッセージID日時保存
                    earliestMessageId.current = resdata.earliestMessageId;
                }

                var set_height = $("#chat_room" ).scrollTop() ?? 0;  //現在のスクロールの距離を取得
                let drawErrFlg = false;
                for(let i=0; i<res_data.length; i++){
                    const value = res_data[i];
                    //取得済みのメッセージか存在確認
                    if(messageIdList.current.includes(value.messageUniqId)) {
                        continue;
                    }
                    messageIdList.current.push(value.messageUniqId);

                    const send_time = value.sent;

                    // オペレーターかユーザかの判断と出力先設定および、出力重複確認
                    var lr_class = "right";
                    if(value.userId === OperatorUser.USER_ID){
                        lr_class = "left";
                    }

                    const set_date = convTimeToDateTime(send_time);
                    var chatsent_id = "chatsent"+value.sent;
                    var chatMessageCode = value.chatMessageCode;

                    // 画像かテキストの判定
                    var chat_text = "";
                    if(chatMessageCode === ChatMessageCode.FILE){
                        chat_text = '<div class="chat" id="'+ chatsent_id +'"><div class="'+lr_class+'"><canvas class="chat_img chatImgStyle" id="'+value.chatImgMessageId+'" width="100" height="100"></canvas></div><p class="time">'+set_date['time']+'</p></div>';
                    }else{
                        chat_text = '<div class="chat" id="'+ chatsent_id +'"><div class="'+lr_class+'">' + value.chatMessage + '</div><p class="time">'+set_date['time']+'</p></div>';
                    }
                    $("#chat_room").prepend(chat_text);
                    if(chatMessageCode === ChatMessageCode.FILE){
                        const response = await CommonUtil.draw(value.chatImgMessageId, value.chatImg);
                        if(!response) {
                            drawErrFlg = !response;
                        }
                    }                

                    // 日付表示　同じ日付だったら一度消してもう一度上につける
                    const set_pre_date = set_date['date'];
                    if(set_pre_date === pre_date ){
                        var del_id = "#chatDate_"+pre_date;
                        $(del_id).remove();
                    }
                    pre_date = set_pre_date;
                    const timedate = '<p class="chatDate" id="chatDate_'+set_pre_date+'"><span>' + convTimeToDays(send_time) + '</span></p>';
                    $("#chat_room").prepend(timedate);

                    // 初期に読み込んだ日が今日かどうかの判定のための設定
                    if(fast_log_date === ""){
                        fast_log_date = set_pre_date;
                    }

                    const elementHeight = $( "#"+chatsent_id )[0].clientHeight; //メッセージの高さを取得

                    const elementMarginBottom =  StyleUtil.getStyleNumber(chatsent_id, "margin-bottom");//メッセージのstyleからmargin-bottomを取得
                    const elementMarginTop =  StyleUtil.getStyleNumber(chatsent_id, "margin-top");//メッセージのstyleからmargin-topを取得

                    set_height += (elementHeight + elementMarginBottom + elementMarginTop);
                }

                if(drawErrFlg){
                    //ファイル読み込みエラー,アラートダイアログ表示
                    ModalDialog(AlertMessages.RSEWS_ER_92310);
                }

                $( "#chat_room" ).scrollTop( set_height ); //要素追加前の位置にスクロールを移動させる
                
                // 既読情報更新
                readyMessageInfo(latestMessageId.current, earliestMessageId.current).then(
                    function(){                        
                        CommonUtil.setIndicator(false);
                        resolve(null);
                    }
                )
                .catch(function(error: any){
                    CommonUtil.setIndicator(false);
                    reject(error);
                });

            })
            .catch(function(error: any){
                CommonUtil.setIndicator(false);
                reject(error);            
            });        
        });
    }


    function chatLoad(messageId: string, pollFlg: boolean) {
        
        if (processing.current) {            
            return new Promise((resolve, reject) => {                   
                resolve(true);
            });
        }
        
        processing.current = true;
        CommonUtil.setChatIndicator(true);
        return new Promise((resolve, reject) => {   
            getLastMessageInfo(messageId).then(
                async function(resdata: any){

                    const res_data = resdata.messages;
                    if(resdata.latestMessageId != null && resdata.latestMessageId !== ""){
                        // 最新メッセージID
                        latestMessageId.current = resdata.latestMessageId;
                    }

                    const scrollTop = $("#chat_room" ).scrollTop() ?? 0;                    //現在のスクロール位置の上端
                    const scrollBottom = $( "#chat_room" )[0].clientHeight + scrollTop;     //現在のスクロール位置の下端(チャット領域高 + 上端)
                    const bottomFlg = $( "#chat_room" )[0].scrollHeight === scrollBottom;   //現在のスクロール位置の下端が最下部かどうか

                    let drawErrFlg = false;
                    for(let i=0; i<res_data.length; i++){
                        const value = res_data[i];
                        //取得済みのメッセージか存在確認
                        if(messageIdList.current.includes(value.messageUniqId)) {                    
                            continue;
                        }
                        messageIdList.current.push(value.messageUniqId);

                        const send_time = value.sent;
                        // オペレーターかユーザかの判断と出力先設定および、出力重複確認
                        var lr_class = "right";
                        if(value.userId === OperatorUser.USER_ID){
                            lr_class = "left";
                        }
                        const set_date = convTimeToDateTime(send_time);

                        // 日付が変わったら日付表示
                        const set_pre_date = set_date['date'];
                        if(set_pre_date !== fast_log_date){
                            const timedate = '<p class="chatDate"><span>' + convTimeToDays(send_time) + '</span></p>';
                            $("#chat_room").append(timedate);
                            fast_log_date = set_pre_date;
                        }

                        var chatsent_id = "chatsent"+value.sent;
                        var chatMessageCode = value.chatMessageCode;

                        var chat_text = "";

                        if(chatMessageCode === ChatMessageCode.FILE){
                            chat_text = '<div class="chat" id="'+ chatsent_id +'"><div class="'+lr_class+'"><canvas class="chat_img chatImgStyle" id="'+value.chatImgMessageId+'" width="100%" height="100%"></canvas></div><p class="time">'+set_date['time']+'</p></div>';
                        }else{
                            chat_text = '<div class="chat" id="'+ chatsent_id +'"><div class="'+lr_class+'">' + value.chatMessage + '</div><p class="time">'+set_date['time']+'</p></div>';
                        }
                        
                        $("#chat_room").append(chat_text);

                        if(chatMessageCode === ChatMessageCode.FILE){
                            const response = await CommonUtil.draw(value.chatImgMessageId, value.chatImg);
                            if(!response) {
                                drawErrFlg = !response;
                            }
                        }                         

                        const scrollToElm = $( "#"+chatsent_id ).parent().position().top; //all-contentからの距離を取得
                        const buttonHeight = $("#chat_window").position().top; //message-bodyからの距離を取得
                        const nowScrollHeight = $( "#chat_room" ).scrollTop() || 0;  //現在のスクロールの距離を取得
                        var set_hight = scrollToElm - buttonHeight + nowScrollHeight;
                        $( "#chat_room" ).scrollTop( set_hight ); //要素追加前の位置にスクロールを移動させる
                    }

                    if(drawErrFlg){
                        //ファイル読み込みエラー,アラートダイアログ表示
                        ModalDialog(AlertMessages.RSEWS_ER_92310);
                    }
                    
                    //下記の想定で最下部にスクロールさせる
                    //ポーリング時に最下部以外に位置していた場合は、位置補正せず現在位置のまま保持
                    //ポーリング時に最下部に位置していた場合は、新規メッセージ・画像下に移動(最下部を保持)
                    //メッセージ・画像送信時は現在位置によらず最下部に移動
                    if(!pollFlg || bottomFlg){
                        $( "#chat_room" ).scrollTop($( "#chat_room" )[0].scrollHeight);
                    } 

                    // 既読情報更新
                    readyMessageInfo(latestMessageId.current, earliestMessageId.current).then(
                        function(){
                            processing.current = false;
                            CommonUtil.setChatIndicator(false);
                            resolve(null);
                        }
                    )
                    .catch(function(error: any){
                        processing.current = false;
                        CommonUtil.setChatIndicator(false);
                        reject(error);
                    });               
                }
            )
            .catch(function(error: any){
                processing.current = false;
                CommonUtil.setChatIndicator(false);
                reject(error);
            });
        });
    }
    
    function sendImage(event:any) { 

        setIsCheckedCamera(!isCheckedCamera);
        setIsCheckedPicture(!isCheckedPicture);
        setIsCheckedText(!isCheckedText);

        const orderNum = reliefInfo['orderNum'] || "";

        var file = $(event).prop('files')[0]; //ファイルデータを取得
        console.log(file);
        console.log(file.size);
        var fileSize = file.size;

        var reader = new FileReader();
        
        let imageData = null;
        CommonUtil.setIndicator(true);
        reader.onload = function() {
            imageData = reader.result;

            if(imageData != null){
                var fd = new FormData();
                fd.append('image',file);
                fd.append('chatroomId', orderNum);
                console.log(fd);

                sendImageInfo(fd,fileSize).then(
                    function(){
                        setIsCheckedCamera(isCheckedCamera);
                        setIsCheckedPicture(isCheckedPicture);
                        setIsCheckedText(isCheckedText);

                        CommonUtil.setIndicator(false);

                        chatLoad(latestMessageId.current, false)
                        .then(function(resdata: any){
                        })
                        .catch(function(response: any){
                            PagingUtil.resetAndNext(ErrorTransition(response), navigate);
                        })
                    }
                )
                .catch(function(error: any){
                    ModalDialog(AlertMessages.RSEWS_ER_92330);

                    setIsCheckedCamera(isCheckedCamera);
                    setIsCheckedPicture(isCheckedPicture);
                    setIsCheckedText(isCheckedText);
                    CommonUtil.setIndicator(false);
                });
            }else{
                ModalDialog(AlertMessages.RSEWS_ER_92320);

                setIsCheckedCamera(isCheckedCamera);
                setIsCheckedPicture(isCheckedPicture);
                setIsCheckedText(isCheckedText);
                CommonUtil.setIndicator(false);
            }
        }
        reader.onerror = (function () {
            //ファイル読み込みエラー
            //アラートダイアログ表示
            ModalDialog(AlertMessages.RSEWS_ER_92310);
            CommonUtil.setIndicator(false);
            return;
        });

        reader.readAsBinaryString(file);
        
        //ファイル読み込み後にリセットする
        $(event).val("");       
    }

    // ボタン押したときの処理
    function sendMessage(e: { preventDefault: () => void; }) {
        e.preventDefault();

        const trimText = text.trim();
        //テキスト未入力、もしくはスペースのみの場合は何もしない
        if(trimText === ""){
            setMessage(ChatMessages.RSEWS_V23_M001);
            return;
        }
        else if(trimText.length > 200){
            setMessage(ChatMessages.RSEWS_V23_M002);
            return;
        }

        setMessage('');
        setIsCheckedCamera(!isCheckedCamera);
        setIsCheckedPicture(!isCheckedPicture);
        setIsCheckedText(!isCheckedText);

        console.log(trimText);
        CommonUtil.setIndicator(true);
        sendMessageInfo(trimText).then(
            function(){
                //送信完了したらテキストクリア
                setText("");
                setIsCheckedCamera(isCheckedCamera);
                setIsCheckedPicture(isCheckedPicture);
                setIsCheckedText(isCheckedText);
                
                CommonUtil.setIndicator(false);

                //送信したメッセージの再取得
                chatLoad(latestMessageId.current, false)
                .then(function(resdata: any){
                })
                .catch(function(response: any){
                    PagingUtil.resetAndNext(ErrorTransition(response), navigate);
                })
            }
        )
        .catch(function(error: any){
            PagingUtil.resetAndNext(ErrorTransition(error), navigate);
            CommonUtil.setIndicator(false);
        });

    }

    //ヘッダーの戻るボタン押下時の処理
    function backClick(){
        if(PagingUtil.isBeforePage("/receptionstatus")){
            PagingUtil.back(navigate);
        }
        else{
            PagingUtil.backAndNext("/receptionstatus", navigate);
        }
    }

// 画面設定
    return (
<div>

<HelmetProvider>
    <Helmet>
            <title>チャット | JAF救援要請ウェブサイト</title>
    </Helmet>
</HelmetProvider>

<Header back receptionStatus orderConfirmAfter chat={false} logout onBackClick={backClick} pageNavigator={props.pageNavigator}/>
<div className="contentsWrapper">
<div className="contents privacyWrapper" id="chat_window">
            <div id='chat_room' className='chatContent'>
</div>

<form onSubmit={sendMessage}>
<div className="chatenter">
<label htmlFor="camera" className="camera">
<input type="file" accept={"."+ImageSetting.ALLOW_EXTS.join(",.")} capture="environment" id="camera" className="icon_camera" />
</label>
<label htmlFor="chatpicture" className="chatpicture"></label>
<input type="file" className="picture" id="chatpicture" accept={"."+ImageSetting.ALLOW_EXTS.join(",.")} />
<input type="text" onChange={(e) => setText(e.target.value)} value={text} placeholder="メッセージを入力してください。" />
<input type="submit" value="送信" />
</div>
<div>
{message.length > 0 && <p className="cmt" style={errorStyle}>{message}</p>}
<p className="cmt format" style={textIndent}>※お使いの機種によっては、アップロードできない場合がございます。</p>
<p className='cmt format'>アップロードできるファイル形式は {ImageSetting.ALLOW_FILE_TYPES.join("/")}です。</p>
</div>
</form>




</div>
</div>

<Footer display={displayFooter}/>
<Cngestion />
</div>
    )
}
 
export default Chat
