import axios from 'axios';

import SessionStorage from '../common/SessionStorage';
import * as Modal from '../common/Modal';
import { AlertMessages } from '../messages';
import { Version, ErrorReason } from '../constants';

let reloading:boolean = false;

/**
 * SPAのバージョン情報を管理するクラス。
 */
class VersionInfo extends SessionStorage {
    static readonly NAME:string = "version";

    /**
     * コンストラクタ。
     */
    constructor() {
        super(VersionInfo.NAME);
    }

    /**
     * バージョンチェック済みかどうかを設定する。
     *
     * @param {boolean} value バージョンチェック済みかどうか。<br>
     *                        true: チェック済み、false: 未チェック。
     */
    set checked(value:boolean) {
        this.set("checked", value as boolean|null);
    }

    /**
     * バージョンチェック済みかどうかを取得する。
     *
     * @return {boolean} バージョンチェック済みかどうか。<br>
     *                   true: チェック済み、false: 未チェック。
     */
    get checked():boolean {
        return this.getBoolean("checked", false);
    }

    /**
     * バージョンがチェック済みかどうかをSessionStorageに保存する。
     *
     * @param {boolean} checked バージョンがチェック済みかどうか。
     */
    static setChecked(checked:boolean):void {
        const info = new VersionInfo();
        info.checked = checked;
        info.save();
    };

    /**
     * バージョンがチェック済みかどうかを確認する。
     *
     * @return {boolean} バージョンがチェック済みかどうか。<br>
     *                   true: チェック済み、false: 未チェック。
     */
    static isChecked():boolean {
        const info = new VersionInfo();
        return info.checked;
    }
}

/**
 * 検査結果。
 */
const Result = {
    AVAILABLE: "0",
    RELOAD:    "1",
    ERROR:     "2",
};

/**
 * バージョンファイルをサーバからダウンロードし、現在のバージョンと一致するかどうかを確認する。
 *
 * @return {Promise<Result>} 成功時には検査結果を返し、失敗時にはダウンロードの応答エラーを返すPromiseを返却する。
 */
export const check = ():Promise<string> => {
    return new Promise((resolve, reject) => {
        const hash = new Date().getTime();
        axios.get(Version.FILE + "?" + hash).then((response) => {
            const version = response.data.version;
            console.log(`version=${version} (old=${Version.CODE})`);

            let result = Result.ERROR;

            if (version === Version.CODE) {
                console.log("version matched");
                result = Result.AVAILABLE;
                VersionInfo.setChecked(false);
            } else {
                if (VersionInfo.isChecked()) {
                    console.log("version is already checked, but version mismatched");
                    result = Result.ERROR;
                } else {
                    console.log("version mismatched");
                    result = Result.RELOAD;
                }
                VersionInfo.setChecked(true);
            }

            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
};

type Callback = () => void;
type Error = (error:any) => void;

/**
 * バージョンチェックを行い処理の続行可否を判断する。
 *
 * @param {Callback} success バージョンチェック成功時に呼び出すコールバック関数。
 * @param {Error}    failure バージョンチェック成功時に呼び出すコールバック関数。
 */
export const validate = (success:Callback, failure:Error):void => {
    check().then((result:string) => {
        switch (result) {
            case Result.AVAILABLE:
                success();
                break;
            case Result.RELOAD:
                Modal.cleanPage(true);
                Modal.ModalDialog(AlertMessages.RSEWS_ER_9997, () => {
                    console.log("reload for version mismatch");
                    reloading = true;
                    // リロードするので後続の処理が動作しないよう通知しない
                    window.location.reload();
                });
                break;
            case Result.ERROR:
            default:
                failure(ErrorReason.VERSION_MISMATCHED);
                break;
        }
    }).catch((error:any) => {
        failure(error);
    });
};

/**
 * バージョンチェックによるリロード動作中かどうかを確認する。
 *
 * @return {boolean} バージョンチェックによるリロード動作中かどうか。<br>
 *                   true: リロード動作中。<br>
 *                   false: リロード非動作中。
 */
export const isReloading = () => {
    return reloading;
};
