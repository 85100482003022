
import React from 'react'
import { useEffect, useState , useRef} from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import $ from 'jquery';

import { ModalDialog } from '../common/Modal';
import { map_box, ErrorReason } from '../constants';
import { OrderCarryMessages, AlertMessages } from '../messages';

import * as MapBox from '../mapbox';
import * as CommonUtil from '../common/CommonUtil';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Cngestion } from '../header/Congestion';
import { ErrorTransition } from '../common/ErrorUtil';
import { EntryInfo, ReliefInfo } from "../types/reliefInfo";
import GeoLocation from "../common/GeoLocation"
import { Location } from "../types/location"
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';

const errorStyle = {
    color: "red",
};

function OrderCarry(props:PageProps) {

    const getReliefInfo = sessionStorage.getItem('reliefInfo');
    var reliefInfo: ReliefInfo = (getReliefInfo === undefined || getReliefInfo === null) ? "" : JSON.parse(getReliefInfo);

    /* state変数を定義する↓ */
    const [disabled, setDisabled] = useState(reliefInfo?(reliefInfo['entryInfo'] ? false : true) : false);

    // バリデーションエラー表示用State
    const [carryInputLimit, setCarryInputLimit] = useState(false);

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    //多重押下対策
    const processing = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();

    //スクロール対象定義
    const scrollTop = useRef<HTMLDivElement>(null);

    // 初期処理
    useEffect(() => {        
        const initResult =CommonUtil.initialize(true, true, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }
        const addressInfo = reliefInfo['reliefAddressInfo']

        CommonUtil.getOrderControl(true,true,addressInfo['prefectureCode']).then(function(response: any){ 
            //救援要請制御ダイアログかどうか
            if(response){
                PagingUtil.resetAndNext(ErrorTransition(ErrorReason.ORDER_CONTROL_ERROR), navigate);
                return;
            }
            getPosition();
        })
        .catch(function(response: any){
            PagingUtil.resetAndNext(ErrorTransition(response), navigate);
        })

        function getPosition(){
            // 位置情報パーミッション判定
            GeoLocation.getCurrentPosition().then((location:Location) => {
                CommonUtil.setIndicator(false);
                if (!location.latitude || !location.longitude) {
                    // パーミッション拒否
                    CommonUtil.displayFngestionFbar();
                }
                setDisplay();
            });
        }
        
        //画面の選択項目を生成
        const setDisplay = () => {
            //入力内容をセットする。
            if(!(reliefInfo['entryInfo'] === undefined)) {
                let entryInfo:EntryInfo = reliefInfo['entryInfo'];

                $('#spot').val(entryInfo['entryPlaceName']?entryInfo['entryPlaceName']:"");
            }

            setTimeout(function(){
                MapBox.deleteMap();
                MapBox.displayMap(map_box.MAP_STYLE, false, null,null,"marker_jaf");
            },100);
        }
        setDisplayFooter(true);
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

    // 画面遷移
    let transitionDestination = () => {
        processing.current = false;
        PagingUtil.back(navigate);
    }

    // 確定するボタン押下
    const confirmClick = () => {
        if (processing.current) return;
        processing.current = true;

        setCarryInputLimit(false);
        let trimSpot:any = $("#spot").val();
        trimSpot = trimSpot.trim();
        $('#spot').val(trimSpot);
        if(trimSpot.length > 40) {
            setCarryInputLimit(true);
            processing.current = false;
            scrollTop?.current?.scrollIntoView({behavior: "smooth"});
            return;
        }

        // マーカーの中心位置からお運び先情報を取得
        CommonUtil.getEntryAddressInfo(reliefInfo).then((response) => {
            if (response) {
                const orderEntryInfo = {
                    'entryPlaceLongitude': response.entryPlaceLongitude,
                    'entryPlaceLatitude': response.entryPlaceLatitude,
                    'geocodeEntryPlaceAddress': response.geocodeEntryPlaceAddress,
                    'prefectureCode': response.prefectureCode,
                    'entryPlaceName': trimSpot,
                };
                reliefInfo = {...reliefInfo, ...{'entryInfo' : orderEntryInfo}};
                sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));
                transitionDestination()
            } else {
                ModalDialog(AlertMessages.RSEWS_ER_91510);
                processing.current = false;
            }
        });
    }

    // マップクリック時の処理
    let clickMap = (event:any) => {
        //クリック箇所の判定
        if(!MapBox.checkMapClick(event.target))
        {
            return;
        }
        else{
            setDisabled(false)
        }
    }

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>お運び先入力 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>

            <Header back pageNavigator={props.pageNavigator}/>
            <div ref={scrollTop} className="contentsWrapper">
                <div className="contents">
                    <ul className="status">
                        <li>救援場所入力</li>
                        <li>お客様情報入力</li>
                        <li className="current">トラブル内容入力</li>
                        <li>内容確認</li>
                        <li>登録完了</li>
                    </ul>
                </div>
            </div>
            <div className="contentsWrapper top0">
                <div className="contents height_full">
                    <p className="general bold">
                        お運び先にピンを合わせてください。
                    </p>
                    <div className="mapWrapper">
                        <div id="map"className="map cross" onClick={clickMap}>
                        </div>
                        <div className="buttonWrapper">
                            <div id="area_info" className="select nomust">
                                <textarea name="" id="spot" placeholder="お運び先店舗名を記入してください。"></textarea>
                            { carryInputLimit && (
                                <p className="cmt" style={errorStyle}>
                                    {OrderCarryMessages.RSEWS_V15_M002}
                                </p>
                            )}
                            </div>
                            <div className="column2">
                                <button className={disabled ? "action arrow gray" : "action arrow"} disabled={disabled} onClick={confirmClick}>確定する</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer display={displayFooter}/>
            <Cngestion />
        </div>
    )
}

export default OrderCarry
