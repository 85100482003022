import SessionStorage from './SessionStorage';
import * as MstInfo from '../types/mstInfo';

const NAME:string = "mstInfo";

/**
 * マスタ情報を管理するクラス。
 */
export default class MasterInfo extends SessionStorage {
    /**
     * コンストラクタ。
     */
    constructor() {
        super(NAME);
    }

    /**
     * 汎用マスタリストを取得する。
     *
     * @return {Array<MstInfo.GeneralInfo>} 汎用マスタリスト。
     */
    get generalInfoList():Array<MstInfo.GeneralInfo> {
        return this.get("generalInfoList", []) as Array<MstInfo.GeneralInfo>;
    }

    /**
     * 都道府県マスタリストを取得する。
     *
     * @return {Array<MstInfo.PrefectureInfo>} 都道府県マスタリスト。
     */
    get prefectureInfoList():Array<MstInfo.PrefectureInfo> {
        return this.get("prefectureInfoList", []) as Array<MstInfo.PrefectureInfo>;
    }

    /**
     * トラブル内容マスタリストを取得する。
     *
     * @return {Array<MstInfo.OrderInfoList>} トラブル内容マスタリスト。
     */
    get orderInfoList():Array<MstInfo.OrderInfoList> {
        return this.get("orderInfoList", []) as Array<MstInfo.OrderInfoList>;
    }

    /**
     * ご希望内容マスタリストを取得する。
     *
     * @return {Array<MstInfo.OrderContentInfoList>} ご希望内容マスタリスト。
     */
    get orderContentInfoList():Array<MstInfo.OrderContentInfoList> {
        return this.get("orderContentInfoList", []) as Array<MstInfo.OrderContentInfoList>;
    }

    /**
     * 車両区分マスタリストを取得する。
     *
     * @return {Array<MstInfo.VehicleInfo>} 車両区分マスタリスト。
     */
    get vehicleInfoList():Array<MstInfo.VehicleInfo> {
        return this.get("vehicleInfoList", []) as Array<MstInfo.VehicleInfo>;
    }

    /**
     * 車両メーカーマスタリストを取得する。
     *
     * @return {Array<MstInfo.CarMakerInfoList>} 車両メーカーマスタリスト。
     */
    get carMakerInfoList():Array<MstInfo.CarMakerInfoList> {
        return this.get("carMakerInfoList", []) as Array<MstInfo.CarMakerInfoList>;
    }

    /**
     * 車色マスタリストを取得する。
     *
     * @return {Array<MstInfo.CarNameInfoList>} 車色マスタリスト。
     */
    get carNameInfoList():Array<MstInfo.CarNameInfoList> {
        return this.get("carNameInfoList", []) as Array<MstInfo.CarNameInfoList>;
    }

    /**
     * 車両通称名マスタリストを取得する。
     *
     * @return {Array<MstInfo.CarColorInfoList>} 車両通称名マスタリスト。
     */
    get carColorInfoList():Array<MstInfo.CarColorInfoList> {
        return this.get("carColorInfoList", []) as Array<MstInfo.CarColorInfoList>;
    }

    /**
     * 車両登録地マスタリストを取得する。
     *
     * @return {Array<MstInfo.CarNumberPlaceInfoList>} 車両登録地マスタリスト。
     */
    get carNumberPlaceInfoList():Array<MstInfo.CarNumberPlaceInfoList> {
        return this.get("carNumberPlaceInfoList", []) as Array<MstInfo.CarNumberPlaceInfoList>;
    }

    /**
     * キャンセル理由マスタリストを取得する。
     *
     * @return {Array<MstInfo.CancelReasonInfo>} キャンセル理由マスタリスト。
     */
    get cancelReasonInfoList():Array<MstInfo.CancelReasonInfo> {
        return this.get("cancelReasonInfoList", []) as Array<MstInfo.CancelReasonInfo>;
    }

    /**
     * プッシュ通知用認証キーマスタリストを取得する。
     *
     * @return {Array<MstInfo.PushKeyInfo>} プッシュ通知用認証キーマスタリスト。
     */
    get pushKeyInfoList():Array<MstInfo.PushKeyInfo> {
        return this.get("pushKeyInfoList", []) as Array<MstInfo.PushKeyInfo>;
    }

    /**
     * 道路場所マスタリストを取得する。
     *
     * @return {Array<MstInfo.RoadLocationInfoList>} 道路場所マスタリスト。
     */
    get roadLocationInfoList():Array<MstInfo.RoadLocationInfoList> {
        return this.get("roadLocationInfoList", []) as Array<MstInfo.RoadLocationInfoList>;
    }

    /**
     * 地図サービスキーマスタリストを取得する。
     *
     * @return {Array<MstInfo.MapKeyInfo>} 地図サービスキーマスタリスト。
     */
    get mapKeyInfoList():Array<MstInfo.MapKeyInfo> {
        return this.get("mapKeyInfoList", []) as Array<MstInfo.MapKeyInfo>;
    }

    /**
     * マスタ情報を更新する。
     *
     * @param {any} data マスタ情報。<br>
     *                   APIサーバから取得したマスタ情報の応答データ。
     */
    update(data:any):void {
        for (let key in data) {
            if (data[key].length > 0) {
                this._data = {
                    ...this._data,
                    ...{
                        [key]: data[key]
                    },
                };
            }
        }
    }

    /**
     * SessionStorageから項目を削除する。
     */
    static clean() {
        SessionStorage.clear(NAME);
    }
}
