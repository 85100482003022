

//ハッシュ化関数を定義
  export function async_digestMessage(message) {
    return new Promise(function(resolve){
        var msgUint8 = new TextEncoder("utf-8").encode(message);
        crypto.subtle.digest('SHA-256', msgUint8).then(
            function(hashBuffer){
                var hashArray = Array.from(new Uint8Array(hashBuffer));
                var hashHex = hashArray.map(function(b){return b.toString(16).padStart(2, '0')}).join('');
                return resolve(hashHex);
            });
        })
    }


    