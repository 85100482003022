import { api_url } from '../../constants';
import { CertificationInfoRequest, CertificationInfoResponse } from '../../types/certificationInfo';
import { ApiGet_Authorization } from '../ApiGet';
import { ApiPost_Authorization } from '../ApiPost';
import { ApiDef, validateResponse } from './common';
import Url from '../../common/Url';

type Callback = (response:CertificationInfoResponse) => void;
type RegisterCallback = (hash:string) => void;
type Error = (error:any) => void;

/**
 * URLパラメータ認証用一時情報を登録する。
 *
 * @param {CertificationInfoRequest} info    URLパラメータ認証用情報登録情報。
 * @param {RegisterCallback}         success 成功時に呼び出されるコールバック関数。
 * @param {Error}                    failure 失敗時に呼び出されるコールバック関数。
 */
export const registerCertificationInfo = (info:CertificationInfoRequest, success:RegisterCallback, failure:Error):void => {
    const URL = api_url.API_URL + "certificationInfo";

    const config = {
        headers:{
            "Content-Type": "application/json; charset=utf-8",
        }
    };

    ApiPost_Authorization(URL, info, config, (response:any) => {
        console.log('URLパラメータ認証用情報登録成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("hashValue", "string", 64, true),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data.hashValue);
        } else {
            failure(reason);
        }
    }, failure);
};

/**
 * URLパラメータ認証用一時情報を取得する。
 *
 * @param {string}   hash    ハッシュ値。
 * @param {Callback} success 成功時に呼び出されるコールバック関数。
 * @param {Error}    failure 失敗時に呼び出されるコールバック関数。
 */
export const getCertificationInfo = (hash:string|null, success:Callback, failure:Error):void => {
    const url:Url = new Url(api_url.API_URL + "certificationInfo");
    url.addString("hashValue", hash);

    const config = {
        headers:{}
    };

    ApiGet_Authorization(url.create(), config, (response:any) => {
        console.log('URLパラメータ認証用情報取得成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("mailAddress",    "string", 256, true),
            new ApiDef("inflowCode",     "string", 10,  true),
            new ApiDef("transitionCode", "string", 10,  true),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};
