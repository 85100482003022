// 初期設定
import React from "react";
import { TransitionCode, OrderFlag } from '../constants';
import { useNavigate , useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { validateMailAddress } from "../common/api/mailValidate"
import { getCertificationInfo } from "../common/api/certificationInfo"
import { registerUserInfo, getUserInfo } from "../common/api/userInfo"
import { MailValidateInfo } from '../types/mailValidateInfo';
import { CertificationInfoResponse } from '../types/certificationInfo';
import { UserInfoResponse } from '../types/userInfo';
import { ErrorTransition } from '../common/ErrorUtil';
import { QueryParameter, ErrorReason } from '../constants';
import *  as CommonUtil from '../common/CommonUtil';
import LoginUserInfo from '../common/LoginUserInfo';
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';
import * as MasterInfoGet from "../masterInfoGet";
import { Header } from '../header/Header';

function Regist(props:PageProps) {

    const navigate = useNavigate();
    const location = useLocation();
    const search = useLocation().search;

    React.useEffect(() => {
        
        const initResult = CommonUtil.initialize(true, true, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }
        
        // URLパラメータ取得
        const query = new URLSearchParams(search);
        const registHash = query.get(QueryParameter.PARAMETER_HASH);

        CommonUtil.setIndicator(true);

        getCertificationInfo(registHash, function (info:CertificationInfoResponse) {
            const user_email = info.mailAddress;
            const user_transitionCode = info.transitionCode;
            const user_inflowCode = info.inflowCode;

            validateMailAddress(user_email, function (info:MailValidateInfo) {
                //救援要請中ではない場合
                if (info.isOrder === OrderFlag.UNREGISTERED) {
                    //利用者情報登録
                    const data = { mailAddress: user_email, inflowCode: user_inflowCode };
                    registerUserInfo(data, function (info_response:UserInfoResponse) {
                        saveLoginUserInfo(info_response.userId, info_response.accessToken);
                        getMasterInfo(info.isOrder, user_transitionCode);
                    }, function (info_error:any) {
                        PagingUtil.resetAndNext(ErrorTransition(info_error), navigate);
                    });
                }
                else{
                    //利用者情報取得
                    getUserInfo(user_email, function (info_response:UserInfoResponse) {
                        saveLoginUserInfo(info_response.userId, info_response.accessToken);
                        getMasterInfo(info.isOrder, user_transitionCode);
                    }, function (info_error:any) {
                        PagingUtil.resetAndNext(ErrorTransition(info_error), navigate);
                    });
                }
            }, function (chk_error:any) {
                PagingUtil.resetAndNext(ErrorTransition(chk_error), navigate);
            });
        }, function (error:any) {
            PagingUtil.resetAndNext(ErrorTransition(error), navigate);
        });

        //利用者情報保存
        function saveLoginUserInfo(userId:string, accessToken:string) {
            const loginUserInfo:LoginUserInfo = new LoginUserInfo();
            loginUserInfo.userId = userId;
            loginUserInfo.accessToken = accessToken;
            loginUserInfo.save();
        }

        //マスタ情報取得
        function getMasterInfo(isOrder:any, transitionCode:any) {
            MasterInfoGet.RequestMaster(function () {
                pageRedirect(isOrder, transitionCode);
            }, function (info_error:any) {
                PagingUtil.resetAndNext(ErrorTransition(info_error), navigate);
            });
        }

        //画面遷移判定処理
        function pageRedirect(isOrder:any, transitionCode:any) {
            CommonUtil.setIndicator(false);
            //救援要請中であり、次の遷移先が「チャット」の場合
            if(isOrder === OrderFlag.REGISTERING && transitionCode === TransitionCode.CHAT){
                //チャット画面に遷移
                PagingUtil.backAndNext("/chat/login", navigate);
            }
            //救援要請中の場合
            else if(isOrder === OrderFlag.REGISTERING){
                //受付状況確認画面に遷移
                PagingUtil.backAndNext("/receptionStatus", navigate);
            }
            //救援要請中ではない場合
            else if(isOrder === OrderFlag.UNREGISTERED){
                //救援場所入力画面に遷移
                PagingUtil.backAndNext("/relief", navigate);
            }
            //想定外の場合
            else{
                //不正な値が返ってきたためエラーページに遷移
                PagingUtil.backAndNext(ErrorTransition(ErrorReason.UNKWNOWN), navigate);
            }
        }
        
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

// 画面設定
    return (
<div>

<HelmetProvider>
    <Helmet>
        <title>JAF救援要請ウェブサイト</title>
    </Helmet>
</HelmetProvider>
<Header hideMenu pageNavigator={props.pageNavigator}/>

</div>
    )
}
 
export default Regist
