import { api_url } from '../../constants';
import { ApiGet_AccessToken } from '../ApiGet';
import { ApiDef, validateResponse } from './common';
import Url from '../../common/Url';

type Callback = (response:any) => void;
type Error = (error:any) => void;

const DEFS:any = {
    "m_control_center": new ApiDef("controlCenterInfoList", "object", 0, false, [
        new ApiDef("controlCenterCode", "string", 1, true),
        new ApiDef("controlCenterName", "string", 100, true),
        new ApiDef("callCenterNumber",  "string", 15, true),
        new ApiDef("notificationDistance", "string", 4, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_prefecture": new ApiDef("prefectureInfoList", "object", 0, false, [
        new ApiDef("prefectureCode", "string", 2, true),
        new ApiDef("prefectureName", "string", 100, true),
        new ApiDef("controlCenterCode", "string", 1, true),
        new ApiDef("latitude", "string", 20, true),
        new ApiDef("longitude", "string", 20, true),
        new ApiDef("areaCode", "string", 1, true),
        new ApiDef("areaName", "string", 100, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_order": new ApiDef("orderInfoList", "object", 0, false, [
        new ApiDef("orderCode", "string", 2, true),
        new ApiDef("orderName", "string", 100, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_order_content": new ApiDef("orderContentInfoList", "object", 0, false, [
        new ApiDef("orderCode", "string", 2, true),
        new ApiDef("orderContentCode", "string", 4, true),
        new ApiDef("orderContentName", "string", 100, true),
        new ApiDef("entryPlaceSelectFlg", "string", 1, true),
        new ApiDef("tireSelectFlg", "string", 1, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_vehicle": new ApiDef("vehicleInfoList", "object", 0, false, [
        new ApiDef("vehicleCode", "string", 2, true),
        new ApiDef("vehicleName", "string", 100, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_car_maker": new ApiDef("carMakerInfoList", "object", 0, false, [
        new ApiDef("carMakerCode", "string", 2, true),
        new ApiDef("carMakerName", "string", 100, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_car_name": new ApiDef("carNameInfoList", "object", 0, false, [
        new ApiDef("carMakerCode", "string", 2, true),
        new ApiDef("carNameCode", "string", 4, true),
        new ApiDef("carName", "string", 100, true),
        new ApiDef("carNameHiragana", "string", 100, true),
        new ApiDef("carNameKatakana", "string", 100, true),
        new ApiDef("vehicleCode", "string", 2, true),
        new ApiDef("twoWheelsFlg", "string", 1, true),
        new ApiDef("carOverWeightFlg", "string", 1, true),
        new ApiDef("otherCarNameFlg", "string", 1, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_car_color": new ApiDef("carColorInfoList", "object", 0, false, [
        new ApiDef("carColorCode", "string", 2, true),
        new ApiDef("carColorName", "string", 100, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_car_number_place": new ApiDef("carNumberPlaceInfoList", "object", 0, false, [
        new ApiDef("carNumberPlaceCode", "string", 4, true),
        new ApiDef("carNumberPlaceName", "string", 100, true),
        new ApiDef("carNumberPlaceHiragana", "string", 100, true),
        new ApiDef("carNumberPlaceKatakana", "string", 100, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_cancel_reason": new ApiDef("cancelReasonInfoList", "object", 0, false, [
        new ApiDef("cancelReasonCode", "string", 3, true),
        new ApiDef("cancelReasonName", "string", 100, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_general": new ApiDef("generalInfoList", "object", 0, false, [
        new ApiDef("id", "string", 10, true),
        new ApiDef("generalType", "string", 2, true),
        new ApiDef("generalKey", "string", 100, true),
        new ApiDef("generalValue", "string", 100, true),
    ]),
    "m_conversion_year": new ApiDef("conversionYearInfoList", "object", 0, false, [
        new ApiDef("japaneseCalendarCode", "string", 2, true),
        new ApiDef("japaneseCalendarName", "string", 100, true),
        new ApiDef("westernFrom", "string", 4, true),
        new ApiDef("westernTo", "string", 4, true),
    ]),
    "m_push_key": new ApiDef("pushKeyInfoList", "object", 0, false, [
        new ApiDef("id", "string", 2, true),
        new ApiDef("systemCode", "string", 1, true),
        new ApiDef("publicKeyFlg", "string", 1, true),
        new ApiDef("keyName", "string", 100, true),
        new ApiDef("keyValue", "string", 512, true),
    ]),
    "m_road_location": new ApiDef("roadLocationInfoList", "object", 0, false, [
        new ApiDef("roadLocationCode", "string", 1, true),
        new ApiDef("roadLocationName", "string", 100, true),
        new ApiDef("roadCode", "string", 1, true),
        new ApiDef("locationCode", "string", 1, true),
        new ApiDef("sortNum", "string", 5, true),
    ]),
    "m_inflow": new ApiDef("inflowInfoList", "object", 0, false, [
        new ApiDef("code", "string", 2, true),
        new ApiDef("name", "string", 100, true),
        new ApiDef("linkServiceKey", "string", 10, true),
    ]),
    "m_map_key": new ApiDef("mapKeyInfoList", "object", 0, false, [
        new ApiDef("id", "string", 1, true),
        new ApiDef("systemCode", "string", 1, true),
        new ApiDef("keyName", "string", 100, true),
        new ApiDef("keyValue", "string", 512, true),
    ]),
};

/**
 * 汎用マスタリストを取得する。
 *
 * @param {Callback}      success  成功時に呼び出されるコールバック関数。
 * @param {Error}         failure  失敗時に呼び出されるコールバック関数。
 */
export const getGeneralMasterList = (success:Callback, failure:Error):void => {
    getMasterInfo([], (response:any) => {
        success(response.generalInfoList);
    }, failure);
};

/**
 * リクエストに紐づくマスタの内容を取得する。
 *
 * @param {Array<string>} types    取得マスタ種別リスト。
 * @param {Callback}      success  成功時に呼び出されるコールバック関数。
 * @param {Error}         failure  失敗時に呼び出されるコールバック関数。
 */
export const getMasterInfo = (types:Array<string>, success:Callback, failure:Error):void => {
    types.unshift("m_general");

    const url:Url = new Url(api_url.API_URL + "mstInfo");
    types.forEach((type) => {
        url.addString("mstTypeList[]", type);
    });

    const config = {
        headers:{}
    };

    ApiGet_AccessToken(url.create(), config, (response:any) => {
        console.log('マスタ情報取得成功', response);

        const defs:ApiDef[] = [];
        types.forEach((type) => {
            if (type in DEFS) {
                defs.push(DEFS[type]);
            } else {
                console.error(`${type} is not found in definition`);
            }
        });

        const reason = validateResponse(defs, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};
