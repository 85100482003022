import { api_url } from '../../constants';
import { OrderRequest, OrderResponse } from '../../types/orderInfo';
import { ApiPost_AccessToken } from '../ApiPost';
import { ApiDef, validateResponse } from './common';

type Callback = (response:OrderResponse) => void;
type Error = (error:any) => void;

/**
 * 依頼入力時、電話依頼へ切り替えた利用者向けに依頼情報の補完を目的とした依頼情報の登録を行う。
 *
 * @param {OrderRequest} request 利用者情報登録情報。
 * @param {Callback}     success 成功時に呼び出されるコールバック関数。
 * @param {Error}        failure 失敗時に呼び出されるコールバック関数。
 */
export const registerFuzzyOrder = (request:OrderRequest, success:Callback, failure:Error):void => {
    const URL = api_url.API_URL + "fuzzyOrder";

    const config = {
        headers:{}
    };

    ApiPost_AccessToken(URL, request, config, (response:any) => {
        console.log('電話離脱時依頼内容登録成功',response);

        const DEFS:ApiDef[] = [ new ApiDef("orderNum", "string", 25, true) ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};
