import { api_url } from '../../constants';
import { OrderInfo } from '../../types/orderInfo';
import { ApiGet_AccessToken } from '../ApiGet';
import { ApiDef, validateResponse } from './common';
import Url from '../../common/Url';

type Callback = (response:OrderInfo) => void;
type Error = (error:any) => void;

/**
 * アライアンスサーバ依頼番号に紐づく依頼内容を取得する。
 *
 * @param {string}   orderNum アライアンスサーバ依頼番号。
 * @param {Callback} success  成功時に呼び出されるコールバック関数。
 * @param {Error}    failure  失敗時に呼び出されるコールバック関数。
 */
export const getOrderInfo = (orderNum:string, success:Callback, failure:Error):void => {
    const url:Url = new Url(api_url.API_URL + "orderInfo");
    url.addString("orderNum", orderNum);

    const config = {
        headers:{}
    };

    ApiGet_AccessToken(url.create(), config, (response:any) => {
        console.log('依頼情報取得成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("orderNum",                     "string", 25,  true),
            new ApiDef("ac3ReceptionNum",              "string", 10,  false),
            new ApiDef("userId",                       "string", 11,  true),
            new ApiDef("controlCenterCode",            "string", 1,   true),
            new ApiDef("areaCode",                     "string", 1,   true),
            new ApiDef("userNameKanji",                "string", 25,  true),
            new ApiDef("userNameKana",                 "string", 25,  true),
            new ApiDef("memberFlg",                    "string", 1,   true),
            new ApiDef("membershipCardFlg",            "string", 1,   true),
            new ApiDef("membershipNum",                "string", 12,  false),
            new ApiDef("membershipSsoFlg",             "string", 1,   false),
            new ApiDef("telNum",                       "string", 15,  true),
            new ApiDef("bkTelNum",                     "string", 15,  false),
            new ApiDef("arrangementCode",              "string", 1,   true),
            new ApiDef("orderCode",                    "string", 2,   true),
            new ApiDef("orderContentCode",             "string", 4,   true),
            new ApiDef("prefectureCode",               "string", 2,   true),
            new ApiDef("cityCode",                     "string", 3,   true),
            new ApiDef("manualReliefAddress",          "string", 60,  false),
            new ApiDef("reliefLocationSupplementInfo", "string", 100, false),
            new ApiDef("latitude",                     "string", 20,  true),
            new ApiDef("longitude",                    "string", 20,  true),
            new ApiDef("geocodeReliefAddress",         "string", 255, false),
            new ApiDef("manualInputFlg",               "string", 1,   true),
            new ApiDef("carMakerCode",                 "string", 2,   true),
            new ApiDef("vehicleCode",                  "string", 2,   true),
            new ApiDef("carColorCode",                 "string", 2,   false),
            new ApiDef("carNameCode",                  "string", 4,   true),
            new ApiDef("licencePlatePlace",            "string", 4,   false),
            new ApiDef("licencePlateClassification",   "string", 3,   false),
            new ApiDef("licencePlateDistinction",      "string", 1,   false),
            new ApiDef("licencePlateNumber",           "string", 5,   false),
            new ApiDef("carWeight",                    "string", 5,   false),
            new ApiDef("receptionStatus",              "string", 1,   true),
            new ApiDef("ac3ReceptionDt",               "string", 19,  false),
            new ApiDef("operationDt",                  "string", 19,  false),
            new ApiDef("arrivalScheduleDt",            "string", 19,  false),
            new ApiDef("exceptionArrivalScheduleDt",   "string", 3,   false),
            new ApiDef("arrivalDt",                    "string", 19,  false),
            new ApiDef("completeDt",                   "string", 19,  false),
            new ApiDef("reliefReservationDt",          "string", 19,  false),
            new ApiDef("reliefChangeDt",               "string", 19,  false),
            new ApiDef("reliefCancelDt",               "string", 19,  false),
            new ApiDef("cancelReasonCode",             "string", 3,   false),
            new ApiDef("cancelReason",                 "string", 50,  false),
            new ApiDef("specialNote",                  "string", 512, false),
            new ApiDef("memo",                         "string", 512, false),
            new ApiDef("driveCode",                    "string", 1,   true),
            new ApiDef("urgentArrangementCode",        "string", 3,   false),
            new ApiDef("towHopeFlg",                   "string", 1,   true),
            new ApiDef("locationCode",                 "string", 1,   true),
            new ApiDef("roadCode",                     "string", 1,   false),
            new ApiDef("entryPlaceLatitude",           "string", 20,  false),
            new ApiDef("entryPlaceLongitude",          "string", 20,  false),
            new ApiDef("geocodeEntryPlaceAddress",     "string", 100, false),
            new ApiDef("entryPlaceName",               "string", 40,  false),
            new ApiDef("additionalInfo1",              "string", 1,   false),
            new ApiDef("additionalInfo2",              "string", 1,   false),
            new ApiDef("additionalInfo3",              "string", 1,   false),
            new ApiDef("additionalInfo4",              "string", 1,   false),
            new ApiDef("additionalInfo5",              "string", 1,   false),
            new ApiDef("additionalInfo6",              "string", 1,   false),
            new ApiDef("inflowCode",                   "string", 10,  false),
            new ApiDef("insDt",                        "string", 19,  false),
            new ApiDef("updDt",                        "string", 19,  false),
            new ApiDef("image",                        "string", 0,   false),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};
