import axios from 'axios';
import crypto from 'crypto-js';
import { ApiSetting } from '../constants';
import { requestApi } from './ApiCommon';
import { getToken } from '../common/Secret';
import { getServiceInfo } from '../common/api/service';
import { getAccessToken } from '../common/api/token';
import { login } from '../common/api/chat/login';
import { ServiceInfo } from '../types/serviceInfo';
import { ChatLoginResponse } from '../types/chatInfo';
import LoginUserInfo from './LoginUserInfo';


/**
 * GET要求を行う。
 *
 * @param {string} url     APIのURL。
 * @param {any}    config  axiosの設定。
 * @param {any}    success 成功時に呼び出されるコールバック関数。
 * @param {any}    failure 失敗時に呼び出されるコールバック関数。
 */
export const ApiGet = (url:string, config:any, success:any, failure:any):void => {
    requestApi(url, config, success, failure, (url:string, config:any) => {
        console.log("request get:", url);
        return axios.get(url, config);
    }, true);
};


//署名情報を使用したAPI実行処理
export const ApiGet_Authorization = (url:string, header:any, success:any, failure:any = null):void => {
    const loginUserInfo:LoginUserInfo = new LoginUserInfo();

    ApiGet_Service(loginUserInfo, (loginUserInfo:any) => {
        const config = {
            headers: {
                ...header.headers,...{
                    "Authorization": loginUserInfo.authorization,
                    "X-Service-Key": ApiSetting.SERVICE_KEY,
                    "X-RsRescue-Time": loginUserInfo.rescueTime,
                }
            }
        };

        ApiGet(url, config, success, failure);
    }, failure);
};

//アクセストークンを使用したAPI実行処理
export const ApiGet_AccessToken = (url:string, header:any, success:any, failure:any = null):void => {
    const loginUserInfo:LoginUserInfo = new LoginUserInfo();

    //アクセストークン発行APIの実行
    ApiGet_getAccessToken(loginUserInfo, (accessToken:any) => {
        const config = {
            headers:{
                ...header.headers,...{
                    "X-AccessToken": accessToken,
                    "X-RsRescue-Token": loginUserInfo.userId,
                }
            }
        };

        ApiGet(url, config, success, failure);
    }, failure);
};

//アクセストークンを使用したチャットAPI実行処理
export const ApiGet_ChatAccessToken = (url:string, header:any, success:any, failure:any = null):void => {
    const loginUserInfo:LoginUserInfo = new LoginUserInfo();

    //ログインAPIの実行
    ApiGet_loginChat(loginUserInfo, (accessToken:any) => {
        const config = {
            headers:{
                ...header.headers,...{
                    "X-AccessToken": accessToken,
                    "X-RsRescue-Token": loginUserInfo.userId,
                }
            }
        };

        ApiGet(url, config, success, failure);
    }, failure);
};

//サービス情報取得API実行処理
export const ApiGet_Service = (loginUserInfo:LoginUserInfo, success:any, failure:any = null):any => {
    if (loginUserInfo.secretKey && loginUserInfo.salt && loginUserInfo.rescueTime && loginUserInfo.authorization) {
        success(loginUserInfo);
        return;
    }

    //現在のUNIXタイムスタンプを取得
    const serviceKey = ApiSetting.SERVICE_KEY;
    const rescueTime = new Date().getTime().toString();

    //サービス情報取得API実行
    getServiceInfo(serviceKey, rescueTime, (response:ServiceInfo) => {
        const salt = response.salt.substr(5);
        const secret = response.secretKey.substr(5);

        //署名情報の生成
        const firstEncryption = crypto.HmacSHA256(serviceKey + salt, rescueTime);
        const secondEncryption = crypto.HmacSHA256(firstEncryption, secret);
        const authorization = 'Bearer '+secondEncryption.toString();

        //暗号化した状態のシークレットキー、ソルトを保存
        //署名情報、UNIXタイムスタンプを保存
        loginUserInfo.secretKey = response.secretKey;
        loginUserInfo.salt = response.salt;
        loginUserInfo.authorization = authorization;
        loginUserInfo.rescueTime = rescueTime;
        loginUserInfo.save();

        success(loginUserInfo);
    }, (error:any) => {
        if (failure != null) {
            failure(error);
        }
    });
};

// アクセストークン発行
export const ApiGet_getAccessToken = (loginUserInfo:LoginUserInfo, success:any, failure:any):void => {
    if (loginUserInfo.obfuscationKey && loginUserInfo.obfuscationToken) {
        //難読化キーの複合化
        const bytes  = crypto.AES.decrypt(loginUserInfo.obfuscationToken, loginUserInfo.obfuscationKey);
        const accessToken = bytes.toString(crypto.enc.Utf8);

        success(accessToken);
        return;
    }

    //アクセストークン発行APIの実行
    getAccessToken((response:string) => {
        const loginUserInfo:LoginUserInfo = new LoginUserInfo();

        //アクセストークンを保存
        loginUserInfo.accessToken = response;
        loginUserInfo.save();

        const salt = loginUserInfo.salt.substr(5);
        const secret = loginUserInfo.secretKey.substr(5);

        getToken(loginUserInfo.accessToken, secret + salt + loginUserInfo.userId).then((accessToken:any) => {
            //難読化キーの生成と難読化実行
            loginUserInfo.obfuscationKey = Math.random().toString(32).substring(2);
            loginUserInfo.obfuscationToken = crypto.AES.encrypt(accessToken, loginUserInfo.obfuscationKey).toString();
            loginUserInfo.save();

            success(accessToken);
        });
    }, failure);
};

// チャットログイン
export const ApiGet_loginChat = (loginUserInfo:any, success:any, failure:any):void => {
    if (loginUserInfo.chatObfuscationKey && loginUserInfo.chatObfuscationToken) {
        //難読化キーの複合化
        const bytes  = crypto.AES.decrypt(loginUserInfo.chatObfuscationToken, loginUserInfo.chatObfuscationKey);
        const accessToken = bytes.toString(crypto.enc.Utf8);

        success(accessToken);
        return;
    }

    let reliefInfo:any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    //ログインAPIの実行
    login(reliefInfo['orderNum'], (response:ChatLoginResponse) => {
        const loginUserInfo:LoginUserInfo = new LoginUserInfo();
        loginUserInfo.chatAccessToken = response.accessToken;
        loginUserInfo.save();

        const salt = loginUserInfo.salt.substr(5);
        const secret = loginUserInfo.secretKey.substr(5);

        getToken(loginUserInfo.chatAccessToken, secret + salt + loginUserInfo.userId).then((accessToken:any) => {
            //難読化キーの生成と難読化実行
            loginUserInfo.chatObfuscationKey = Math.random().toString(32).substring(2);
            loginUserInfo.chatObfuscationToken = crypto.AES.encrypt(accessToken, loginUserInfo.chatObfuscationKey).toString();
            loginUserInfo.save();

            success(accessToken);
        });
    }, failure);
};
