import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import MasterInfo from "./common/MasterInfo"
import GeoLocation from "./common/GeoLocation"
import { Location } from "./types/location"
import { AddressInfo } from "./types/map"
import { MapboxApi } from "./constants"

/* eslint-disable no-undef*/
var map = [];
var marker = null;

const JAPAN_WIDE_ZOOM = 3; // 日本全域を表示する倍率


/**
 * 地図を表示する。
 *
 * @param {string}  styleURL                  StyleのURL。
 * @param {any}     center                    地図の中点座標。
 * @param {string}  containerId               地図のID。
 * @param {boolean} interactive               操作可能かどうか。
 * @param {boolean} isPositionButtonNecessary 位置情報取得ボタンの要否。
 * @param {boolean} canMoveMarker             地図クリック図にマーカーを移動するかどうか。
 * @param {string}  id                        地図コンポーネントのID。
 */
function displayMap_internal(styleURL, center, containerId, interactive, isPositionButtonNecessary, canMoveMarker, id="map") {
    //mapBoxのアクセストークンを取得
    const mstInfo:MasterInfo = new MasterInfo();

    mapboxgl.accessToken = mstInfo.mapKeyInfoList[0]['keyValue'];
    map[containerId] = new mapboxgl.Map({
        container: id, // container ID
        style: styleURL,
        center: center,
        zoom: 16, // starting zoom
        renderWorldCopies:false,
        attributionControl:true,
        minZoom: JAPAN_WIDE_ZOOM,
        interactive: interactive,
        projection: 'globe', // display the map as a 3D globe
    });

    map[containerId].on('style.load', () => {
        map[containerId].setFog({}); // Set the default atmosphere style
    });

    if (isPositionButtonNecessary) {
        //位置情報取得ボタン
        const geoLocate = new mapboxgl.GeolocateControl({
            positionOptions: {enableHighAccuracy: false},
            trackUserLocation: false,
            showUserLocation: false
        });
        map[containerId].addControl(geoLocate);
        geoLocate.on('geolocate', function(e) {
            map[containerId].easeTo({
                center:[e.coords.longitude, e.coords.latitude],
                zoom:map[containerId].getZoom()
            });
        });
    }

    map[containerId].on('load', () => {
        //道路情報を保持する入れ物を用意
        map[containerId].addSource('tilequery', {
            type: 'geojson',
            data: {
                'type': 'FeatureCollection',
                'features': [],
            }
        });

        if (interactive) {
            map[containerId].on('click', (e) => {
                if (canMoveMarker) {
                    if (marker === null) {
                        marker = new mapboxgl.Marker({
                            color: "#FF6347",
                        })
                        .setLngLat([e.lngLat.lng, e.lngLat.lat])
                        .addTo(map[containerId]);
                    } else {
                        marker.setLngLat([e.lngLat.lng, e.lngLat.lat]);
                    }
                }
                map[containerId].panTo([e.lngLat.lng, e.lngLat.lat]);
            });
        }
    });
}

/**
 * 設定を加味した中点情報を取得する。
 *
 * @param {string}  longitude 経度。
 * @param {string}  latitude  緯度。
 * @param {boolean} isRelief  救援場所かお運び先か。
 */
function getCenterPosition(longitude, latitude, isRelief) {
    let center = [longitude, latitude];

    let reliefInfo = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    if (isRelief) {
        if(reliefInfo !== null && reliefInfo['reliefAddressInfo'] !== undefined) {
            // 救援要請情報を入力項目にフィルイン
            center = [reliefInfo['reliefAddressInfo']['longitude'], reliefInfo['reliefAddressInfo']['latitude']];
        }
    } else{
        if(reliefInfo !== null && reliefInfo['entryInfo'] !== undefined) {
            // 救援要請情報を入力項目にフィルイン
            center = [reliefInfo['entryInfo']['entryPlaceLongitude'], reliefInfo['entryInfo']['entryPlaceLatitude']];
        }
    }

    return center;
}


/**
 * 画面入力の中心座標で地図表示
 */
export function displayMap(stypeURL, isRelief = true, longitude = null, latitude = null, markerName, containerId="mapObject") {
    if (!(containerId in map)) {
        map[containerId] = null;
    }

    //初回はmap生成
    if (map[containerId] === null) {
        GeoLocation.getCurrentPosition().then((location:Location) => {
            if (location.latitude && location.longitude) {
                const center = getCenterPosition(location.longitude, location.latitude, isRelief);
                displayMap_internal(stypeURL, center, containerId, true, true, true);
                setCustomMarker(center[0], center[1], markerName, containerId);
            } else {
                const center = getCenterPosition(139.691706, 35.689487, isRelief);
                displayMap_internal(stypeURL, center, containerId, true, false, true);
                setCustomMarker(center[0], center[1], markerName, containerId);
            }
        });
    } else {
        //2回目以降は中心に移動するだけ
        map[containerId].panTo([longitude, latitude]);
    }
}

/**
 * 地図表示(受付状況確認画面用)
 */
export function receptionDisplayMap(stypeURL, longitude, latitude, containerId="mapObject") {
    //初回はmap生成
    if (!(containerId in map)) {
        map[containerId] = null;
    }

    if (map[containerId] === null) {
        const center = [longitude, latitude];
        displayMap_internal(stypeURL, center, containerId, true, false, false);
    } else {
        //2回目以降は中心に移動するだけ
        map[containerId].panTo([longitude, latitude]);
    }
}

/**
 * 地図表示(静的地図)
 */
export function staticMap(stypeURL, longitude, latitude, containerId) {
    if(!(containerId in map)){
        map[containerId] = null;
    }

    const center = [longitude, latitude];
    displayMap_internal(stypeURL, center, containerId, false, false, false, containerId);
}

/**
 * 地図情報を削除する。
 */
export function deleteMap() {
    map = [];
}

/**
 * 任意の2点で自動縮尺
 */
export function changeRange(x1,y1,x2,y2,containerId = "mapObject") {
    const bounds = new mapboxgl.LngLatBounds();

    //2点の座標の距離と角度を求める
    const angle = Math.atan2( y2 - y1, x2 - x1 ) * ( 180 / Math.PI );
    const distance = Math.sqrt( Math.pow( x2-x1, 2 ) + Math.pow( y2-y1, 2 ) );
    //求めた距離と角度から、対角線上の座標を取得
    const x2_ = parseFloat(x1) - distance * Math.cos( angle * (Math.PI / 180) ) ;
    const y2_ = parseFloat(y1) - distance * Math.sin( angle * (Math.PI / 180) ) ;

    //縮尺表示を行う座標群をセット
    bounds.extend([x1, y1]);
    bounds.extend([x2, y2]);
    bounds.extend([x2_, y2_]);
    if(map[containerId] !== null){
        //表示に余裕を持たせる
        map[containerId].fitBounds(bounds,{
            padding: 70
        });

        setCustomMarker(x1,y1,"marker_user",containerId);
        setCustomMarker(x2,y2,"marker_jaf",containerId);
    }
}

/**
 * 地図上に指定したマーカーをセット
 */
export function setCustomMarker(longitude, latitude, markerName, containerId = "mapObject") {
    //カスタムマーカーの定義
    let custom_marker = document.createElement('div');
    custom_marker.className = markerName;
    // マーカーを作成し、地図上に配置する
    marker = new mapboxgl.Marker(custom_marker)
      .setLngLat([longitude, latitude])
      .addTo(map[containerId]);
    map[containerId].on('style.load', () => {
        map[containerId].setFog({}); // Set the default atmosphere style
    });
}

/**
 * 地図上に指定したマーカーを異動させる
 */
export function setLngLatMarker(longitude, latitude) {
    marker.setLngLat([longitude, latitude]);
}

/**
 * 座標情報から逆ジオコーディング
 */
export function getAddressInfo(longitude, latitude) {
    const url = MapboxApi.REVERSE_GEOCODING_URL + longitude + "," + latitude;
    const data = {
        limit: 1,
        language: "ja",
        access_token: mapboxgl.accessToken,
    };

    console.log("逆ジオコーディング:", url);

    return new Promise((resolve, reject) => {
        $.ajax({
            url: url,
            type: "GET",
            dataType: "json",
            data: data,
            success: function (data) {
                //console.log("逆ジオコーディング成功:", data);
                if(data.features.length > 0){
                    //通信成功時の処理
                    console.log('住所情報 : 位置情報の取得成功');

                    const feature = data.features[0];
                    const info:AddressInfo = { placeName: returnReplacedAddress(feature.properties?.place_name) };

                    for (let context of feature.properties?.context) {
                        if (context?.localized_layer === "prefecture") {
                            info.prefecture = context.name;
                            break;
                        }
                    }

                    resolve(info);
                }
                else{
                    console.log('住所情報 : 位置情報の取得失敗');
                    resolve(null);
                }
            },
            error: function () {
                //通信失敗時の処理
                console.log('住所情報 : 位置情報の取得失敗');
                resolve(null);
            }
        });
    });
}

/**
 * マーカー位置を取得する。
 */
export function getMarkerGeometry() {
    return marker.getLngLat()
}

/**
 * 地図上のコンポーネントクリック判定
 */
export function checkMapClick(target) {
    //mapboxglのコントロールとその中のリンククリック時はマップクリック対象外
    if(target.className.indexOf("mapboxgl-ctrl") >= 0 || target.localName === "a"){
        return false;
    }

    return true;
}

/**
 * 逆ジオ結果住所から不要な文言を削除
 * @param {string} address 住所
 * @return {string} 変換後住所
 */
function returnReplacedAddress(address){
    //逆ジオ結果が取得できない場合処理終了
    if (!address) {
        return '';
    }

    if(address.match("^〒[0-9]{3}-[0-9]{4} ")){
        //パターン1: {先頭}〒{数字3桁}-{数字4桁}{半角スペース}
        address = address.replace(/^〒[0-9]{3}-[0-9]{4} /, '')
    }
    else if(address.match("^日本, ")){
        //パターン2: {先頭}日本,{半角スペース}
        address = address.replace(/^日本, /, '')
    }

    return address;
};

/* eslint-disable no-undef*/
