import './App.css';

import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import Home from './pages/Home'
import Send from './pages/Send'
import ReceptionStatus from './pages/ReceptionStatus'
import Regist from './pages/Regist'
import Sorry from './pages/Sorry'
import Relief from './pages/Relief'
import UserInfo from './pages/UserInfo'
import Call from './pages/Call'
import OrderContent from './pages/OrderContent'
import OrderConfirm from './pages/OrderConfirm'
import OrderConfirmAfter from './pages/OrderConfirmAfter'
import OrderCancel from './pages/OrderCancel'
import Ordered from './pages/Ordered'
import UserLogin from './pages/UserLogin'
import UserLoginResult from './pages/UserLoginResult'
import OrderCarry from './pages/OrderCarry'
import ChatLogin from './chat/Login'
import Message from './chat/Message'

import UnauthorizedError from './pages/UnauthorizedError'
import AccessDeniedError from './pages/AccessDeniedError'
import ApiError from './pages/ApiError'
import Error from './pages/Error'

import PagingNavigator from './common/PagingNavigator';


function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <AppRoutes/>
            </BrowserRouter>
        </div>
    );
}

function AppRoutes() {
    const navigate = useNavigate();
    const pageNavigator = useRef<PagingNavigator>(new PagingNavigator(navigate));

    useEffect(() => {
        pageNavigator.current.update(navigate);
    }, [navigate]);

    return (
        <Routes>
            <Route path="/" element={<Home pageNavigator={pageNavigator}/>} />
            <Route path="send" element={<Send pageNavigator={pageNavigator}/>} />
            <Route path="receptionStatus" element={<ReceptionStatus pageNavigator={pageNavigator}/>} />
            <Route path="regist/confirm" element={<Regist pageNavigator={pageNavigator}/>} />
            <Route path="sorry" element={<Sorry pageNavigator={pageNavigator}/>} />
            <Route path="relief" element={<Relief pageNavigator={pageNavigator}/>} />
            <Route path="userInfo" element={<UserInfo pageNavigator={pageNavigator}/>} />
            <Route path="call" element={<Call pageNavigator={pageNavigator}/>} />
            <Route path="orderContent" element={<OrderContent pageNavigator={pageNavigator}/>} />
            <Route path="orderConfirm" element={<OrderConfirm pageNavigator={pageNavigator}/>} />
            <Route path="orderConfirmAfter" element={<OrderConfirmAfter pageNavigator={pageNavigator}/>} />
            <Route path="orderCancel" element={<OrderCancel pageNavigator={pageNavigator}/>} />
            <Route path="ordered" element={<Ordered pageNavigator={pageNavigator}/>} />
            <Route path="userLogin" element={<UserLogin pageNavigator={pageNavigator}/>} />
            <Route path="userLoginResult" element={<UserLoginResult pageNavigator={pageNavigator}/>} />
            <Route path="orderCarry" element={<OrderCarry pageNavigator={pageNavigator}/>} />
            <Route path="chat/login" element={<ChatLogin pageNavigator={pageNavigator}/>} />
            <Route path="chat/message" element={<Message pageNavigator={pageNavigator}/>} />
            <Route path="unauthorizedError" element={<UnauthorizedError pageNavigator={pageNavigator}/>} />
            <Route path="apiError" element={<ApiError pageNavigator={pageNavigator}/>} />
            <Route path="error" element={<Error pageNavigator={pageNavigator}/>} />
            <Route path="accessDeniedError" element={<AccessDeniedError pageNavigator={pageNavigator}/>} />
            <Route path="*" element={<AccessDeniedError pageNavigator={pageNavigator}/>} />
        </Routes>
    );
}

export default App;
