import { api_url } from '../../constants';
import { MainteMessageInfo } from '../../types/mainteInfo';
import { ApiGet_Authorization } from '../ApiGet';
import { ApiDef, validateResponse } from './common';

type Callback = (response:Array<MainteMessageInfo>) => void;
type Error = (error:any) => void;

/**
 * メンテナンスメッセージの一覧を取得する。
 *
 * @param {Callback} success 成功時に呼び出されるコールバック関数。
 * @param {Error}    failure 失敗時に呼び出されるコールバック関数。
 */
export const getMaintenanceMessage = (success:Callback, failure:Error):void => {
    const URL = api_url.API_URL + "mainteInfo"; 

    const config = {
        headers:{}
    };

    ApiGet_Authorization(URL, config, (response:any) => {
        console.log('メンテナンスメッセージ取得成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("mainteMessageInfoList", "object", 0, false, [
                new ApiDef("code",       "string", 10,    true),
                new ApiDef("subject",    "string", 65535, true),
                new ApiDef("message",    "string", 65535, true),
                new ApiDef("startDt",    "string", 19,    true),
                new ApiDef("endDt",      "string", 19,    true),
            ]),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data.mainteMessageInfoList);
        } else {
            failure(reason);
        }
    }, failure);
};
