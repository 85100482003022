import { api_url } from '../../constants';
import { UserInfoRegisterRequest, UserInfoUpdateRequest, UserInfoResponse } from '../../types/userInfo';
import { ApiGet_Authorization } from '../ApiGet';
import { ApiPost_Authorization } from '../ApiPost';
import { ApiPut_AccessToken } from '../ApiPut';
import { ApiDef, validateResponse } from './common';
import Url from '../../common/Url';

type Callback = (response:UserInfoResponse) => void;
type UpdateCallback = () => void;
type Error = (error:any) => void;

/**
 * 利用者情報を登録する。
 *
 * @param {UserInfoRegisterRequest} request 利用者情報登録情報。
 * @param {Callback}                success 成功時に呼び出されるコールバック関数。
 * @param {Error}                   failure 失敗時に呼び出されるコールバック関数。
 */
export const registerUserInfo = (request:UserInfoRegisterRequest, success:Callback, failure:Error):void => {
    const URL = api_url.API_URL + "userInfo";

    const config = {
        headers:{
            "Content-Type": "application/json; charset=utf-8",
        }
    };

    ApiPost_Authorization(URL, request, config, (response:any) => {
        console.log('利用者情報登録成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("userId",      "string", 11, true),
            new ApiDef("accessToken", "string", 64, true),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};

/**
 * 利用者情報を取得する。
 *
 * @param {string}   mailAddress メールアドレス。
 * @param {Callback} success     成功時に呼び出されるコールバック関数。
 * @param {Error}    failure     失敗時に呼び出されるコールバック関数。
 */
export const getUserInfo = (mailAddress:string, success:Callback, failure:Error):void => {
    const url:Url = new Url(api_url.API_URL + "userInfo");
    url.addString("mailAddress", mailAddress);

    const config = {
        headers:{
            "Content-Type": "application/json; charset=utf-8",
        }
    };

    ApiGet_Authorization(url.create(), config, (response:any) => {
        console.log('利用者情報取得成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("userId",      "string", 11, true),
            new ApiDef("accessToken", "string", 64, true),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};

/**
 * 利用者情報を更新する。
 *
 * @param {UserInfoUpdateRequest} request 利用者情報更新情報。
 * @param {UpdateCallback}        success 成功時に呼び出されるコールバック関数。
 * @param {Error}                 failure 失敗時に呼び出されるコールバック関数。
 */
export const updateUserInfo = (request:UserInfoUpdateRequest, success:UpdateCallback, failure:Error):void => {
    const URL = api_url.API_URL + "userInfo";

    const config = {
        headers:{
            "Content-Type": "application/json; charset=utf-8",
        }
    };

    ApiPut_AccessToken(URL, request, config, (response:any) => {
        console.log('利用者情報更新成功', response);

        success();
    }, failure);
};
