/**
 * SessionStorageを管理するクラス。
 */
export default class SessionStorage {
    readonly _name:string;
    _data:any = {};

    /**
     * コンストラクタ。
     *
     * @param {string} name SessionStorageの項目名。
     */
    constructor(name:string) {
        this._name = name;
        this.reload();
    }

    /**
     * 値を設定する。
     *
     * SessionStorageに直接保存するわけではない。
     * SessionStorageに保存する場合は、#saveをコールすること。
     *
     * @param {string} key   キー名。
     * @param {any}    value keyに紐付けて設定する値。
     */
    set(key:string, value:any):void {
        this._data[key] = value;
    }

    /**
     * 値を取得する。
     *
     * SessionStorageから直接値を取得するわけではない。
     * 本オブジェクト生成時にSessionStorageから値を取得後、ローカルで管理しているものを取得する。
     * SessionStorageと同期したい場合には、#reloadをコールすること。
     *
     * @param  {string} key          キー名。
     * @param  {string} defaultValue 未設定な場合のデフォルト値。
     * @return {string} keyに紐付けて設定されている値。
     */
    get(key:string, defaultValue:any=null):any {
        const ret = this._data[key];
        if (ret !== undefined && ret !== null) {
            return ret;
        }
        return defaultValue;
    }

    /**
     * 値をstringで取得する。
     *
     * @param  {string} key          キー名。
     * @param  {string} defaultValue 未設定な場合のデフォルト値。
     * @return {string} keyに紐付けて設定されている値。
     * @see #get
     */
    getString(key:string, defaultValue:string|null=null):string {
        return this.get(key, defaultValue) as string;
    }

    /**
     * 値をnumberで取得する。
     *
     * @param  {string} key          キー名。
     * @param  {string} defaultValue 未設定な場合のデフォルト値。
     * @return {string} keyに紐付けて設定されている値。
     * @see #get
     */
    getNumber(key:string, defaultValue:number|null=null):number {
        return this.get(key, defaultValue) as number;
    }

    /**
     * 値をbooleanで取得する。
     *
     * @param  {string} key          キー名。
     * @param  {string} defaultValue 未設定な場合のデフォルト値。
     * @return {string} keyに紐付けて設定されている値。
     * @see #get
     */
    getBoolean(key:string, defaultValue:boolean|null=false):boolean {
        return this.get(key, defaultValue) as boolean;
    }

    /**
     * 値を削除する。
     *
     * @param {string} key キー名。
     */
    remove(key:string):void {
        delete this._data[key];
    }

    /**
     * 設定されている情報をSessionStorageに設定する。
     */
    save():void {
        sessionStorage.setItem(this._name, JSON.stringify(this._data));
    }

    /**
     * SessionStorageから値を読み出す。
     */
    reload():void {
        const data = sessionStorage.getItem(this._name);
        if (data) {
            this._data = JSON.parse(data);
        } else {
            this._data = {};
        }
    }

    /**
     * SessionStorageから項目を削除する。
     *
     * @param {string} name SessionStorageの項目名。
     */
    static clear(name:string):void {
        sessionStorage.removeItem(name);
    }
}
