import { chat_api_url } from '../../../constants';
import { ChatLoginResponse } from '../../../types/chatInfo';
import { ApiGet_Authorization } from '../../ApiGet';
import { ApiDef, validateResponse } from '../common';
import Url from '../../../common/Url';
import LoginUserInfo from '../../LoginUserInfo';

type Callback = (response:ChatLoginResponse) => void;
type Error = (error:any) => void;

/**
 * 救援要請単位のルームにログインし、アクセストークンの発行・再発行・レコード更新を行う。
 *
 * @param {string}   chatroomId ルームID。
 * @param {Callback} success    成功時に呼び出されるコールバック関数。
 * @param {Error}    failure    失敗時に呼び出されるコールバック関数。
 */
export const login = (chatroomId:string, success:Callback, failure:Error):void => {
    const url:Url = new Url(chat_api_url.API_URL + "login");
    url.addString("chatroomId", chatroomId);

    const config = {
        headers:{
            "Content-Type": "application/json; charset=utf-8",
            "X-RsRescue-Token": LoginUserInfo.getUserId(),
        }
    };

    ApiGet_Authorization(url.create(), config, (response:any) => {
        console.log('チャットログイン成功', response);

        const DEFS:ApiDef[] = [ new ApiDef("accessToken", "string", 64, true) ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};
