// 初期設定
import React, { useState }  from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation } from "react-router-dom";
import * as CommonUtil from '../common/CommonUtil';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import PageProps from '../types/pageProps';

const windowClose = () => {
    window.close();
}

function UserLoginResult(props:PageProps) {
    const location = useLocation();

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    // 初期処理
    React.useEffect(() => {
        const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);

        setDisplayFooter(true);  
        return initResult['cleanup'];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 画面設定
    return (

        <div>
            <HelmetProvider>
                <Helmet>
                    <title>ログイン確認メール送信結果 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header pageNavigator={props.pageNavigator}/>

            <div className="contentsWrapper">
                <div className="contents">
                    <p className="general">
                        メールに記載のURLへアクセスしてください。<br />
                    </p>

                    <div className="close">
                        <p>確認が完了したら閉じるボタンを押してください。<br />
                        （ボタンを押してもタブが閉じない場合は手動で閉じてください）</p>
                        <button className="close" onClick={windowClose}>閉じる</button>
                    </div>
                </div>
            </div>
            <Footer display={displayFooter}/>
        </div>
    )
}

export default UserLoginResult
