import $ from "jquery";

//引数でId指定された要素のスタイルを取得する
export const getElementStyle = (elementId:string, targetProperty:string) => {
    const element = $("#"+elementId);
    if(!element || element.length === 0){
        return null;
    }

    const elementStyle = window.getComputedStyle(element[0]);

    return elementStyle.getPropertyValue(targetProperty);
}

//引数でId指定された要素の数値のスタイルを取得する
export const getStyleNumber = (elementId:string, targetProperty:string):number => {
    const style = getElementStyle(elementId, targetProperty);
    if (style) {
        // 数値のみ抽出
        return Number(style.replace(/[^0-9]/g, ''));
    }
    return 0;
}