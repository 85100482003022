import SessionStorage from './SessionStorage';

const NAME:string = "mailInfo";

/**
 * メール情報を管理するクラス。
 */
export default class MailInfo extends SessionStorage {
    /**
     * コンストラクタ。
     */
    constructor() {
        super(NAME);
    }

    /**
     * メール送信完了フラグを設定する。
     *
     * @param {boolean} value メール送信完了フラグ。<br>
     *                        true: 送信済み、false: 未送信。
     */
    set sendMailFlg(value:boolean) {
        this.set("sendMailFlg", value as boolean|null);
    }

    /**
     * メール送信完了フラグを取得する。
     *
     * @return {boolean} メール送信完了フラグ。<br>
     *                   true: 送信済み、false: 未送信、null: 未設定。
     */
    get sendMailFlg():boolean {
        return this.getBoolean("sendMailFlg", null);
    }

    /**
     * ログインメール送信完了フラグを設定する。
     *
     * @param {boolean} value ログインメール送信完了フラグ。<br>
     *                        true: 送信済み、false: 未送信。
     */
    set sendLoginMailFlg(value:boolean) {
        this.set("sendLoginMailFlg", value as boolean|null);
    }

    /**
     * ログインメール送信完了フラグを取得する。
     *
     * @return {boolean} ログインメール送信完了フラグ。<br>
     *                   true: 送信済み、false: 未送信。
     */
    get sendLoginMailFlg():boolean {
        return this.getBoolean("sendLoginMailFlg", false);
    }

    /**
     * SessionStorageから項目を削除する。
     */
    static clean() {
        SessionStorage.clear(NAME);
    }
}
