import { Location } from '../types/location';

/**
 * 位置情報を管理するクラス。
 */
export default class GeoLocation {
    /**
     * 現在の位置情報を取得する。
     *
     * @return {Promise<Location>} Promiseオブジェクト。<br>
     *                             成功時・失敗時共にresolveで応答を返却する。<br>
     *                             失敗した場合にはLocationに格納されるlatitude/longitudeがnullとなる。
     */
    static getCurrentPosition():any {
        return new Promise<Location>((resolve, reject) => {
            const location: Location = {
                latitude: null,
                longitude: null,
            };

            if (!navigator.geolocation) {
                resolve(location);
                return;
            }

            navigator.geolocation.getCurrentPosition((pos) => {
                location.latitude = pos?.coords?.latitude?.toString();
                location.longitude = pos?.coords?.longitude?.toString();
                resolve(location);
            }, (error) => {
                resolve(location);
            });
        });
    }
}
