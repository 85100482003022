import React from 'react'
import { useEffect, useState, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async'
import $ from 'jquery';
import { ModalDialog, FuzzyReliefModalDialog, TireSelectModalDialog, ConfirmModalDialog } from '../common/Modal';
import { useLocation, useNavigate } from 'react-router-dom'
import {OrderContentMessages,AlertMessages} from '../messages';
import { Logout } from '../common/Logout';
import *  as CommonUtil from '../common/CommonUtil';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Cngestion } from '../header/Congestion';
import { ErrorTransition } from '../common/ErrorUtil';
import { QueryParameter, ErrorReason, ImageSetting } from '../constants';
import { OrderResponse } from '../types/orderInfo';
import { setImageFile, getImageFile, deleteImageFile } from '../common/ImageUtil';
import MasterInfo from "../common/MasterInfo"
import * as PagingUtil from '../common/PagingUtil';
import * as ReliefUtil from "../common/ReliefUtil";
import PageProps from '../types/pageProps';

/** imageサイズ */

const IMAGESIZE_WIDTH:number = 460;
const IMAGESIZE_HEIGHT:number = 460;

function OrderContent(props:PageProps) {

    const textIndent = {
        textIndent: "-0.9rem",
    }

    // セッションストレージからデータ取得
    const mstInfo:MasterInfo = new MasterInfo();
    let reliefInfo: any = sessionStorage.getItem('reliefInfo');
    reliefInfo = JSON.parse(reliefInfo);

    const [displayEntry, setDisplayEntry] = useState(false);
    const [displayTire, setDisplayTire] = useState(false);
    const [troubleErr, settroubleErr] = useState(false);
    const [methodErr, setmethodErr] = useState(false);
    const [counterErr, setcounterErr] = useState(false);
    const [fileErr, setFileErr] = useState(false);
    const [displayImage, setDisplayImage] = useState(false);
    const [message, setMessage] = useState('');
    const [mthdDisabled, setmthdDisabled] = useState(true);
    const error = useRef(false);
    const [troubleCode, setTroubleCode] = useState(reliefInfo?.orderInfo?.orderCode !== undefined ? reliefInfo?.orderInfo.orderCode : "");
    const [methodCode, setMethodCode] = useState(reliefInfo?.orderInfo?.orderContentCode !== undefined ? reliefInfo?.orderInfo.orderContentCode : "");

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    //多重押下対策
    const processing = useRef(false);

    const navigate = useNavigate();
    const location = useLocation();

    //スクロール対象定義
    const scrollTop = useRef<HTMLDivElement>(null);

    // エラーのスタイル
    const errorStyle = {
        color: "red",
    };

    useEffect(() => {

        const initResult = CommonUtil.initialize(true, true, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }        
        const addressInfo = reliefInfo['reliefAddressInfo']
        
        CommonUtil.getOrderControl(true,true,addressInfo['prefectureCode']).then(function(response: any){
            //救援要請制御ダイアログかどうか
            if(response){
                CommonUtil.setIndicator(false);
                PagingUtil.resetAndNext(ErrorTransition(ErrorReason.ORDER_CONTROL_ERROR), navigate);
                return;
            }
            setDisplay();
        })
        .catch(function(response: any){
            CommonUtil.setIndicator(false);
            PagingUtil.resetAndNext(ErrorTransition(response), navigate);
        })
        
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

    
    //画面要素生成
    const setDisplay = () => {
        // トラブル内容オプション追加
        const orderInfoList = mstInfo.orderInfoList;
        $("#trouble").empty();
        $('#trouble').append($('<option value="" disabled selected>トラブル内容を選択してください。</option>'));
        for (var i = 0; i < orderInfoList.length; i++) {
            var option = document.createElement('option');
            option.value = orderInfoList[i]['orderCode'];
            option.text = orderInfoList[i]['orderName'];
            $('#trouble').append(option);
        }        
        $('#trouble').val(troubleCode);

        $('#method').empty();
        $('#method').append($('<option id="option0" value="" disabled selected>選択してください。</option>'));
        $('#method').val("");

        // 救援要請情報を入力項目にフィルイン
        const orderInfo = reliefInfo['orderInfo']
        if(orderInfo !== undefined) {
            changetrbl(troubleCode);
            changemthd(methodCode);
            $('#counter').val(orderInfo['troubleDetail']);
            countLeft();
            // 添付画像を表示
            const imageKey: any = orderInfo['imageKey'];
            if(imageKey !== undefined) {
                //ファイル読み込み
                getImageFile(imageKey).then(function(response: File){ 
                    
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(response);
                    fileReader.onload = (function () {     
                        CommonUtil.draw("imagePreview", fileReader.result, IMAGESIZE_WIDTH, IMAGESIZE_HEIGHT).then(function(response: any){
                            setDisplayFooter(true);
                            if(response){
                                setDisplayImage(true);
                                CommonUtil.setIndicator(false);
                            }
                            else{
                                //ファイル読み込みエラー
                                CommonUtil.setIndicator(false);
                                setDisplayImage(false);
                                //アラートダイアログ表示
                                ModalDialog(AlertMessages.RSEWS_ER_91410);
                            }
                        });                       
                    });
                    fileReader.onerror = (function () {
                        setDisplayFooter(true);
                        //ファイル読み込みエラー
                        CommonUtil.setIndicator(false);
                        setDisplayImage(false);
                        //アラートダイアログ表示
                        ModalDialog(AlertMessages.RSEWS_ER_91410);
                        return;
                    });
                })
                .catch(function(response: boolean){
                    CommonUtil.setIndicator(false);
                    setDisplayImage(false);
                    PagingUtil.resetAndNext(ErrorTransition(ErrorReason.UNKWNOWN), navigate);
                })
            }
            else{
                setDisplayFooter(true);
                CommonUtil.setIndicator(false);
                setDisplayImage(false);
            }
        }
        else{
            setDisplayFooter(true);
            CommonUtil.setIndicator(false);
            setDisplayImage(false);
        }
    }
    
    // トラブル内容プルダウン選択時
    function changetrbl(selectValue:any) {
        setTroubleCode(selectValue)

        setDisplayEntry(false)
        setDisplayTire(false)
        setmthdDisabled(false)
        const orderContentInfoList = mstInfo.orderContentInfoList;

        setMethodCode("");
        $('#method').empty();
        $('#method').append($('<option id="option0" value="" disabled selected="selected">選択してください。</option>'));
        for (var i = 0, k = 1; i < orderContentInfoList.length; i++) {
            const orderCntInfo = orderContentInfoList[i]
            const orderCd = orderCntInfo['orderCode']
            if (selectValue === orderCd) {
                var option = document.createElement('option');
                option.id = 'option' + k;
                option.value = orderCntInfo['orderContentCode'];
                option.text = orderCntInfo['orderContentName'];
                option.setAttribute('data-ordercode', orderCntInfo['orderCode'])
                option.setAttribute('data-entryflg', orderCntInfo['entryPlaceSelectFlg'])
                option.setAttribute('data-tireflg', orderCntInfo['tireSelectFlg'])
                $('#method').append(option);
            }
        }        
        $('#method').val(methodCode);
    }


    // ご希望内容プルダウン選択時
    function changemthd(selectValue:any) {
        setMethodCode(selectValue)
        // お運び先選択・トラブルタイヤ位置ボタン表示判定
        let entryPlaceSelectFlg = $('#method option:selected').data('entryflg')
        let tireSelectFlg = $('#method option:selected').data('tireflg')
        if (entryPlaceSelectFlg === 1) {
            setDisplayEntry(true);
        } else {
            setDisplayEntry(false);
            delete reliefInfo.entryInfo;
            sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));
        }
        if (tireSelectFlg === 1) {
            setDisplayTire(true);
        } else {
            setDisplayTire(false);
            //選択したご希望内容のタイヤフラグが0の場合、SessionStorageのトラブルタイヤ情報を削除する
            if('orderInfo' in reliefInfo && 'tireInfo' in reliefInfo) {
                delete reliefInfo['tireInfo']
                delete reliefInfo['orderInfo']['troubleTires']
                sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));
            }
        }
    };


    //トラブル状況等テキストボックス残り文字数カウント
    function countLeft() {
        const value: any = $("#counter").val();
        let count = 100 - value.length;
        if(Math.sign(count) === -1) {
            count = 0
            setcounterErr(true)
        } else {
            setcounterErr(false)
        }
        $("p.counter").text('残り' + count + '文字');
    }


    // ファイル添付時
    $(document).off();
    $(document).on("change", ".preview-uploader", function (e) {
        if (e.target.files.length === 0) return;
        let file = e.target.files[0]
        
        // ファイルの拡張子・MIMEタイプ・サイズチェック
        let errorMessage = CommonUtil.checkImageFile(file);

        if (errorMessage !== "") {
            setFileErr(true);
            setMessage(errorMessage);

            rmvImg();
            return;
        }

        setFileErr(false)
        let fileReader = new FileReader();             //ファイルを読み取るオブジェクトを生成
        fileReader.readAsDataURL(file);                //ファイルを読み取る
        $(".preview-uploader").val("")                 //ファイル読み取り後にクリア

        CommonUtil.setIndicator(true);
        fileReader.onload = (function () {
            CommonUtil.draw("imagePreview", fileReader.result, IMAGESIZE_WIDTH, IMAGESIZE_HEIGHT).then(function(response: any){
                if(response){
                    let imageKey = null;
                    if(reliefInfo['orderInfo'] && reliefInfo['orderInfo']['imageKey']){
                        // 添付画像を表示
                        imageKey = reliefInfo['orderInfo']['imageKey'];
                    }

                    //描画が完了したらindexedDBに画像を保存
                    setImageFile(file,imageKey).then(function(response: any){
                        //responseには一意なキーが返る
                        reliefInfo['orderInfo'] = {...reliefInfo['orderInfo'], ...{
                            imageKey: response,
                        }};
                        sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));

                        setDisplayImage(true);
                        CommonUtil.setIndicator(false);
                    })
                    .catch(function(response: any){
                        PagingUtil.resetAndNext(ErrorTransition(ErrorReason.UNKWNOWN), navigate);
                    });
                }
                else{
                    //ファイル読み込みエラー
                    CommonUtil.setIndicator(false);
                    //アラートダイアログ表示
                    ModalDialog(AlertMessages.RSEWS_ER_91410);
                }
            });
        });
        fileReader.onerror = (function () {
            //ファイル読み込みエラー
            CommonUtil.setIndicator(false);
            //アラートダイアログ表示
            ModalDialog(AlertMessages.RSEWS_ER_91410);
            return;
        });
    });

    // プレビュー画像押下時
    function rmvImgConfirm() {
        let comment = '添付した画像を削除しますか？';
        // 画像削除確認ダイアログ表示
        ConfirmModalDialog(comment, () => {
            // 画像削除確認ダイアログ内処理
            // はいボタン押下時
            rmvImg();
        });
    }
    
    // プレビュー画像削除処理
    function rmvImg() {            
        CommonUtil.setIndicator(true);
        //DBから画像ファイルを削除
        deleteImageFile(reliefInfo.orderInfo?.imageKey).then(function(response: boolean){ 
            
            CommonUtil.setIndicator(false);
            // SessionStorageからimageKeyを削除
            if(reliefInfo?.orderInfo?.imageKey !== undefined){
                delete reliefInfo['orderInfo']['imageKey'];
            }
            sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));

            // 画像ファイルプレビュー削除
            var board:any = document.getElementById("imagePreview");
            const ctx = board.getContext("2d", { willReadFrequently: true });
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            setDisplayImage(false);
        })
        .catch(function(response: boolean){
            //ファイル削除エラー
            CommonUtil.setIndicator(false);
            //アラートダイアログ表示
            ModalDialog(AlertMessages.RSEWS_ER_91420);
            return;
        })
    }

    // トラブルタイヤ位置ボタン押下時
    function tireSlct(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        //多重押下対策
        e.preventDefault();
        if (processing.current) return;
        processing.current = true;
        //タイヤ選択ダイアログ表示
        TireSelectModalDialog(reliefInfo['wheels'], () => {
            // タイヤ選択ダイアログ内処理
            //決定ボタン押下時
            CommonUtil.tireSaveSelection();
            reliefInfo = sessionStorage.getItem('reliefInfo');
            reliefInfo = JSON.parse(reliefInfo);
            processing.current = false;
        }, () => {
            //「×」ボタン押下時
            if('orderInfo' in reliefInfo && 'tireInfo' in reliefInfo) {
                delete reliefInfo['tireInfo']
                delete reliefInfo['orderInfo']['troubleTires']
                sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));
            }
            processing.current = false;
        });
    }

    //次へボタン・お運び先選択押下時
    function next(e: React.MouseEvent<HTMLButtonElement, MouseEvent>,confirmFlg: boolean) {
        //多重押下対策
        e.preventDefault();
        if (processing.current) return;
        processing.current = true;
        
        //バリデーションチェック
        if (troubleCode === "") {
            settroubleErr(true)
            error.current = true
        } else {
            settroubleErr(false)
        }

        if (methodCode === "") {
            setmethodErr(true)
            error.current = true
        } else {
            setmethodErr(false)
        }

        let trimSpot: any = $("#counter").val();
        trimSpot = trimSpot.trim();
        $("#counter").val(trimSpot);
        if (trimSpot.length > 100) {
            setcounterErr(true)
            error.current = true
        } else {
            setcounterErr(false)
        }

        if (error.current) {
            error.current = false
            processing.current = false;
            
            scrollTop?.current?.scrollIntoView({behavior: "smooth"});
            return;
        }

        // sessionStorageに入力内容を保存
        let orderInfo = {
            'orderCode': troubleCode,
            'orderContentCode': methodCode,
            'troubleDetail': trimSpot === '' ? null :  trimSpot
        };

        reliefInfo['orderInfo'] = {...reliefInfo['orderInfo'], ...orderInfo};
        sessionStorage.setItem('reliefInfo', JSON.stringify(reliefInfo));
        if (confirmFlg) {
            ReliefUtil.transition("/orderConfirm", navigate);
        } else {
            PagingUtil.next("/orderCarry", navigate);
        }
    }


    // ヘッダーの電話ボタン押下時
    function telClick(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
        //多重押下対策
        e.preventDefault();
        if (processing.current) return;
        processing.current = true;
        const dialog = FuzzyReliefModalDialog(false, ():boolean => {
            // JAFに電話するボタン押下時
            if (processing.current) return false;
            processing.current = true;
            fuzzyRegist(dialog);
            return false;
        }, () => {
            // ウェブで続けるボタン押下時
        });

        processing.current = false;
    }


    //電話離脱時依頼内容登録API呼び出し
    const fuzzyRegist = (dialog:any) => {
        // 電話離脱時依頼内容登録API成功時
        const registSuccess = (success: OrderResponse) => {
            processing.current = false;
            let prefectureCode = reliefInfo['reliefAddressInfo']['prefectureCode'];
            dialog.close();
            //ログアウト処理
            Logout().finally(() => { 
                CommonUtil.setIndicator(false);
                //sessionStorageから取得した都道府県コードをURLパラメータとして電話案内画面へ渡す  
                PagingUtil.resetAndNext(`/call?${QueryParameter.PARAMETER_PREFECTURE_CODE}=` + prefectureCode, navigate);
            })
        };

        // 電話離脱時依頼内容登録APIエラー時
        const registFail = (e: any) => {
            CommonUtil.setIndicator(false);
            processing.current = false;
            console.log('電話離脱時依頼内容登録失敗', e);
            dialog.close();
            PagingUtil.resetAndNext(ErrorTransition(e), navigate);
        };

        CommonUtil.setIndicator(true);
        CommonUtil.postFuzzyRegist(registSuccess, registFail);
    }


    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>要請内容入力 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header tel back orderContentTel={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {telClick(e)}} pageNavigator={props.pageNavigator}/>

            <div ref={scrollTop}　className="contentsWrapper">
                <div className="contents">

                    <ul className="status">
                        <li>救援場所入力</li>
                        <li>お客様情報入力</li>
                        <li className="current">トラブル内容入力</li>
                        <li>内容確認</li>
                        <li>登録完了</li>
                    </ul>

                </div>
            </div>

            <div className="contentsWrapper top0">
                <div className="contents">

                    <div className="flexWrapper">
                        <div>
                            <p className="title must">トラブル内容</p>
                            <select
                                name="トラブル内容"
                                id="trouble"
                                className="v13"
                                value={troubleCode}
                                onChange={(e) => changetrbl(e.target.value)}
                                required>
                            </select>
                            {troubleErr && <p className="cmt" style={errorStyle}>{OrderContentMessages.RSEWS_V14_M001}</p>}
                            <p className="title must">ご希望内容</p>
                            <select
                                name="ご希望内容"
                                id="method"
                                className="v13"
                                value={methodCode}
                                onChange={(e) => changemthd(e.target.value)}
                                disabled={mthdDisabled}
                                required>
                            </select>
                            {methodErr && <p className="cmt" style={errorStyle}>{OrderContentMessages.RSEWS_V14_M002}</p>}
                            <p className="title nomust">トラブル状況等</p>
                            <textarea name="トラブル状況等" id="counter" className="trouble v13" placeholder="トラブル詳細などを記入してください" onChange={countLeft}></textarea>
                            {counterErr && <p className="cmt" style={{color:'red', float:'left'}}>{OrderContentMessages.RSEWS_V14_M003}</p>}
                            <p className="counter">残り100文字</p>
                        </div>
                        <div>
                            <p className="title">画像添付</p>
                            <p className="cmt" style={textIndent}>※お使いの機種によっては、アップロードできない場合がございます。</p>
                            <p className='cmt'>アップロードできるファイル形式は {ImageSetting.ALLOW_FILE_TYPES.join("/")}です。</p>
                            <div className="submit picture_attach pt10">
                                <label htmlFor="file_upload" className="file_upload">
                                    ファイルから添付する
                                    <input type="file" id="file_upload" accept={"."+ImageSetting.ALLOW_EXTS.join(",.")} className="preview-uploader" name="ファイルから添付する" />
                                </label>
                                <label htmlFor="camera" className="file_upload">
                                    画像を撮影する
                                    <input type="file" accept={"."+ImageSetting.ALLOW_EXTS.join(",.")} capture="environment" name="カメラ" id="camera" className="preview-uploader" />
                                </label>
                            </div>
                                {fileErr && <p className="cmt" style={errorStyle}>{message}</p>}
                            <div className="preview">
                                <canvas id="imagePreview" width={IMAGESIZE_WIDTH} height={IMAGESIZE_HEIGHT} onClick={rmvImgConfirm} style={{ display: displayImage ? 'block' : 'none' }}></canvas>
                            </div>
                        </div>
                    </div>

                    <div className="submit verysmall">
                        {displayEntry && <button className="default" onClick={(e) => next(e,false)}>お運び先選択</button>}
                        {displayTire && <button className="default" onClick={(e) => tireSlct(e)}>トラブルタイヤ位置</button>}
                    </div>
                    <div className="submit verysmall">
                        <button className="action arrow" onClick={(e) => next(e,true)}>次へ</button>
                    </div>
                </div>
            </div>

            <Footer display={displayFooter}/>
            <Cngestion />
        </div>
    )
}

export default OrderContent;