import { api_url } from '../../constants';
import { ApiGet_Authorization } from '../ApiGet';
import { ApiDef, validateResponse } from './common';
import LoginUserInfo from '../LoginUserInfo';

type Callback = (response:string) => void;
type Error = (error:any) => void;

/**
 * アクセストークンの発行・再発行・レコード更新を行う。
 *
 * @param {Callback} success 成功時に呼び出されるコールバック関数。
 * @param {Error}    failure 失敗時に呼び出されるコールバック関数。
 */
export const getAccessToken = (success:Callback, failure:Error):void => {
    const URL = api_url.API_URL + "token";

    const config = {
        headers:{
            "X-RsRescue-Token": LoginUserInfo.getUserId(),
            "Content-Type": "application/json; charset=utf-8",
        }
    };

    ApiGet_Authorization(URL, config, (response:any) => {
        console.log('アクセストークン発行成功', response);

        const DEFS:ApiDef[] = [ new ApiDef("accessToken", "string", 64, true) ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data.accessToken);
        } else {
            failure(reason);
        }
    }, failure);
};
