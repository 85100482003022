import MasterInfo from "./common/MasterInfo"

/* eslint-disable no-undef*/
//車両名称サジェスト
export const vehicleNameSuggest = (carMakerInputValue, carNameInputValue) => {
  const mstInfo:MasterInfo = new MasterInfo();

  const { carMakerInfoList, carNameInfoList } = mstInfo;

  const maker = carMakerInfoList?.filter(maker => maker.carMakerName === carMakerInputValue);
  const vehicle_name_list = carNameInfoList.map(carName => { return carName });

  let vehicleNameOptions = [];
  for (let i = 0; i < vehicle_name_list.length; i++) {
    if(
    maker.length === 0 || ( vehicle_name_list[i].twoWheelsFlg === '0' &&
    ((maker[0].carMakerCode === vehicle_name_list[i].carMakerCode && vehicle_name_list[i].carName.includes(carNameInputValue)) || 
    (maker[0].carMakerCode === vehicle_name_list[i].carMakerCode && vehicle_name_list[i].carNameHiragana.includes(carNameInputValue)) ||
    (maker[0].carMakerCode === vehicle_name_list[i].carMakerCode && vehicle_name_list[i].carNameKatakana.includes(carNameInputValue))))
    ){
      vehicleNameOptions.push(vehicle_name_list[i].carName);
    }
  }

  $('#txt_vehicle_name').autocomplete({
          source: vehicleNameOptions,
          minLength: 0,  // 「0」を設定したら、全ての項目を表示する。
          delay : 1,
          autoFocus: false,
          scroll:true,
          position:{ my : "left top", at: "left bottom", collision: "none" },
          select: function(event, ui) {
            $('#txt_vehicle_name').val(ui.item.value)
            $('#txt_vehicle_name').blur()
        }
  });
  
  $('#txt_vehicle_name').autocomplete("search", "");
}

//ナンバープレート(本拠地)サジェスト
export const vehiclePlaceSuggest = (val) => {
  const mstInfo:MasterInfo = new MasterInfo();

  const { carNumberPlaceInfoList } = mstInfo;

  let vehicle_place_list = [];
  for(let i=0;i < carNumberPlaceInfoList.length; i++){
      vehicle_place_list.push(carNumberPlaceInfoList[i]);
  }

  let vehiclePlaceOptions = [];
  for (let i = 0; i < vehicle_place_list.length; i++) {
    if(val === "" || vehicle_place_list[i].carNumberPlaceName.includes(val) || vehicle_place_list[i].carNumberPlaceHiragana.includes(val) || vehicle_place_list[i].carNumberPlaceKatakana.includes(val)) {
      vehiclePlaceOptions.push(vehicle_place_list[i].carNumberPlaceName);
    }
  }

  $('#txt_vehicle_place').autocomplete({
          source: vehiclePlaceOptions,
          minLength: 0,  // 「0」を設定したら、全ての項目を表示する。
          delay : 1,
          autoFocus: false,
          scroll:true,
          position:{ my : "left top", at: "left bottom", collision: "none" },
          select: function(event, ui) {
            $('#txt_vehicle_place').val(ui.item.value)
            $('#txt_vehicle_place').blur()
        }
  });
  
  $('#txt_vehicle_place').autocomplete("search", "");
}
