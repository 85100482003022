import { api_url } from '../../constants';
import { OrderControlInfo } from '../../types/orderControlInfo';
import { ApiGet_Authorization } from '../ApiGet';
import { ApiDef, validateResponse } from './common';
import Url from '../../common/Url';

type Callback = (response:OrderControlInfo) => void;
type Error = (error:any) => void;

/**
 * 救援要請可否情報を取得する。
 *
 * @param {string}   prefectureCode 都道府県コード。
 * @param {Callback} success        成功時に呼び出されるコールバック関数。
 * @param {Error}    failure        失敗時に呼び出されるコールバック関数。
 */
export const getOrderControl = (prefectureCode:string|null, success:Callback, failure:Error):void => {
    const url:Url = new Url(api_url.API_URL + "orderControl");
    url.addString("prefectureCode", prefectureCode);

    const config = {
        headers:{}
    };

    ApiGet_Authorization(url.create(), config, (response:any) => {
        console.log('救援要請制御情報取得成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("reliefControlCode", "string", 1, true),
            new ApiDef("prefectureCode",    "string", 2, false),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data);
        } else {
            failure(reason);
        }
    }, failure);
};
