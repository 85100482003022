import SessionStorage from './SessionStorage';
import { MainteMessageInfo } from '../types/mainteInfo';

const NAME:string = "mainteMessageInfoList";

/**
 * メンテナンスメッセージ情報を管理するクラス。
 */
export default class MaintenanceInfo extends SessionStorage {
    /**
     * コンストラクタ。
     */
    constructor() {
        super(NAME);
    }

    /**
     * メンテナンスメッセージの一覧を設定する。
     *
     * @param {Array<MainteMessageInfo>} value メンテナンスメッセージの一覧。
     */
    set mainteMessageInfoList(value:Array<MainteMessageInfo>) {
        this.set("mainteMessageInfoList", value);
    }

    /**
     * メンテナンスメッセージの一覧を取得する。
     *
     * @return {Array<MainteMessageInfo>} メンテナンスメッセージの一覧。
     */
    get mainteMessageInfoList():Array<MainteMessageInfo> {
        return this.get("mainteMessageInfoList", []) as Array<MainteMessageInfo>;
    }

    /**
     * SessionStorageから項目を削除する。
     */
    static clean() {
        SessionStorage.clear(NAME);
    }
}
