// 初期設定
import React from 'react'
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation } from "react-router-dom";

import * as CommonUtil from '../common/CommonUtil';
import PageProps from '../types/pageProps';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { SendImage } from '../constants';

function Send(props:PageProps) {
  const location = useLocation();
  
  //フッター表示用State
  const [displayFooter, setDisplayFooter] = useState(false);

  useEffect(() => {
    const initResult = CommonUtil.initialize(true, false, props.pageNavigator, location);
    
    CommonUtil.setIndicator(true);
    CommonUtil.draw("desktopImagePreview", SendImage.Desktop.URL).then(function(response: any){
      CommonUtil.draw("mobileImagePreview", SendImage.Mobile.URL).then(function(response: any){
        CommonUtil.setIndicator(false);
        setDisplayFooter(true);
      });
    });

    return initResult['cleanup'];
  })

//画面表示
    return (

<div>

<HelmetProvider>
  <Helmet>
    <title>利用登録確認メール送信結果 | JAF救援要請ウェブサイト</title>
  </Helmet>
</HelmetProvider>
<Header pageNavigator={props.pageNavigator}/>
<div className="contentsWrapper">
<div className="contents">

<p className="general">
ご入力いただいたメールアドレスへメールを送信いたします。<br />
メールに記載のURLへアクセスしてください。<br />
なお、ロードサービス要請には位置情報が必須のため、以下の画面が表示されたら、許可をお願いします。<br />
</p>

<div className="imgcntr w80">
  <canvas id="desktopImagePreview" className="desktop" width={SendImage.Desktop.WIDTH} height={SendImage.Desktop.HEIGHT} ></canvas>
  <canvas id="mobileImagePreview" className="mobile" width={SendImage.Mobile.WIDTH} height={SendImage.Mobile.HEIGHT} ></canvas>
</div>
<div className="close">
<p>確認が完了したら閉じるボタンを押してください。<br />
(ボタンを押してもタブが閉じない場合は手動で閉じてください）</p>
<button className="close" onClick={() => window.close()}>閉じる</button>
</div>
</div>
</div>
<Footer display={displayFooter}/>

        </div>
    )
}
 


export default Send

