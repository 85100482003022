import { api_url } from '../../constants';
import { CarInfo } from '../../types/carInfo';
import { ApiGet_AccessToken } from '../ApiGet';
import { ApiDef, validateResponse } from './common';
import Url from '../../common/Url';

type Callback = (response:Array<CarInfo>) => void;
type Error = (error:any) => void;

/**
 * 地図に表示する号車の位置情報を取得する。
 *
 * @param {string}   orderNum アライアンスサーバ依頼番号。
 * @param {Callback} success  成功時に呼び出されるコールバック関数。
 * @param {Error}    failure  失敗時に呼び出されるコールバック関数。
 */
export const getCarInfo = (orderNum:string, success:Callback, failure:Error):void => {
    const url:Url = new Url(api_url.API_URL + "carInfo");
    url.addString("orderNum", orderNum);

    const config = {
        headers:{}
    };

    ApiGet_AccessToken(url.create(), config, (response:any) => {
        console.log('号車情報取得成功', response);

        const DEFS:ApiDef[] = [
            new ApiDef("carInfoList", "object", 0, false, [
                new ApiDef("orderNum",                   "string", 25, true),
                new ApiDef("id",                         "string", 10, true),
                new ApiDef("carNum",                     "string", 5,  true),
                new ApiDef("roadServiceCarType",         "string", 2,  true),
                new ApiDef("baseCode",                   "string", 1,  true),
                new ApiDef("latitude",                   "string", 20, false),
                new ApiDef("longitude",                  "string", 20, false),
                new ApiDef("insDt",                      "string", 19, true),
                new ApiDef("arrivalScheduleDt",          "string", 19, true),
                new ApiDef("exceptionArrivalScheduleDt", "string", 4,  false),
            ]),
        ];

        const reason = validateResponse(DEFS, response.data);
        if (!reason) {
            success(response.data.carInfoList);
        } else {
            failure(reason);
        }
    }, failure);
};
