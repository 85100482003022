/**
 * フローティングバー要素
 */
export const Cngestion = () => {
    return (
        <div id="commonFbarWrapper" className={"fbarWrapper conceal"}>
            <div id="cngestionFbar" className={"fbar conceal"}>
                <p className="msg">救援車両到着までに時間を要する場合があります。</p>
                <p className="fclose"></p>
            </div>
            <div id="pointFbar" className={"fbar conceal"}>
                <p className="msg">位置情報の取得に失敗しました。</p>
                <p className="fclose"></p>
            </div>
        </div>
    )
}
