import EnvSettings from "./settings/envSettings";
import VersionFile from "./version.json";

/** バージョン情報 */
export class Version {
  static readonly FILE = "/version.json";
  static readonly CODE = VersionFile.version;
}

/** ライセンス情報 */
export class Licence {
  static readonly FILE = "/licence.html";
}

/** メンテナンスメッセージ_公開ステータス */
export class open_status {
  // クラス定数の定義
  static readonly DISABLED = '1'; // 公開ステータス：無効
  static readonly PUBLISHED = '2'; // 公開ステータス：公開
}


/** 救援要請制御コード */
export class ReliefControlCode {
  // クラス定数の定義
  static readonly POSSIBLE = '1'; // 受付可能
  static readonly CONGESTION = '2'; // 混雑通知
  static readonly IMPOSSIBLE = '3'; // 受付不可
}


/** API 設定 */
export class ApiSetting {
  // クラス定数の定義
  static readonly SERVICE_KEY = EnvSettings.SERVICE_KEY;
  static readonly CHAT_SERVICE_KEY = EnvSettings.CHAT_SERVICE_KEY;
}

/** API URLおよびバージョン設定 */
export class api_url {
  // クラス定数の定義
  static readonly API_DOMAIN = EnvSettings.API_DOMAIN;
  static readonly API_VERSION = "v1";
  static readonly API_URL = api_url.API_DOMAIN + "/"+api_url.API_VERSION+"/";
}

/** chat API URLおよびバージョン設定 */
export class chat_api_url {
  // クラス定数の定義
  static readonly API_DOMAIN = EnvSettings.CHAT_API_DOMAIN;
  static readonly API_VERSION = "v1";
  static readonly API_URL = chat_api_url.API_DOMAIN + "/"+chat_api_url.API_VERSION+"/";
}

/** 地図表示関連設定 */
export class map_box {
  static readonly MAP_STYLE = EnvSettings.MAP_STYLE;;
}

/** メール送信元 */
export class mail_sender {
  // クラス定数の定義
  static readonly ADDRESS = EnvSettings.MAIL_ADDRESS;
  static readonly DOMAIN = EnvSettings.MAIL_DOMAIN;
}

/** 受付ステータスコード */
export class ReceptionStatusCode {
  static readonly WAITING_TO_ACCEPT = '1';    // 受付待ち
  static readonly ACCEPTING = '2';            // 受付中
  static readonly RECEPTION_COMPLETION = '3'; // 受付完了
  static readonly EXPRESS_IN = '4';           // 車両急行中
  static readonly ARRIVAL = '5';              // 車両到着(作業中)
  static readonly RELIEF_COMPLETION = '6';    // 救援完了
  static readonly WAITING_TO_CANCEL = '7';    // 取消受付待ち
  static readonly CANCELLING = '8';           // 取消処理中
  static readonly CANCEL_COMPLETION = '9';    // 取消完了
}

/** 依頼登録フラグ */
export class OrderFlag {
    static readonly UNREGISTERED = '0';  // 救援要請未登録
    static readonly REGISTERING = '1';   // 救援要請登録中
}

/** クエリパラメータ */
export class QueryParameter {
    static readonly PARAMETER_INFLOW_CODE = 'iC';     // 流入元コード短縮化
    static readonly PARAMETER_PREFECTURE_CODE = 'pC'; // 都道府県コード短縮化
    static readonly PARAMETER_HASH = 'hash';          // ハッシュ
}

/** 都道府県コード */
export class PrefectureCode {
  static readonly TOKYO = "13";
  static readonly DEFAULT = this.TOKYO;
}

/** ブラックリスト登録フラグ */
export class BlacklistFlag {
    static readonly UNREGISTERED = '0';  // 登録なし
    static readonly REGISTERED = '1';    // 登録あり
}

/** 遷移先コード(transitionCode)は定数から取得して、1固定としてください。 */
export class TransitionCode {
  static readonly RELIEF_ORDER = "RSEWS-V11";
  static readonly RECEPTION_STATUS = "RSEWS-V21";
  static readonly CHAT = "RSEWS-V23";
}

/** ・認証コード(authCode)は定数から取得して、1固定としてください。 */
export class AuthCode {
  static readonly USER_REGISTRATION_CONFIRM = "1"; //利用登録確認
  static readonly LOGIN_CONFIRM = "2"; //ログイン確認
  static readonly RELIEF_ORDER_COMPLETION = "3"; //救援車両手配完了通知
  static readonly RELIEF_ORDER_PROXIMITY = "4"; //救援車両近接通知
  static readonly QUESTIONNAIRE_REQUEST = "5"; //アンケート通知
  static readonly NEW_CHAT_MESSAGE = "6"; //新着チャット通知
  static readonly RELIEF_ORDER_REGIST = "7"; //救援要請登録完了通知
}

/** 手配区分コード */
export class ArrangementCode {
  static readonly RELIEF_RESERVATION_DT_INQUIRY = "1"; //問い合せ（不使用）
  static readonly RELIEF_RESERVATION_DT_CANCEL = "2"; //取消
  static readonly RELIEF_RESERVATION_DT_RESERVATION = "3"; //予約
  static readonly RELIEF_RESERVATION_DT_EMERGENCY = "4"; //緊急
  static readonly RELIEF_RESERVATION_DT_RECEPTION = "5"; //受付
}

/**道路場所コード */
export class RoadLocationCode {
  static readonly ORDINARY_ROADS = "1"; //路上（一般道）
  static readonly HOME = "2"; //自宅
  static readonly PARKING_LOT = "3"; //駐車場
  static readonly FREEWAY = "4"; //路上（高速・専用道路）
  static readonly SERVICE_AREA = "5"; //SA/PA（高速・専用道路）
  static readonly OTHER = "6"; //その他（詳細は下記に入力してください）
}

/**会員証の所持フラグ */
export class MembershipCardFlg {
  static readonly POSSESSION = "1"; //所持
  static readonly NON_POSSESSION = "2"; //非所持
}

/**会員情報SSO連携フラグ */
export class MembershipSsoFlg {
  static readonly NO_LINKAGE = "0"; //会員情報連携無し
  static readonly LINKAGE = "1"; //会員情報連携有り
}

/**手入力フラグ */
export class ManualInputFlg {
  static readonly MAP_SELECT = "0"; //地図から選択
  static readonly MANUAL = "1"; //手入力
}

/**牽引希望フラグ */
export class TowHopeFlg {
  static readonly UNWANTED = "0"; //希望無し
  static readonly WANT = "1"; //希望有り
}

/** オペレータユーザID */
export class OperatorUser {
  static readonly USER_ID = "AC3"; //オペレータユーザID
}
/** メッセージ区分コード */
export class ChatMessageCode {
  static readonly TEXT = "1"; //テキスト
  static readonly FILE = "2"; //画像
}

/** 画像設定 */
export class ImageSetting {
  static readonly ALLOW_FILE_TYPES = [ "JPEG", "PNG", "GIF" ] //画面表示用拡張子
  static readonly ALLOW_EXTS = ['gif', 'jpeg', 'png', 'jpg']; //拡張子
  static readonly ALLOW_MIMES = ['image/gif', 'image/jpeg', 'image/png'] //mimeタイプ
  static readonly MAX_IMAGE_SIZE = 8388608; //ファイルサイズ上限(8MB) 
}

/**キャンセル理由区分コード */
export class CancelReason {
  static readonly OTHER_SUPPLEMENTS = "614"; //その他（補足情報必須）
}

/** 車両種別 */
export class WheelType {
  static readonly FOUR_WHEELS = "4";
  static readonly TWO_WHEELS = "2";
}
/** ページ遷移順 */
export class PageTransitionList {

  static readonly PAGE_LIST = [[{
                                'pageName' : '/relief',  //画面パス
                                'requreList' : ['controlCenterCode', 'areaCode', 'prefectureCode', 'latitude', 'longitude', 'locationCode', 'roadCode'],  //入力必須項目
                                'infoKeyName' : 'reliefAddressInfo' //入力情報セットキー
                              }],
                              [{
                                'pageName' : '/userInfo',
                                'requreList' : ['userNameKanji', 'userNameKana', 'memberFlg', 'telNum', ['carMakerCode','twoWheelInfo,carMakerCode'], ['vehicleCode','twoWheelInfo,vehicleCode'], ['carNameCode','twoWheelInfo,carNameCode']],
                                'infoKeyName' : 'userInfo'
                              }],
                              [{
                                'pageName' : '/orderContent',
                                'requreList' : ['orderCode', 'orderContentCode'],
                                'infoKeyName' : 'orderInfo'
                              }],
                              [{
                                'pageName' : '/orderCarry',
                                'requreList' : [],
                                'infoKeyName' : 'entryInfo'
                              }
                              ,{
                                'pageName' : '/orderConfirm',
                                'requreList' : [],
                                'infoKeyName' : ''
                              }]
                            ]
}
/** IndexedDBの使用設定 */
export class IndexedDBSetting {
  static readonly DB_NAME = "rsrescueDB";
}

/** エラー要因 */
export class ErrorReason {
  static readonly UNKWNOWN = 'UNKNOWN';
  static readonly ACCESS_DENIED_ERROR = 'AccessDeniedError';
  static readonly ORDER_CONTROL_ERROR = 'OrderControlError';
  static readonly RESPONSE_NOT_FOUND = "ResponseNotFound";
  static readonly RESPONSE_TYPE_MISMATCHED = "ResponseTypeMismatched";
  static readonly VERSION_MISMATCHED = "versionMismatched";
}

/** 全コールセンター電話番号 */
export class CallCenterNumber {
  static readonly KANTO = EnvSettings.KANTO_CALL_CENTER;
  static readonly CHUBU = EnvSettings.CHUBU_CALL_CENTER;
  static readonly KANSAI = EnvSettings.KANSAI_CALL_CENTER;
  static readonly KYUSHU = EnvSettings.KYUSHU_CALL_CENTER;
}

/** JAFへの入会*/
export class MemberFlg {
  static readonly MEMBER = "1";
  static readonly NO_MEMBERS = "2";
  static readonly UNKWNOWN = "3";
}

/** ポーリング処理間隔 */
export class ChatPollingSetting {
  static readonly TIME = EnvSettings.POLLING_TIME;
}

/** mapboxAPI */
export class MapboxApi {
  static readonly REVERSE_GEOCODING_URL = EnvSettings.MapBox.REVERSE_GEOCODING_URL;
}

/**
* メール送信結果画面に表示する画像設定
*
* 画像の変更があった場合にURL, WIDTH, HEIGHTの値を変更後の画像に合わせて修正する事。
*
* 下記理由により本箇所で画像設定を管理する方針となった。
* メール送信結果画面ではパーミッション許可の案内画像を表示しているが、フッタの表示位置の計算を行う際に
* 画面表示タイミング(画像ロードされる前)でcanvasタグのwidth/ heightに設定する画像のサイズが必要であった為。
*
* また、フッタのサイズ計算のタイミングを変更することでcanvasタグのwidth/heightに画像のサイズを動的に設定する方法も検討したが
* 時間が無かったため断念した。
*
*/
export class SendImage {
  public static Desktop = class extends SendImage {
    /**
     * デスクトップサイズ表示時のメール送信結果画面に表示する画像のパス
     */
    static readonly URL = "/images/pic_alert1.svg";

    /**
     * 画像の幅のピクセル値
     */
    static readonly WIDTH = 512;

    /**
     * 画像の高さのピクセル値
     */
    static readonly HEIGHT = 489;
  };

  public static Mobile = class extends SendImage {
    /**
     * モバイルサイズ表示時のメール送信結果画面に表示する画像のパス
     */
    static readonly URL = "/images/pic_alert2.svg";

    /**
     * 画像の幅のピクセル値
     */
    static readonly WIDTH = 378.98;

    /**
     * 画像の高さのピクセル値
     */
    static readonly HEIGHT = 510.34;
  };
}

/**
* 登録完了画面に表示する画像設定
*
* 画像の変更があった場合にURL, WIDTH, HEIGHTの値を変更後の画像に合わせて修正する事。
*
* 下記理由により本箇所で画像設定を管理する方針となった。
* 要請内容登録完了画面ではパーミッション許可の案内画像を表示しているが、フッタの表示位置の計算を行う際に
* 画面表示タイミング(画像ロードされる前)でcanvasタグのwidth/ heightに設定する画像のサイズが必要であった為。
*
* また、フッタのサイズ計算のタイミングを変更することでcanvasタグのwidth/heightに画像のサイズを動的に設定する方法も検討したが
* 時間が無かったため断念した。
*
*/
export class OrderedImage {
  public static Desktop = class extends OrderedImage {
    /**
     * デスクトップサイズ表示時の要請内容登録完了画面に表示する画像のパス
     */
    static readonly URL = "/images/pic_alert1.svg";

    /**
     * 画像の幅のピクセル値
     */
    static readonly WIDTH = 512;

    /**
     * 画像の高さのピクセル値
     */
    static readonly HEIGHT = 489;
  };

  public static Mobile = class extends OrderedImage {
    /**
     * モバイルサイズ表示時の要請内容登録完了画面に表示する画像のパス
     */
    static readonly URL = "/images/pic_alert2.svg";

    /**
     * 画像の幅のピクセル値
     */
    static readonly WIDTH = 378.98;

    /**
     * 画像の高さのピクセル値
     */
    static readonly HEIGHT = 510.34;
  };
}