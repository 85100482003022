import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as MapBox from '../mapbox';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { map_box, MembershipCardFlg, MembershipSsoFlg, ManualInputFlg, TowHopeFlg, ErrorReason, MemberFlg } from '../constants';
import { ModalDialog, NoticeModalDialog, ConfirmModalDialog } from '../common/Modal';
import { ReliefInfo } from '../types/reliefInfo';
import { OrderContentInfoList, OrderInfoList } from '../types/mstInfo';
import { registerOrder } from "../common/api/order"
import { OrderRequest, OrderResponse } from '../types/orderInfo';
import * as CommonUtil from '../common/CommonUtil';
import * as MasterInfoUtil from '../common/MasterInfoUtil';
import * as ReliefUtil from '../common/ReliefUtil';
import MasterInfo from "../common/MasterInfo"
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Cngestion } from '../header/Congestion';
import { ErrorTransition } from '../common/ErrorUtil';
import { AlertMessages } from '../messages';
import { getImageFile } from '../common/ImageUtil';
import * as PagingUtil from '../common/PagingUtil';
import PageProps from '../types/pageProps';

//画像のサイズ
const IMAGESIZE_WIDTH:number = 400;
const IMAGESIZE_HEIGHT:number = 400;

const OrderConfirm = (props:PageProps) => {

    const getReliefInfo = sessionStorage.getItem('reliefInfo');
    var reliefInfo: ReliefInfo = getReliefInfo !== null ? JSON.parse(getReliefInfo) : "";
    var { reliefAddressInfo, userInfo, orderInfo, entryInfo } = reliefInfo;

    const mstInfo:MasterInfo = new MasterInfo();
    const { roadLocationInfoList, orderInfoList, orderContentInfoList, carNumberPlaceInfoList } = mstInfo;
    const locationName = MasterInfoUtil.findRoadLocationName(reliefAddressInfo?.roadCode?.toString(), reliefAddressInfo?.locationCode?.toString(), roadLocationInfoList);
    const orderName = orderInfoList?.find((order: OrderInfoList) => order.orderCode === orderInfo?.orderCode)?.orderName;
    const orderContentName = orderContentInfoList?.find((orderContent: OrderContentInfoList) => orderContent.orderContentCode === orderInfo?.orderContentCode && orderContent.orderCode === orderInfo?.orderCode)?.orderContentName;

    const [termCheckBox, setTermCheckBox] = useState(false);
    const [priceInfoCheckBox, setPriceInfoCheckBox] = useState(false);
    const [registButtonDisabled, setRegistButtonDisabled] = useState(false);
    const [image, setImage] = useState(true);

    //フッター表示用State
    const [displayFooter, setDisplayFooter] = useState(false);

    const processing = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();

    const carNumberPlaceName = MasterInfoUtil.findNumberPlaceName(userInfo?.licencePlatePlace,carNumberPlaceInfoList);

    useEffect(() => {
        const initResult = CommonUtil.initialize(true, true, props.pageNavigator, location);
        if (!initResult['workable']) {
            return initResult['cleanup'];
        }

        MapBox.staticMap(map_box.MAP_STYLE, reliefAddressInfo.longitude, reliefAddressInfo.latitude, "map1");
        MapBox.setCustomMarker(reliefAddressInfo.longitude, reliefAddressInfo.latitude, "marker_user", "map1");

        if ((entryInfo?.entryPlaceLongitude !== undefined && entryInfo?.entryPlaceLongitude !== null) && (entryInfo?.entryPlaceLatitude !== undefined && entryInfo?.entryPlaceLatitude !== null)) {
            MapBox.staticMap(map_box.MAP_STYLE, entryInfo?.entryPlaceLongitude, entryInfo?.entryPlaceLatitude, "map2");
            MapBox.setCustomMarker(entryInfo?.entryPlaceLongitude, entryInfo?.entryPlaceLatitude, "marker_jaf", "map2");
        }

        if(reliefInfo['currentPos'] === 'false') {
            const comment = '位置情報を取得できていません。<br>指定した救援場所に誤りが無いかご確認ください。';
            NoticeModalDialog(comment);
        }

        //救援要請制御情報取得
        let prefectureCode = null;
        //救援場所情報が存在する場合都道府県コードを取得
        prefectureCode = reliefInfo['reliefAddressInfo']['prefectureCode'];
        CommonUtil.getOrderControl(true,true,prefectureCode).then(function(response: any){ 
            //救援要請制御ダイアログかどうか
            if(response){
                PagingUtil.resetAndNext(ErrorTransition(ErrorReason.ORDER_CONTROL_ERROR), navigate);
                return;
            }
            if(orderInfo?.imageKey){
                displayReliefImage(orderInfo.imageKey);
                setImage(true);
            }
            else{
                setDisplayFooter(true);
                setImage(false);
                CommonUtil.setIndicator(false);   
            }
        })
        .catch(function(response: any){
            CommonUtil.setIndicator(false); 
            PagingUtil.resetAndNext(ErrorTransition(response), navigate);
        })
        return initResult['cleanup'];
    }, // eslint-disable-next-line react-hooks/exhaustive-deps 
    []);

    
    //救援要請画像表示
    const displayReliefImage = (imageKey:number) => {
        //ファイル読み込み
        getImageFile(imageKey).then(function(response: File){                 
            const fileReader = new FileReader();
            fileReader.readAsDataURL(response);
            fileReader.onload = (function () {
                CommonUtil.draw("image", fileReader.result,IMAGESIZE_WIDTH, IMAGESIZE_HEIGHT).then(function(response: any){
                    setDisplayFooter(true);
                    if(response){
                        CommonUtil.setIndicator(false);
                        setImage(true);
                    }
                    else{
                        //ファイル読み込みエラー
                        CommonUtil.setIndicator(false);
                        setImage(false);
                        //ファイル読み込みエラー,アラートダイアログ表示
                        ModalDialog(AlertMessages.RSEWS_ER_91610);
                    }
                });
            });                       
            fileReader.onerror = (function () {
                setDisplayFooter(true);
                //ファイル読み込みエラー
                CommonUtil.setIndicator(false);
                setImage(false);      
                //アラートダイアログ表示
                ModalDialog(AlertMessages.RSEWS_ER_91610);
                return;
            });
        })
        .catch(function(response: boolean){
            PagingUtil.resetAndNext(ErrorTransition(ErrorReason.UNKWNOWN), navigate);
        })
    }

    const postOrderConfirm = () => {
        var body:OrderRequest = {
            controlCenterCode: reliefAddressInfo.controlCenterCode,
            areaCode: reliefAddressInfo.areaCode,
            userNameKanji: userInfo.userNameKanji,
            userNameKana: userInfo.userNameKana,
            memberFlg: userInfo.memberFlg,
            membershipCardFlg: MembershipCardFlg.NON_POSSESSION,
            membershipNum: userInfo.membershipNum,
            membershipSsoFlg: MembershipSsoFlg.NO_LINKAGE,
            telNum: userInfo.telNum,
            bkTelNum: userInfo.bkTelNum,
            arrangementCode: null,
            orderCode: orderInfo.orderCode,
            orderContentCode: orderInfo.orderContentCode,
            prefectureCode: reliefAddressInfo.prefectureCode,
            cityCode: null,
            manualReliefAddress: null,
            reliefLocationSupplementInfo: reliefAddressInfo.reliefLocationSupplementInfo,
            latitude: reliefAddressInfo.latitude.toString(),
            longitude: reliefAddressInfo.longitude.toString(),
            geocodeReliefAddress: reliefAddressInfo.geocodeReliefAddress,
            manualInputFlg: ManualInputFlg.MAP_SELECT,
            carMakerCode: userInfo.carMakerCode !== undefined ? userInfo.carMakerCode : (userInfo.twoWheelInfo ? userInfo.twoWheelInfo.carMakerCode : ""),
            vehicleCode: userInfo.vehicleCode !== undefined ? userInfo.vehicleCode : (userInfo.twoWheelInfo ? userInfo.twoWheelInfo.vehicleCode : ""),
            carColorCode: userInfo.carColorCode !== undefined ? userInfo.carColorCode : "",
            carNameCode: userInfo.carNameCode !== undefined ? userInfo.carNameCode : (userInfo.twoWheelInfo ? userInfo.twoWheelInfo.carNameCode : ""),
            licencePlatePlace: carNumberPlaceName !== undefined ? carNumberPlaceName : "",
            licencePlateClassification: userInfo.licencePlateClassification !== undefined ? userInfo.licencePlateClassification : "",
            licencePlateDistinction: userInfo.licencePlateDistinction !== undefined ? userInfo.licencePlateDistinction : "",
            licencePlateNumber: userInfo.licencePlateNumber !== undefined ? userInfo.licencePlateNumber : "",
            carWeight: CommonUtil.getWeight(userInfo),
            reliefReservationDt: null,
            specialNotes: ReliefUtil.getSpecialNotes(reliefInfo),
            memo:"",
            driveCode: null,
            urgentArrangementCode: null,
            towHopeFlg: TowHopeFlg.UNWANTED,
            locationCode:reliefAddressInfo.locationCode.toString(),
            roadCode:reliefAddressInfo.roadCode.toString(),
            entryPlaceLatitude: entryInfo?.entryPlaceLatitude === undefined ? null : entryInfo?.entryPlaceLatitude.toString(),
            entryPlaceLongitude: entryInfo?.entryPlaceLongitude === undefined ? null : entryInfo?.entryPlaceLongitude.toString(),
            geocodeEntryPlaceAddress:entryInfo?.geocodeEntryPlaceAddress,
            entryPlaceName:entryInfo?.entryPlaceName,
            additionalInfo1: null,
            additionalInfo2: null,
            additionalInfo3: null,
            additionalInfo4: null,
            additionalInfo5: null,
            additionalInfo6: null,
            inflowCode: null,
            image: null
        }
        
        CommonUtil.setIndicator(true);

        //画像が存在する場合  
        if(orderInfo.imageKey){

            getImageFile(orderInfo.imageKey).then(function(response: File){ 
                body['image'] = response;
                registerOrder(
                    body,
                    registSuccess,
                    registFail);
            })
            .catch(function(response: boolean){
                PagingUtil.resetAndNext(ErrorTransition(ErrorReason.UNKWNOWN), navigate);
                return;
            })
        }
        else{
            registerOrder(
                body,
                registSuccess,
                registFail);
        }
    }

    // 要請内容登録API成功時
    const registSuccess = (response:OrderResponse) => {
        CommonUtil.setIndicator(false);
        PagingUtil.resetAndNext("/ordered", navigate);
    }

    // 要請内容登録APIエラー時
    const registFail = (e:any) => {
        CommonUtil.setIndicator(false);
        setRegistButtonDisabled(false);
        PagingUtil.resetAndNext(ErrorTransition(e), navigate);
        return;
    }

    const goRegist = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        //多重押下対策
        e.preventDefault();
        if (processing.current) return;
        processing.current = true;

        setRegistButtonDisabled(true);
        //登録完了するボタン非活性にする
        const comment = '料金表は確認されましたか？';
        //料金表確認ダイアログ表示
        ConfirmModalDialog(comment, () => {
            processing.current = false;
            postOrderConfirm();
        }, () => {
            //いいえボタン押下時
            // 登録完了するボタン活性にする            
            processing.current = false;
            setRegistButtonDisabled(false);
        });
    }

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>要請内容確認 | JAF救援要請ウェブサイト</title>
                </Helmet>
            </HelmetProvider>
            <Header back pageNavigator={props.pageNavigator}/>

<div className="contentsWrapper">
<div className="contents">

<ul className="status">
<li>救援場所入力</li>
<li>お客様情報入力</li>
<li>トラブル内容入力</li>
<li className="current">内容確認</li>
<li>登録完了</li>
</ul>

</div>
</div>
<div className="contentsWrapper top0">
<div className="contents">
<p className="title large">救援場所</p>

<div className="mapWrapper auto">
<div id="map1" className="map small">
</div>

<div className="buttonWrapper relative wide">

<dl className="request">
<dt>住所</dt>
<dd>{reliefAddressInfo?.geocodeReliefAddress}</dd>
<dt>お車の位置</dt>
<dd>{locationName}</dd>
<dt>その他</dt>
<dd>{reliefAddressInfo?.reliefLocationSupplementInfo}</dd>
</dl>

</div>
</div>
<div className="submit half">
<button className="default half" onClick={() => PagingUtil.next("/relief", navigate)}>救援場所を修正する</button>
</div>

<p className="title large">お客様情報</p>

<dl className="request">
<dt>氏名</dt>
<dd>{userInfo?.userNameKanji}</dd>
<dt>氏名（カナ）</dt>
<dd>{userInfo?.userNameKana}</dd>
<dt>電話番号</dt>
<dd>{userInfo?.telNum}</dd>
<dt>予備の電話番号</dt>
<dd>{userInfo?.bkTelNum}</dd>
<dt>JAFへの入会</dt>
<dd>{CommonUtil.getMemberInformation(userInfo?.memberFlg)}</dd>
{userInfo?.memberFlg === MemberFlg.MEMBER && 
    <>
        <dt>会員番号</dt>
        <dd>{userInfo?.membershipNum}</dd>
    </>
}
{(userInfo?.memberFlg === MemberFlg.NO_MEMBERS || userInfo?.memberFlg === MemberFlg.UNKWNOWN) && 
    <>
        <dt>お支払方法</dt>
        <dd>{userInfo?.paymentOptions}</dd>
    </>
}
</dl>

<div className="submit half">
<button className="default" onClick={() => PagingUtil.next("/userInfo", navigate,  {scroll: "userInfo"})}>お客様情報を修正する</button>
</div>

<p className="title large">車両情報</p>

<dl className="request">
<dt>種別</dt>
<dd>{userInfo?.carKinds}</dd>
{userInfo?.carKinds === "四輪" && 
<>
<dt>車両メーカー</dt>
<dd>{userInfo?.carMaker}</dd>
<dt>車両名称</dt>
<dd>{userInfo?.carName}</dd>
{userInfo?.otherCarFlg === "1" &&
    <>
    <dt>その他の車両名称</dt>
    <dd>{userInfo?.otherCarName}</dd>
</>
}
<dt>カラー</dt>
<dd>{userInfo?.carColor}</dd>
<dt>ナンバープレート</dt>
<dd>{carNumberPlaceName} {userInfo?.licencePlateClassification} {userInfo?.licencePlateDistinction} {userInfo?.licencePlateNumber}</dd>
<dt>車両重量</dt>
<dd>{(userInfo?.carWeight !== "" && userInfo?.carWeight !== undefined) ? userInfo?.carWeight + "kg" : ""}</dd>
</>
}
{userInfo?.carKinds === "二輪" &&
    <>
    <dt>車両区分</dt>
    <dd>{userInfo.twoWheelInfo?.carName}</dd>
    <dt>バイク名称</dt>
    <dd>{userInfo?.bikeName}</dd>
    <dt>ナンバープレート</dt>
    <dd>{userInfo?.licencePlate}</dd>
    <dt>車両重量</dt>
    <dd>{(userInfo?.bikeWeight !== "" && userInfo?.bikeWeight !== undefined) ? userInfo?.bikeWeight + "kg" : ""}</dd>
</>
}

</dl>

<div className="submit half">
<button className="default" onClick={() => PagingUtil.next("/userInfo", navigate, {scroll: "carInfo"})}>車両情報を修正する</button>
</div>

<p className="title large">要請内容</p>

<dl className="request">
<dt>トラブル内容</dt>
<dd>{orderName}</dd>
<dt>ご希望内容</dt>
<dd>{orderContentName}</dd>
<dt>トラブル状況等</dt>
<dd>{orderInfo?.troubleDetail}</dd>

{ orderInfo?.troubleTires &&
<>
<dt>トラブルタイヤ位置</dt>
<dd>{orderInfo?.troubleTires}</dd>
</>
}
<dt>画像</dt>
<dd><div className="preview">{image && <canvas id="image" className="imgStyle" width={IMAGESIZE_WIDTH} height={IMAGESIZE_HEIGHT} ></canvas> }</div></dd>
</dl>

<div className="submit half">
<button className="default" onClick={() => PagingUtil.next("/orderContent", navigate)}>要請情報を修正する</button>
</div>

{ entryInfo &&
<>
<p className="title large">お運び先</p>
<div className="mapWrapper auto">
    <div id="map2" className="map small">
    </div>
    <div className="buttonWrapper relative wide">
        <dl className="request">
            <p className="newline" id="entryPlaceName">{entryInfo?.entryPlaceName !== undefined ? entryInfo?.entryPlaceName : ""}</p>
        </dl>

    </div>
</div>

<div className="submit half">
<button className="default" onClick={() => PagingUtil.next("/orderCarry", navigate)}>お運び先を修正する</button>
</div>
</>
}
<div className="all_submit">
<div className="privacy">
<label tabIndex={0} htmlFor="agree">ロードサービス利用約款に同意する。</label>
<input type="checkbox" name="ロードサービス利用約款" id="agree" value="同意する" onChange={() => setTermCheckBox(!termCheckBox)}/>
</div>
<a tabIndex={0} className="privacy" href="terms.html" target="_blank" rel="noopener">ロードサービス利用約款表示</a>
<div className="privacy">
<label tabIndex={0} htmlFor="agree2">料金確認済み。</label>
<input type="checkbox" name="料金" id="agree2" value="確認済み" onChange={() => setPriceInfoCheckBox(!priceInfoCheckBox)}/>
</div>
<a tabIndex={0} className="privacy" href="priceInfo.html" target="_blank" rel="noopener">料金表示</a>

<div className="submit">
{(termCheckBox && priceInfoCheckBox) && !registButtonDisabled ?
<button tabIndex={0} className="noclose modal-open" onClick={(e) => goRegist(e)}>登録完了する</button> :
<button tabIndex={0} className="action gray" disabled>登録完了する</button> 
}
</div>
</div>

</div>
</div>
<Footer display={displayFooter}/>
<Cngestion />
</div>
    )
}

    export default OrderConfirm