import React, { useEffect } from "react";
import * as FooterCommon from './FooterCommon';

interface FooterProps {
    display:boolean
}

export const Footer = (props: FooterProps) => {
    const {display} = props;

    useEffect(() => {
        FooterCommon.adjustFooter("footerWrapperForIndex");
    }, []);

    return (
        
        <footer>
        <div id="footerWrapperForIndex" className={display?'visible':'hidden'}>
            <div id="footer">
                <p className="copy">{FooterCommon.copyright()}<br className="mobile"/>一般社団法人　日本自動車連盟 (JAF)</p>
            </div>
        </div>
        </footer>
    )
}